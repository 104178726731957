import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import PrimaryBlockHeader from './PrimaryBlockHeader'

function PrimaryBlock(props) {
    const { title } = props
    return (
        <Grid item xs={12}>
            <Paper>
                <PrimaryBlockHeader title={title} />
                {props.children}
            </Paper>
        </Grid>
    )
}

export default PrimaryBlock