import React from 'react'
import { get, set } from 'lodash'
import { BooleanField } from 'react-admin'

const MyBooleanField = ({ className, ...props }) => {
  const { source, record } = props
  const value = get(record, source)
  set(record, source, value === '1')
  return <BooleanField {...props} />
}

MyBooleanField.defaultProps = {
  addLabel: true
}

export default MyBooleanField
