import React from 'react'
import { isEmpty, round, map, filter, find } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PieChart from '../../../components/charts/PieChart'

const useStyles = makeStyles({
  gridContainer: {
    fontSize: '1rem',
    padding: '0px 10px'
  },
  valueColor: {
    color: '#283593'
  }
})

const colorScheme = ['#673ab7', '#4caf50', '#03a9f4', '#f44336', '#ffc107', '#795548', '#9e9e9e']

const paymentsChannelMatching = {
  'УК Заботливая (безденежный реестр)': 'Сайт и личный кабинет',
  'МСК Сбербанк': 'Сбербанк Онлайн',
  'Ручной ввод ЕРКЦ Пушкино': 'Другие банки',
  'ПСКБ Банк Эквайринг': 'ЛК Эллис',
  'МСК МКБ Банк': 'Терминалы МКБ',
  'УК Заботливая (безд. реестр Судебные)': 'Судебные',
  'МСК Почтамп': 'Почта',
}

function GeneralFee(props) {
  const classes = useStyles()
  const { data, paymentsChannelsData } = props
  const { accrued, paid, debt_start, debt_end } = data
  const numAccrued = Number(accrued)
  const numPaid = Number(paid)
  const taxPercent = numPaid !== 0 && numAccrued !== 0 ? round((numPaid * 100 / numAccrued), 2) : numPaid !== 0 ? 100 : 0
  let paymentsChannelsTotal = 0

  map(paymentsChannelsData, elem => {
    const payments = Number(elem.paid) >= 0 ? Number(elem.paid) : 0
    paymentsChannelsTotal += payments
  })

  const paymentsChannels = filter(map(paymentsChannelsData, elem => {
    const payments = Number(elem.paid) >= 0 ? Number(elem.paid) : 0
    const paymentsPercent = round((payments / paymentsChannelsTotal) * 100)
    const channel = paymentsChannelMatching[elem.channel] ? paymentsChannelMatching[elem.channel] : elem.channel
    return {
      name: `${channel} - ${paymentsPercent}%`,
      value: paymentsPercent
    }
  }), elem => elem.value !== 0)

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Grid className={classes.gridContainer} container>
          <Grid item xs={12}>Долг на начало: <span className={classes.valueColor}>{round(debt_start, 2).toLocaleString()}</span></Grid>
          <Grid item xs={12}>План поступлений: <span className={classes.valueColor}>{numAccrued.toLocaleString()}</span></Grid>
          <Grid item xs={12}>Факт поступлений: <span className={classes.valueColor}>{numPaid.toLocaleString()}</span></Grid>
          <Grid item xs={12}>Долг на конец: <span className={classes.valueColor}>{round(debt_end, 2).toLocaleString()}</span></Grid>
          <Grid item xs={12}>Сбор: <span className={classes.valueColor}>{taxPercent.toLocaleString()}%</span></Grid>
          <Grid item xs={12}>Показатель фин. устойчивости: <span className={classes.valueColor}>{numAccrued !== 0 ? round(debt_end / numAccrued, 2).toFixed(2).replace('.', ',') : 0}</span></Grid>
          <Grid item xs={12}>Показатель ДМ: <span className={classes.valueColor}>{numAccrued + debt_start !== 0 ? round(2 * numPaid / (numAccrued + debt_start), 2).toFixed(2).replace('.', ',') : 0}</span></Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        {!isEmpty(paymentsChannels)
          ?
          <PieChart
            data={paymentsChannels}
            height={145}
            colorScheme={colorScheme}
            legend='right'
            isTitled={false}
          />
          :
          <Grid container style={{ height: '100%', alignItems: 'center', justifyContent: 'center', color: 'gray' }}>
            <Grid item>Отсутствуют данные</Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default GeneralFee
