import React from 'react'
import { SimpleForm, TextInput, required, FileField } from 'react-admin'
import Title from '../../components/Title'
import FileInput from '../../components/FileInput'
import Edit from '../../components/Edit'

export default props => (
  <Edit {...props}>
    <SimpleForm>
      <FileInput
        formClassName='col-xs-12 file-input'
        source='included.field_files'
        accept='application/pdf'
        maxSize={500000}
      >
        <FileField
          formClassName='file-field'
          source='attributes.uri.url'
          title='attributes.filename'
          target='_blank'
        />
      </FileInput>
    </SimpleForm>
  </Edit>
)
