import React from 'react'
import { EditButton } from 'react-admin'
import checkPermission from '../helpers/permissions'
import { WithPermissions } from 'react-admin'

export default props => {
  return (
    <WithPermissions
      render={({ permissions: roles }) => {
        const { resource } = props
        const access = `resource.${resource}.edit`
        return (
          <>{checkPermission(access, roles) && <EditButton {...props} />}</>
        )
      }}
    />
  )
}
