import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import {
  isEmpty,
  reduce
} from 'lodash'
import { stringifyDate, addMonth } from '../../../helpers/tools'
import FiltersPanel from './FiltersPanel'
import ComparisonTable from './ComparisonTable'
import LoadingIndicator from '../../../components/LoadingIndicator'
import NoDataAvailable from '../../../components/NoDataAvailable'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}))

function BillsComparison(props) {
  const classes = useStyles()
  const { permissions } = usePermissions()
  const dispatch = useDispatch()

  const localDate = addMonth(new Date(), -1)

  const [currYear, setYear] = useState(localDate.getFullYear())
  const [currMonth, setMonth] = useState(localDate.getMonth())
  const currDate = new Date(currYear, currMonth)

  const [billsData, setBillsData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const changeYear = event => setYear(event.target.value)
  const changeMonth = event => setMonth(event.target.value)

  async function makeRequest(url) {
    const response = await fetch(url, { credentials: 'include' })
    return await response.json()
  }

  useEffect(() => {
    setIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? [] : data
        })
        .finally(() => {
          const pending = storage.pendingData
          if (--storage.counter === 0) {
            setBillsData(pending['bills-compare'])

            setIsLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData('bills-compare', [`&date=${stringifyDate(currDate)}`])
  }, [currYear, currMonth])

  return (
    <>
      <Title title="Сравнение начислений" />
      {checkPermission(`menuItem./node/tools/bills_comparison`, permissions) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FiltersPanel
                defaultDate={localDate}
                currentYear={currYear}
                changeMonth={changeMonth}
                changeYear={changeYear}
                isLoaded={isLoaded}
              />
            </Paper>
          </Grid>
          {isLoaded ?
              <Grid item xs={12}>
                {!isEmpty(billsData)
                  ?
                  <Paper>
                    <ComparisonTable data={billsData} />
                  </Paper>
                  :
                  <NoDataAvailable />
                }
              </Grid>
            :
            <Grid item xs={12}>
              <LoadingIndicator />
            </Grid>
          }
        </Grid>
      }
    </>
  )
}

BillsComparison.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default BillsComparison
