import React from 'react'
import { translate } from 'react-admin'
import _ from 'lodash'

export default translate(({ record, translate }) => {
  let title = _.has(record, 'type') ? translate(record.type) : ''
  title = _.has(record, 'attributes.title')
    ? `${title} "${record.attributes.title}"`
    : title
  return <span>{title}</span>
})
