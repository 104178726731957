import React from 'react'
import { Datagrid, EditButton, TextField } from 'react-admin'
import List from '../../components/List'
import checkPermission from '../../helpers/permissions'

export default props => {
  const { permissions, resource } = props
  return (
    <List {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField cellClassName='cell-12' source='attributes.title' />
        {checkPermission(`resource.${resource}.edit`, permissions) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  )
}
