import React, { useRef } from 'react'
import {
  Create,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  FileField,
  required,
  withTranslate,
  ReferenceArrayInput,
  FormDataConsumer,
  SimpleForm,
  AutocompleteInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  useNotify,
  AutocompleteArrayInput,
} from 'react-admin'
import FileInput from '../../components/FileInput'
import compose from 'recompose/compose'
import ClearFix from '../../components/ClearFix'
import { get, reverse } from 'lodash'
import globals from '../../helpers/globals'

export default compose(withTranslate)(props => {
  const { translate } = props
  const prefix = 'resources.node/contract.fields.'
  const notify = useNotify()
  const previewFilesRef = useRef(null)

  const PostCreateToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label='Сохранить' redirect='list' submitOnEnter={true} />
      <SaveButton
        label='Сохранить и добавить'
        redirect='create'
        submitOnEnter={false}
        variant='text'
        onSuccess={() => {
          notify('ra.notification.created')
          clearFileInput()
        }}
      />
    </Toolbar>
  )

  const clearFileInput = () => {
    const files = previewFilesRef.current.children
    if (files.length > 0) {
      const buttonsArray = []
      for (const file of files) {
        buttonsArray.push(file.querySelector('button'))
      }
      for (const button of reverse(buttonsArray)) {
        button.click()
      }
    }
  }

  return (
    <Create {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <TextInput
          label={translate(prefix + 'attributes.field_id')}
          source='attributes.field_id'
          validate={required()}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          fullWidth={true}
        />
        <TextInput
          label={translate(prefix + 'attributes.title')}
          source='attributes.title'
          validate={required()}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          fullWidth={true}
        />
        <ClearFix formClassName='clearfix' />

        <DateInput
          label={translate(prefix + 'attributes.field_date_start')}
          source='attributes.field_date_start'
          validate={required()}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        />
        <DateInput
          label={translate(prefix + 'attributes.field_date_end')}
          source='attributes.field_date_end'
          validate={required()}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        />
        <ClearFix formClassName='clearfix' />

        <ReferenceInput
          label={translate(prefix + 'relationships.field_firm.data.id')}
          source='relationships.field_firm.data.id'
          reference='node/firm'
          sort={{ field: 'title', order: 'ASC' }}
          validate={required()}
          fullWidth={true}
          resource='node/contract'
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        >
          <SelectInput
            style={{ minWidth: 0 }}
            fullWidth={true}
            optionText='attributes.title'
          />
        </ReferenceInput>

        <ReferenceInput
          label={translate(prefix + 'relationships.field_contractor.data.id')}
          source='relationships.field_contractor.data.id'
          reference='node/contractor'
          perPage={50}
          sort={{ field: 'title', order: 'ASC' }}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          filterToQuery={searchText => {
            return {
              title: {
                path: 'title',
                value: searchText,
                operator: 'CONTAINS',
              },
            }
          }}
        >
          <AutocompleteInput optionText='attributes.title' />
        </ReferenceInput>
        {/* <QuickCreateButton formClassName='col-xs-2' /> */}
        <ClearFix formClassName='clearfix' />

        <ReferenceArrayInput
          resource='node/contract'
          source='relationships.field_house.data'
          reference='node/house'
          perPage={50}
          sort={{ field: 'title', order: 'ASC' }}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          filterToQuery={searchText => {
            return {
              title: {
                path: 'title',
                value: searchText,
                operator: 'CONTAINS',
              },
            }
          }}
        >
          <AutocompleteArrayInput optionText='attributes.title' />
        </ReferenceArrayInput>

        <BooleanInput
          label={translate(prefix + 'attributes.field_prolongation')}
          source='attributes.field_prolongation'
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        />
        <ClearFix formClassName='clearfix' />

        <ReferenceInput
          resource='node/contract'
          label={translate(
            prefix + 'relationships.field_contract_type.data.id'
          )}
          source='relationships.field_contract_type.data.id'
          reference='node/contract_type'
          sort={{ field: 'title', order: 'ASC' }}
          validate={required()}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        >
          <SelectInput style={{ minWidth: 0 }} optionText='attributes.title' />
        </ReferenceInput>

        <ReferenceInput
          resource='node/contract'
          label={translate(
            prefix + 'relationships.field_contract_stage.data.id'
          )}
          source='relationships.field_contract_stage.data.id'
          reference='node/contract_stage'
          sort={{ field: 'title', order: 'ASC' }}
          validate={required()}
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        >
          <SelectInput style={{ minWidth: 0 }} optionText='attributes.title' />
        </ReferenceInput>

        <ClearFix formClassName='clearfix' />

        <FormDataConsumer fullWidth={true} formClassName='col-xs-12'>
          {({ formData, ...rest }) => {
            if (
              get(formData, 'relationships.field_contract_stage.data.id') ===
              get(globals, 'values.contract.stage.canceled')
            ) {
              // Расторгнут
              return (
                <div className='row'>
                  <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                    <DateInput
                      fullWidth={true}
                      source='attributes.field_date_cancel'
                      {...rest}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                    <TextInput
                      fullWidth={true}
                      source='attributes.field_number_cancel'
                      {...rest}
                    />
                  </div>
                </div>
              )
            } else if (
              get(formData, 'relationships.field_contract_stage.data.id') ===
                get(globals, 'values.contract.stage.acceptNotification') ||
              get(formData, 'relationships.field_contract_stage.data.id') ===
                get(globals, 'values.contract.stage.cancelNotification')
            ) {
              // Отправлено уведомление о заключении или расторжении
              return (
                <div className='row'>
                  <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                    <DateInput
                      fullWidth={true}
                      source='attributes.field_date_notice'
                      {...rest}
                    />
                  </div>
                  <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                    <TextInput
                      fullWidth={true}
                      source='attributes.field_number_notice'
                      {...rest}
                    />
                  </div>
                </div>
              )
            }
          }}
        </FormDataConsumer>

        <FileInput
          label={translate(prefix + 'included.field_files')}
          source='included.field_files'
          accept='application/pdf'
          resource='node/contract'
          formClassName='col-xs-12'
          previewFilesRef={previewFilesRef}
        >
          <FileField
            source='attributes.uri.url'
            title='attributes.filename'
            target='_blank'
          />
        </FileInput>

        <TextInput
          multiline
          label={translate(prefix + 'attributes.body.value')}
          source='attributes.body.value'
          fullWidth={true}
          formClassName='col-xs-12'
        />
        <ClearFix formClassName='clearfix' />
      </SimpleForm>
    </Create>
  )
})
