import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FiltersPanel from './FiltersPanel'
import GeneralFee from './GeneralFee'
import HousesFeeList from './HousesFeeList'
import { usePermissions, fetchStart, fetchEnd } from 'react-admin'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import { isEmpty, merge, map, findIndex, uniqBy, filter, toPairs, fromPairs, round, find, cloneDeep, reduce } from 'lodash'
import { addMonth, stringifyDate } from '../../../helpers/tools'

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
})

function CenteredGrid(props) {
  const { classes } = props
  const { permissions } = usePermissions()
  const dispatch = useDispatch()

  const localDate = new Date()

  const [currYear, setYear] = useState(localDate.getFullYear())
  const [currMonth, setMonth] = useState(localDate.getMonth())

  const [currentHousekeeper, setHousekeeper] = useState('empty')
  const [housekeepersList, setHousekeepersList] = useState([])

  const [currentService, setService] = useState('empty')
  const [servicesList, setServicesList] = useState([])

  const [housesFeeData, setHousesFeeData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [paymentsChannelsData, setPaymentsChannelsData] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const changeYear = event => setYear(event.target.value)
  const changeMonth = event => setMonth(event.target.value)
  const changeHousekeeper = event => setHousekeeper(event.target.value)
  const changeService = event => setService(event.target.value)

  const getServicesList = () => {
    return uniqBy(filter(map(servicesData, (elem) => {
      const service = elem.service_id
        ? { id: elem.service_id, housekeeper_id: elem.housekeeper_id, title: elem.service_title }
        : null

      return service
    }), (elem) => { return elem !== null }), 'id')
  }

  const currDate = new Date(currYear, currMonth)

  const renameKey = (object, key, newKey) => {
    const clonedObj = cloneDeep(object)
    const targetKey = clonedObj[key]
    delete clonedObj[key]
    clonedObj[newKey] = targetKey
    return clonedObj;
  }

  const getHousekeeperPaymentsChannels = (housekeeper) => {
    setIsLoaded(false)
    dispatch(fetchStart())
    
    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
      .then((data) => {
        storage.pendingData[url] = isEmpty(data) ? [] : data
      })
      .finally(() => {
        const pending = storage.pendingData
        if (--storage.counter === 0) {
          setPaymentsChannelsData(pending['payments-channels'])
          
          setIsLoaded(true)
          dispatch(fetchEnd())
        }
      })
    }
    
    if (housekeeper === 'empty')
      getData('payments-channels', [`&date=${stringifyDate(currDate)}`])
    else
      getData('payments-channels', [`&date=${stringifyDate(currDate)}`, `&housekeeper_id=${housekeeper}`])
  }
  
  const isValidHousekeeper = (keeper, list) => keeper !== 'empty' && find(list, e => e.id === keeper) !== undefined

  useEffect(() => {
    setIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? [] : data
        })
        .finally(() => {
          const pending = storage.pendingData
          if (--storage.counter === 0) {
            const houseKey = ['house_id']
            const servicesKey = ['house_id', 'service_id']

            const megreBy = (elem, mergeFrom, keys, defaultValue) => {
              const query = fromPairs(keys.map(key => [key, elem[key]]))
              const data = find(mergeFrom, query)
              return merge(elem, data || defaultValue)
            }

            const mergeBills = (elem, mergeFrom, keys) => megreBy(elem, mergeFrom, keys, { paid: '0.00', prev_paid: '0.00' })
            const mergePayments = (elem, mergeFrom, keys) => megreBy(elem, mergeFrom, keys, { accrued: '0.00', balance_start: '0.00', prev_paid: '0.00' })


            const houseBills = map(pending['house-bills'], e => renameKey(e, 'paid', 'prev_paid'))
            const housesBillsData = map(cloneDeep(houseBills), (elem) => mergeBills(elem, pending['house-payments'], houseKey))
            const housesPaymentsData = map(pending['house-payments'], (elem) => mergePayments(elem, cloneDeep(houseBills), houseKey))

            const servicesBillsData = map(pending['houses-bills-services'], (elem) => mergeBills(elem, pending['houses-payments-services'], servicesKey))
            const servicesPaymentsData = map(pending['houses-payments-services'], (elem) => mergePayments(elem, pending['houses-bills-services'], servicesKey))

            setHousesFeeData(uniqBy(housesBillsData.concat(housesPaymentsData), 'house_id'))
            setServicesData(uniqBy(servicesBillsData.concat(servicesPaymentsData), elem => elem.house_id + "_" + elem.service_id))
            // setPaymentsChannelsData(pending['payments-channels'])
          }

          setIsLoaded(true)
          dispatch(fetchEnd())
        })
    }

    getData('house-bills', [`&date=${stringifyDate(addMonth(currDate, -1))}`])
    getData('house-payments', [`&date=${stringifyDate(currDate)}`])
    getData('houses-bills-services', [`&date=${stringifyDate(addMonth(currDate, -1))}`])
    getData('houses-payments-services', [`&date=${stringifyDate(currDate)}`])
    // getData('payments-channels', [`&date=${stringifyDate(currDate)}`])
  }, [currYear, currMonth])

  useEffect(() => {
    if (!isEmpty(housesFeeData)) {
      const newHousekeepersList = uniqBy(filter(map(housesFeeData, (elem) => {
        const keeper = elem.housekeeper_id
        ? { id: elem.housekeeper_id, title: elem.housekeeper_title }
        : null
        
        return keeper
      }), (elem) => { return elem !== null }), 'id')
      
      setHousekeepersList(newHousekeepersList)
      getHousekeeperPaymentsChannels(isValidHousekeeper(currentHousekeeper, newHousekeepersList) ? currentHousekeeper : 'empty')
    }
  }, [housesFeeData])

  useEffect(() => {
    setServicesList(getServicesList())
  }, [servicesData])

  useEffect(() => {
    const isValid = isValidHousekeeper(currentHousekeeper, housekeepersList)
    getHousekeeperPaymentsChannels(isValid ? currentHousekeeper : 'empty')
    if (isValid)
      setServicesList(getServicesList())
    else
      setServicesList(filter(getServicesList(), elem => elem.housekeeper_id === currentHousekeeper))
  }, [currentHousekeeper])

  let paymentsChannelsTotal = 0
  map(paymentsChannelsData, elem => {
    const payments = Number(elem.payments) >= 0 ? Number(elem.payments) : 0
    paymentsChannelsTotal += payments
  })

  async function makeRequest(url) {
    const response = await fetch(url, { credentials: 'include' })
    return await response.json()
  }

  const filterByHousekeeper = (data) => {
    const index = findIndex(housekeepersList, elem => elem.id === currentHousekeeper)
    return currentHousekeeper === 'empty' || index === -1
      ? data
      : filter(data, elem => elem.housekeeper_id === currentHousekeeper)
  }

  const filterByService = (data) => {
    const index = findIndex(servicesList, elem => elem.id === currentService)
    return currentService === 'empty' || index === -1
      ? data
      : filter(data, elem => elem.service_id === currentService)
  }

  const getFilterableData = () => {
    let result
    const isServiceValid = findIndex(servicesList, elem => elem.id === currentService) !== -1

    if (currentService === 'empty' || !isServiceValid)
      result = filterByHousekeeper(housesFeeData)
    else if (currentHousekeeper === 'empty')
      result = filterByService(servicesData)
    else if (currentHousekeeper !== 'empty')
      result = filterByHousekeeper(filterByService(servicesData))

    return result
  }

  const filteredData = map(getFilterableData(), (elem) => {
    const { accrued, paid, prev_paid, balance_start, house_title, house_id } = elem
    const debtStart = Number(balance_start) - Number(prev_paid)
    const debtEnd = debtStart + Number(accrued) - Number(paid)
    return {
      house_id,
      accrued: Number(accrued),
      paid: Number(paid),
      house_title,
      debt_start: debtStart,
      debt_end: debtEnd,
      tax_percent: Number(paid) !== 0 && Number(accrued) !== 0 ? round(Number(paid) * 100 / Number(accrued)) : Number(paid) !== 0 ? 100 : 0,
      financial_indicator: Number(accrued) !== 0 ? round(debtEnd / Number(accrued), 2) : 0,
    }
  })

  let generalData
  const resultObj = { accrued: 0, paid: 0, debt_start: 0, debt_end: 0 }

  for (const elem of filteredData) {
    resultObj.accrued += elem.accrued
    resultObj.paid += elem.paid
    resultObj.debt_start += elem.debt_start
    resultObj.debt_end += elem.debt_end
  }

  generalData = resultObj

  const paymentsChannelsChartData = () => {
    return filter(map(paymentsChannelsData, elem => {
      const payments = Number(elem.payments) >= 0 ? Number(elem.payments) : 0
      const paymentsPercent = round((payments / paymentsChannelsTotal) * 100)
      return {
        channel: `${elem.channel} - ${paymentsPercent}%`,
        payments: paymentsPercent
      }
    }), elem => elem.payments !== 0)
  }

  return (
    <>
      {checkPermission(`menuItem./node/analytics/finance2`, permissions) &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FiltersPanel
                currentYear={currYear}
                changeYear={changeYear}
                changeMonth={changeMonth}
                changeHousekeeper={changeHousekeeper}
                changeService={changeService}
                housekeepersList={housekeepersList}
                servicesList={servicesList}
                isLoaded={isLoaded}
              />
            </Paper>
          </Grid>
          {
            !isEmpty(filteredData)
              ?
              <>
                <Grid item xs={12}>
                  <GeneralFee data={generalData} paymentsChannelsData={paymentsChannelsChartData()} />
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <HousesFeeList data={filteredData} />
                  </Paper>
                </Grid>
              </>
              :
              <Grid item xs={12}>
                <Paper>
                  <div>Отсутствуют данные</div>
                </Paper>
              </Grid>
          }
        </Grid>
      }
    </>
  )
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CenteredGrid)
