import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin'
import axios from 'axios'
import globals from '../helpers/globals'
import { get } from 'lodash'

const removeCredentials = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('pass')
  localStorage.removeItem('roles')
  localStorage.removeItem('csrf_token')
  localStorage.removeItem('logout_token')
  localStorage.removeItem('uid')
}

const provider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    return axios({
      method: 'POST',
      url: globals.apiHost + '/user/login?_format=json',
      withCredentials: true,
      headers: new Headers({ 'Content-Type': 'application/json' }),
      data: {
        name: username,
        pass: password
      }
    })
      .then(data => {
        localStorage.setItem('csrf_token', get(data, 'data.csrf_token'))
        localStorage.setItem('logout_token', get(data, 'data.logout_token'))
        localStorage.setItem('uid', get(data, 'data.current_user.uid'))
      })
      .catch(error => {
        removeCredentials()
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          throw new Error(error.message.message)
        }
      })
  }
  if (type === AUTH_LOGOUT) {
    return axios({
      method: 'GET',
      url: globals.apiHost + '/user/logout?_format=json',
      withCredentials: true,
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(data => {
        removeCredentials()
        return Promise.resolve()
      })
      .catch(error => {
        removeCredentials()
        return Promise.resolve()
      })
  }
  if (type === AUTH_ERROR) {
    const status = params.status
    if (status === 401) {
      removeCredentials()
      return Promise.reject()
    }
    return Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    return axios({
      method: 'GET',
      url: globals.apiHost + '/user/login_status?_format=json',
      withCredentials: true,
      headers: new Headers({ 'Content-Type': 'application/json' })
    }).then(data => {
      return get(data, 'data') ? Promise.resolve() : Promise.reject()
    })
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const roles = JSON.parse(localStorage.getItem('roles'))
    if (roles === null) {
      return axios({
        method: 'GET',
        url: `${globals.apiHost}/me/info?_format=json`,
        withCredentials: true,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        data: {
          name: localStorage.getItem('user'),
          pass: localStorage.getItem('pass')
        }
      })
        .then(data => {
          localStorage.setItem('roles', JSON.stringify(get(data, 'data.roles')))
        })
        .catch(error => {
          removeCredentials()
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            throw new Error(error.message.message)
          }
        })
    }
    return roles ? Promise.resolve(roles) : Promise.reject()
  }
  return Promise.resolve()
}

export default provider
