import React from 'react'
import { MenuItemLink } from 'react-admin'
import checkPermission from '../helpers/permissions'

export default props => {
  const { onClick, leftIcon, primaryText, to, translate, roles } = props
  return (
    <>
      {checkPermission('menuItem.' + to, roles) && (
        <MenuItemLink
          to={to}
          primaryText={translate(primaryText, {
            smart_count: 2
          })}
          leftIcon={leftIcon}
          onClick={onClick}
        />
      )}
    </>
  )
}
