import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '5px',
    marginLeft: '5px',
    fontSize: '1.1rem',
    fontWeight: 600,
    color: '#283593'
  },
}))


function PrimaryBlockHeader(props) {
  const classes = useStyles()
  const { title } = props
  return (
    <div className={classes.header}>{title}</div>
  )
}

export default PrimaryBlockHeader