import React from 'react'
import { Create, SimpleForm, TextInput, required } from 'react-admin'

export default props => (
  <Create {...props}>
    <SimpleForm redirect='/node/contractor'>
      <TextInput source='attributes.title' validate={required()} />
      <TextInput multiline source='attributes.body.value' />
    </SimpleForm>
  </Create>
)
