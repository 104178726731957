import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import authProvider from '../auth-providers/authProvider'
import {
  AUTH_CHECK,
  useTranslate,
  Notification,
  useLogin,
  useNotify,
} from 'react-admin'
import Background from '../images/moscow-1.jpeg'

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}))

const Login = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [state, setState] = useState({
    isLoading: false,
    username: '',
    password: '',
    firstRun: true,
    submit: false,
  })
  const login = useLogin()
  const notify = useNotify()
  const { isLoading, username, password, submit, firstRun } = state
  const { push } = props
  const handleSubmit = e => {
    e.preventDefault()
    setState({
      ...state,
      submit: !submit,
    })
    console.log(state)
  }

  useEffect(() => {
    const checkAuth = () => {
      console.log('TCL: checkAuth -> checkAuth')
      authProvider(AUTH_CHECK)
        .then(() => {
          push('/')
        })
        .catch(() => {})
    }
    if (firstRun) {
      checkAuth()
      setState({
        ...state,
        firstRun: false,
      })
    } else {
      setState({
        ...state,
        isLoading: true,
      })
      login({ username, password }).catch(() =>
        notify('Invalid email or password')
      )
      // userLogin(
      //   auth,
      //   props.location.state ? props.location.state.nextPathname : '/'
      // )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <form
          onSubmit={e => {
            handleSubmit(e)
          }}
        >
          <div className={classes.form}>
            <div className={classes.input}>
              <TextField
                autoFocus
                name='username'
                label={translate('ra.auth.username')}
                disabled={isLoading}
                fullWidth
                value={username}
                onChange={e => {
                  setState({
                    ...state,
                    username: e.target.value,
                  })
                }}
              />
            </div>
            <div className={classes.input}>
              <TextField
                name='password'
                label={translate('ra.auth.password')}
                type='password'
                fullWidth
                disabled={isLoading}
                value={password}
                onChange={e => {
                  setState({
                    ...state,
                    password: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              type='submit'
              color='primary'
              disabled={isLoading}
              className={classes.button}
              variant='contained'
              fullWidth
            >
              {isLoading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      </Card>
      <Notification />
    </div>
  )
}

export default Login
