import React from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'

export default (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label='Номер ОДПУ'
        source='field_counter_number'
        alwaysOn
        parse={(value) => {
          return {
            path: 'field_measure_counter.field_counter_number',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={(value) => {
          return get(value, 'value', '')
        }}
      />
      <ReferenceInput
        source='field_counter_house'
        reference='node/house'
        alwaysOn
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        allowEmpty
        parse={(value) => {
          return {
            path: 'field_measure_counter.field_counter_house.id',
            value: value,
            operator: '=',
          }
        }}
        format={(value) => {
          return ''
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      {/* <ReferenceInput
        source='field_measure_counter'
        reference='node/counter'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        allowEmpty={false}
        parse={(value) => {
          return {
            path: 'field_measure_counter.id',
            value: value,
            operator: '=',
          }
        }}
        format={(value) => {
          return ''
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput> */}
    </Filter>
  )
}
