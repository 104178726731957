import React from 'react'
import {
  Datagrid,
  ArrayField,
  FileField,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
  ReferenceField,
} from 'react-admin'
import FileSizeField from '../../components/FileSizeField'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import BooleanField from '../../components/BooleanField'
import ArrayFieldSimple from '../../components/ArrayFieldSimple'

export default props => {
  return (
    <Show title={<Title />} {...props}>
      <SimpleShowLayout className='row'>
        <ReferenceField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='relationships.field_contract.data.id'
          reference='node/contract'
        >
          <TextField source='attributes.title' />
        </ReferenceField>
        <ClearFix className='clearfix' />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_id'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.title'
        />
        <ClearFix className='clearfix' />

        <DateField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_date_start'
        />
        <DateField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_date_end'
        />
        <ClearFix className='clearfix' />

        <ArrayFieldSimple
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='included.field_house'
          itemSource='attributes.title'
          glue=','
        />

        <BooleanField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_prolongation'
        />
        <ClearFix className='clearfix' />

        <ArrayField className='col-xs-12' source='included.field_files'>
          <Datagrid>
            <FileField
              source='attributes.uri.url'
              title='attributes.filename'
              target='_blank'
            />
            <FileSizeField source='attributes.filesize' />
          </Datagrid>
        </ArrayField>
        <RichTextField className='col-xs-12' source='attributes.body.value' />
      </SimpleShowLayout>
    </Show>
  )
}
