import React from 'react'
import { Datagrid, TextField } from 'react-admin'
import BooleanField from '../../components/BooleanField'
import List from '../../components/List'
import ListFilters from './ListFilters'

export default props => {
  return (
    <List filters={<ListFilters />} {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField
          cellClassName='cell-3'
          source='included.field_area.attributes.title'
          sortBy='field_area.attributes.title'
        />
        <TextField cellClassName='cell-5' source='attributes.title' />
        <TextField
          cellClassName='cell-5'
          source='included.field_firm.attributes.title'
        />
        <BooleanField cellClassName='cell-2' source='attributes.field_active' />
      </Datagrid>
    </List>
  )
}
