import React, { useEffect, useState } from 'react'
import { find, includes, orderBy, round } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const squareMeters = <>м<sup>2</sup></>

const headCells = [
  { field: 'fio', name: 'Менеджер' },
  { field: 'deal_id', name: 'Сделка' },
  { field: 'oss_house_area', name: 'Площадь дома', units: squareMeters },
  { field: 'increase_total', name: 'Проголосовало в общем', units: squareMeters },
  { field: 'increase_for', name: 'Проголосовало за', units: squareMeters },
  { field: 'increase_against', name: 'Проголосовало против', units: squareMeters },
  { field: 'increase_abstained', name: 'Воздержались', units: squareMeters }
]

function VotingTable(props) {
  const { data } = props

  const [order, setOrder] = useState('asc')
  const [columnToSort, setColumnToSort] = useState('fio')

  const tableData = []

  for (const deal in data) {
    const {
      name,
      deal_id,
      last_name,
      increase_for,
      increase_against,
      increase_abstained,
      oss_house_area
    } = data[deal]

    tableData.push({
      fio: `${last_name} ${name}`,
      deal_id,
      oss_house_area,
      increase_total: increase_for + increase_against + increase_abstained,
      increase_for,
      increase_against,
      increase_abstained
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = columnToSort === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setColumnToSort(property)
  };

  return (
    <>
      <Typography variant="h6" style={{ textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>Движение ОСС</Typography>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={columnToSort}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody>
          {orderBy(tableData, columnToSort, order).map((row) => (
            <TableRow key={row.fio}>
              <TableCell align="center">{row.fio}</TableCell>
              <TableCell align="center">{row.deal_id}</TableCell>
              <TableCell align="center">{round(row.oss_house_area, 2).toLocaleString()}</TableCell>
              <TableCell align="center">{round(row.increase_total, 2).toLocaleString()}</TableCell>
              <TableCell align="center">{round(row.increase_for, 2).toLocaleString()}</TableCell>
              <TableCell align="center">{round(row.increase_against, 2).toLocaleString()}</TableCell>
              <TableCell align="center">{round(row.increase_abstained, 2).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default VotingTable
