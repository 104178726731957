import React from 'react'
import { SimpleShowLayout, TextField, RichTextField } from 'react-admin'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import Show from '../../components/Show'

export default props => {
  return (
    <Show title={<Title />} {...props}>
      <SimpleShowLayout>
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.title'
        />
        <ClearFix className='clearfix' />
        <RichTextField className='col-xs-12' source='attributes.body.value' />
      </SimpleShowLayout>
    </Show>
  )
}
