import React from 'react'
import { Edit } from 'react-admin'
import Form from './EditCreateForm'
import Title from '../../components/Title'

export default props => {
  return (
    <Edit title={<Title />} {...props}>
      <Form />
    </Edit>
  )
}
