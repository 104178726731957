import React from 'react'
import './styles/app.scss'
import authProvider from './auth-providers/authProvider'
import messages from './i18n/ru'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Login, Layout } from './layout'
import house from './resources/house'
import firm from './resources/firm'
import firmPaymentsBills from './resources/firm_payments_bills'
import contract from './resources/contract'
import agreement from './resources/agreement'
import contractor from './resources/contractor'
import area from './resources/area'
import jsonapiClient from './data-providers/ra-jsonapi-client'
import globals from './helpers/globals'
import protocol from './resources/protocol'
import incomingMail from './resources/incomingMail'
import outgoingMail from './resources/outgoingMail'
import foundingDocument from './resources/foundingDocument'
import contact from './resources/contact'
import counter from './resources/counter'
import measure from './resources/measure'
import ipu from './resources/ipu'
import AnalyticsHome from './pages/analytics/home'
import AnalyticsFinance from './pages/analytics/finance'
import AnalyticsFinance2 from './pages/analytics/finance2'
import AnalyticsSales from './pages/analytics/sales'
import AnalyticsRatings from './pages/analytics/ratings'
import AnalyticsArrears from './pages/analytics/arrears'
import { Route } from 'react-router-dom'
import Home from './pages/Home'
import BillsComparison from './pages/tools/bills-comparison'
import PaymentsComparison from './pages/tools/payments-comparison'
import ServicesPaymentsComparison from './pages/tools/services-payments-comparison'
import BalanceEndComparison from './pages/tools/balance-end-comparison'

const dataProvider = jsonapiClient(`${globals.apiHost}/${globals.apiBasePath}`)

const customRoutes = [
  <Route exact path='/node/analytics/home' component={AnalyticsHome} />,
  <Route exact path='/node/analytics/finance' component={AnalyticsFinance} />,
  <Route exact path='/node/analytics/finance2' component={AnalyticsFinance2} />,
  <Route exact path='/node/analytics/sales' component={AnalyticsSales} />,
  <Route exact path='/node/analytics/ratings' component={AnalyticsRatings} />,
  <Route exact path='/node/analytics/arrears' component={AnalyticsArrears} />,
  <Route exact path='/node/tools/bills_comparison' component={BillsComparison} />,
  <Route exact path='/node/tools/payments_comparison' component={PaymentsComparison} />,
  <Route exact path='/node/tools/services_payments_comparison' component={ServicesPaymentsComparison} />,
  <Route exact path='/node/tools/balance_end_comparison' component={BalanceEndComparison} />,
]

const App = () => (
  <Admin
    title='Домохозяин'
    dataProvider={dataProvider}
    layout={Layout}
    loginPage={Login}
    i18nProvider={polyglotI18nProvider(() => messages, 'ru')}
    authProvider={authProvider}
    customRoutes={customRoutes}
    dashboard={Home}
  >
    {roles => {
      return [
        <Resource {...contract} />,
        <Resource {...contact} />,
        <Resource {...firm} />,
        <Resource {...firmPaymentsBills} />,
        <Resource {...contractor} />,
        <Resource {...house} />,
        <Resource {...area} />,
        <Resource {...agreement} />,
        <Resource {...protocol} />,
        <Resource {...incomingMail} />,
        <Resource {...outgoingMail} />,
        <Resource {...foundingDocument} />,
        <Resource {...counter} />,
        <Resource {...measure} />,
        <Resource {...ipu} />,
        <Resource name='file/file' />,
        <Resource name='node/contract_type' />,
        <Resource name='node/contract_stage' />,
        <Resource name='node/account' />,
        <Resource name='node/ipu_value' />,
      ]
    }}
  </Admin>
)

export default App
