import React from 'react'
import { connect } from 'react-redux'
import { Layout, Sidebar } from 'react-admin'
import Menu from './Menu'
import { darkTheme, lightTheme } from './themes'

const CustomSidebar = props => <Sidebar {...props} size={305} />
const CustomLayout = props => {
  return <Layout {...props} sidebar={CustomSidebar} menu={Menu} />
}

export default connect(
  state => ({
    theme: state.theme === 'dark' ? darkTheme : lightTheme,
  }),
  {}
)(CustomLayout)
