import list from './List'
import edit from './Edit'
import create from './Create'
import icon from '@material-ui/icons/LocationCity'

export default {
  name: 'node/area',
  list,
  edit,
  create,
  icon
}
