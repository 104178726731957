import React from 'react'
import SecondaryBlockHeader from './SecondaryBlockHeader'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    block: {
        // padding: 5,
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '4px'
    },
}))

function PrimaryBlock(props) {
    const classes = useStyles()
    const { title } = props
    return (
        <>
            <SecondaryBlockHeader title={title} />
            <div className={classes.block}>
                {props.children}
            </div>
        </>
    )
}

export default PrimaryBlock