import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chart,
  PieSeries,
  Legend,
  Title,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, Palette, EventTracker } from '@devexpress/dx-react-chart'
import { find, get } from 'lodash'
import { defaultChartScheme, toCurrencyFormat } from '../../helpers/tools'

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
  stabilityRatio: {
    color: '#0069c0',
    fontSize: '2.5rem',
    verticalAlign: 'sub'
  },
  legendRoot: {
    padding: 0,
    maxHeight: '125px',
    overflow: 'auto',
    minWidth: '55%'
  },
  legendRootBottom: {
    padding: 0,
    maxHeight: '125px',
    minHeight: '125px',
    overflow: 'auto',
    width: '100%'
  },
  legendLabel: {
    margin: 0,
  },
  legendItem: {
    padding: '0px 16px',
    '& span': {
      fontSize: '1rem',
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
    }
  },
  chartRoot: {
    padding: 0
  },
  chartTitle: {
    fontSize: '1rem',
    marginBottom: 10
  }
}))

export default function PieChart(props) {
  const classes = useStyles()
  const {
    data,
    height,
    innerRadius,
    outerRadius,
    colorScheme,
    valueField = 'value',
    argumentField = 'name',
    legend,
    isTitled,
    title,
  } = props

  const titleComponent = (({ ...restProps }) => (
    <Title.Text {...restProps} className={classes.chartTitle} />
  ))

  const legendRoot = (restProps => (
    <Legend.Root {...restProps} className={legend === 'bottom' ? classes.legendRootBottom : classes.legendRoot} />
  ))

  const Label = (restProps => (
    <Legend.Label {...restProps} className={classes.legendLabel} />
  ))

  const legendItem = (restProps => (
    <Legend.Item {...restProps} className={classes.legendItem} />
  ))

  return (
    <Chart
      data={data}
      height={height}
    >
      {isTitled && <Title text={title} textComponent={titleComponent} />}
      <Palette scheme={colorScheme ? colorScheme : defaultChartScheme} />
      <PieSeries
        valueField={valueField}
        argumentField={argumentField}
        innerRadius={innerRadius ? innerRadius : 0}
        outerRadius={outerRadius ? outerRadius : 1}

      />
      {legend &&
        <Legend
          rootComponent={legendRoot}
          labelComponent={Label}
          itemComponent={legendItem}
          position={legend}
        />
      }
      <EventTracker />
      <Tooltip />
      <Animation />
    </Chart>
  )
}