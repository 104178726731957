import React from 'react'
import { CreateButton, TopToolbar } from 'react-admin'
import checkPermission from '../helpers/permissions'

export default ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  permissions,
  creatable,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })
    }
    {creatable &&
      checkPermission(`resource.${resource}.edit`, permissions) && (
        <CreateButton basePath={basePath} />
      )
    }
  </TopToolbar>
)
