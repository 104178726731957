import React from 'react'
import { Datagrid, DeleteButton, EditButton, TextField } from 'react-admin'
import List from '../../components/List'

export default props => {
  return (
    <List {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid>
        <TextField cellClassName='cell-12' source='attributes.title' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
