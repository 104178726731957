import React from 'react'
import { Show, EditButton } from 'react-admin'
import CardActions from '@material-ui/core/CardActions'
import checkPermission from '../helpers/permissions'

const cardActionStyle = {
  zIndex: 2,
  padding: 0,
  justifyContent: 'flex-end'
}

const Actions = props => {
  const { basePath, data, resource, permissions } = props
  return (
    <CardActions style={cardActionStyle}>
      {checkPermission(`resource.${resource}.edit`, permissions) && (
        <EditButton basePath={basePath} record={data} />
      )}
    </CardActions>
  )
}

export default props => {
  const { permissions } = props
  return (
    <Show {...props} actions={<Actions permissions={permissions} />}>
      {props.children}
    </Show>
  )
}
