import list from './List'
import edit from './Edit'
import create from './Create'
import icon from '@material-ui/icons/SupervisorAccount'
import show from './Show'

export default {
  name: 'node/outgoing_mail',
  list,
  edit,
  create,
  show,
  icon
}
