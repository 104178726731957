import React from 'react'
import FilterByYearMonth from '../../../components/FilterByYearMonth'

function FiltersPanel(props) {
  const {
    defaultDate,
    currentYear,
    changeYear,
    changeMonth,
    isLoaded,
  } = props

  return (
    <FilterByYearMonth
      defaultDate={defaultDate}
      currentYear={currentYear}
      changeYear={changeYear}
      changeMonth={changeMonth}
      isLoaded={isLoaded}
    />
  )
}

export default FiltersPanel