/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { set } from 'lodash'
import { useMutation, useNotify } from 'react-admin'
import { TextField } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/src/createNumberMask'

export default ({
  counterId,
  measureId,
  currentValue,
  setCurrentValue,
  date,
}) => {
  const [triggerSubmit, setTriggerSubmit] = useState(0)
  const payload = {}
  set(payload, 'data.type', 'node--measure')
  set(payload, 'data.attributes.title', `${counterId} / ${date}`)
  set(payload, 'data.attributes.field_measure_date', date)
  set(payload, 'data.attributes.field_measure_value', currentValue)
  set(payload, 'data.relationships.field_measure_counter.data.id', counterId)
  let resource = 'node/measure'
  let requestType = 'create'
  if (measureId) {
    requestType = 'update'
    set(payload, 'id', measureId)
    set(payload, 'data.id', measureId)
  }

  const [triggerRequest, { error, loading }] = useMutation({
    type: requestType,
    resource,
    payload,
  })

  const notify = useNotify()

  useEffect(() => {
    if (error) {
      notify(error.message, 'warning')
    }
  }, [error])

  useEffect(() => {
    if (triggerSubmit) {
      const timer = setTimeout(() => {
        triggerRequest()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [triggerSubmit])

  return (
    <>
      <TextField
        disabled={loading}
        InputProps={{
          inputComponent: TextMaskCustom,
          value: currentValue,
          onChange: event => {
            setCurrentValue(event.target.value)
            setTriggerSubmit(triggerSubmit + 1)
          },
        }}
      />
    </>
  )
}

const TextMaskCustom = props => {
  const { inputRef, ...other } = props
  const mask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
  })
  return <MaskedInput {...other} ref={inputRef} mask={mask} />
}
