import React from 'react'
import { Datagrid, RichTextField, TextField, DateField, WithPermissions, EditButton } from 'react-admin'
import List from '../../components/List'
import ListFilters from './ListFilters'
import ArrayFieldSimple from '../../components/ArrayFieldSimple'
import checkPermission from '../../helpers/permissions'

export default props => {
  return (
    <WithPermissions
      render={({ permissions: roles }) => {
        const { resource } = props
        const access = `resource.${resource}.edit`
        return (
          <List filters={<ListFilters />} {...props} bulkActionButtons={false} creatable={true}>
            <Datagrid rowClick='show'>
              <TextField source='attributes.field_id' cellClassName='cell-1' />
              <DateField
                source='attributes.field_date_start'
                cellClassName='cell-2'
              />
              <TextField source='attributes.title' cellClassName='cell-2' />
              <ArrayFieldSimple
                sortBy='field_house.attributes.title'
                source='included.field_house'
                itemSource='attributes.title'
                glue=','
              />
              <RichTextField
                sortBy='field_contract_type.attributes.title'
                source='included.field_contract_type.attributes.title'
                cellClassName='cell-2'
              />
              <RichTextField
                sortBy='field_firm.attributes.title'
                source='included.field_firm.attributes.title'
                cellClassName='cell-2'
              />
              <RichTextField
                sortBy='field_contractor.attributes.title'
                source='included.field_contractor.attributes.title'
                cellClassName='cell-2'
              />
              {checkPermission(access, roles) && <EditButton />}
            </Datagrid>
          </List>
        )
      }}
    />
  )
}
