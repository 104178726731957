import React from 'react'
import {
  TabbedForm,
  ReferenceInput,
  ReferenceArrayInput,
  TextInput,
  SelectInput,
  TextField,
  FormTab,
  DateInput,
  FileField,
  ReferenceManyField,
  Datagrid,
  EditButton,
  FormDataConsumer,
  required,
  AutocompleteInput,
  BooleanInput,
  AutocompleteArrayInput,
} from 'react-admin'
import ClearFix from '../../components/ClearFix'
import FileInput from '../../components/FileInput'
import AddAgreementButton from './AddAgreementButton'
import AddProtocolButton from './AddProtocolButton'
import Title from '../../components/Title'
import globals from '../../helpers/globals'
import { get } from 'lodash'
import Edit from '../../components/Edit'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  widthFix: {
    width: '100%',
    minWidth: '100%',
  },
})

const EditForm = props => {
  const { classes } = props

  return (
    <Edit title={<Title />} {...props}>
      <TabbedForm>
        <FormTab
          label='resources.node/contract.tabs.main'
          contentClassName='row block'
        >
          <TextInput
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            className='full-width'
            source='attributes.field_id'
            fullWidth={true}
          />
          <TextInput
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.title'
            validate={required()}
            fullWidth={true}
          />
          <ClearFix formClassName='clearfix' />
          <DateInput
            fullWidth={true}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_date_start'
          />
          <DateInput
            fullWidth={true}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_date_end'
          />
          <ClearFix formClassName='clearfix' />
          <ReferenceInput
            fullWidth={true}
            className={classes.widthFix}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='relationships.field_firm.data.id'
            reference='node/firm'
            sort={{ field: 'title', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText='attributes.title' />
          </ReferenceInput>
          <ReferenceInput
            fullWidth={true}
            className={classes.widthFix}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='relationships.field_contractor.data.id'
            reference='node/contractor'
            sort={{ field: 'title', order: 'ASC' }}
            validate={required()}
            filterToQuery={searchText => {
              return {
                title: {
                  path: 'title',
                  value: searchText,
                  operator: 'CONTAINS',
                },
              }
            }}
          >
            <AutocompleteInput optionText='attributes.title' />
          </ReferenceInput>
          <ClearFix formClassName='clearfix' />
          <ReferenceArrayInput
            fullWidth={true}
            className={classes.widthFix}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='relationships.field_house.data'
            reference='node/house'
            sort={{ field: 'title', order: 'ASC' }}
            perPage={50}
            filterToQuery={searchText => {
              return {
                title: {
                  path: 'title',
                  value: searchText,
                  operator: 'CONTAINS',
                },
              }
            }}
          >
            <AutocompleteArrayInput optionText='attributes.title' />
          </ReferenceArrayInput>
          <BooleanInput
            // label={translate(prefix + 'attributes.field_prolongation')}
            type='checkbox'
            fullWidth={true}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_prolongation'
            // format={v => {
            //   const val = !!v
            //   console.log('val', val)
            //   console.log('v', v)
            //   return false
            // }}
          />
          <ClearFix formClassName='clearfix' />
          <ReferenceInput
            fullWidth={true}
            className={classes.widthFix}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='relationships.field_contract_type.data.id'
            reference='node/contract_type'
            sort={{ field: 'title', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText='attributes.title' />
          </ReferenceInput>
          <ReferenceInput
            fullWidth={true}
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='relationships.field_contract_stage.data.id'
            className={classes.widthFix}
            reference='node/contract_stage'
            sort={{ field: 'title', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText='attributes.title' />
          </ReferenceInput>
          <ClearFix formClassName='clearfix' />
          <FormDataConsumer fullWidth={true} formClassName='col-xs-12'>
            {({ formData, ...rest }) => {
              if (
                get(formData, 'relationships.field_contract_stage.data.id') ===
                get(globals, 'values.contract.stage.canceled')
              ) {
                // Расторгнут
                return (
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                      <DateInput
                        fullWidth={true}
                        source='attributes.field_date_cancel'
                        {...rest}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                      <TextInput
                        fullWidth={true}
                        source='attributes.field_number_cancel'
                        {...rest}
                      />
                    </div>
                  </div>
                )
              } else if (
                get(formData, 'relationships.field_contract_stage.data.id') ===
                  get(globals, 'values.contract.stage.acceptNotification') ||
                get(formData, 'relationships.field_contract_stage.data.id') ===
                  get(globals, 'values.contract.stage.cancelNotification')
              ) {
                // Отправлено уведомление о заключении или расторжении
                return (
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                      <DateInput
                        fullWidth={true}
                        source='attributes.field_date_notice'
                        {...rest}
                      />
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                      <TextInput
                        fullWidth={true}
                        source='attributes.field_number_notice'
                        {...rest}
                      />
                    </div>
                  </div>
                )
              }
            }}
          </FormDataConsumer>
          <FileInput
            formClassName='col-xs-12 file-input'
            source='included.field_files'
            accept='application/pdf'
          >
            <FileField
              formClassName='file-field'
              source='attributes.uri.url'
              title='attributes.filename'
              target='_blank'
            />
          </FileInput>
          <TextInput
            multiline
            fullWidth
            formClassName='col-xs-12'
            source='attributes.body.value'
          />
        </FormTab>

        <FormTab
          label='resources.node/contract.tabs.agreement'
          path='agreement'
        >
          <ReferenceManyField
            addLabel={false}
            reference='node/agreement'
            target='field_contract.id'
            sort={{ field: 'created', order: 'DESC' }}
            fullWidth={true}
          >
            <Datagrid rowClick='show'>
              <TextField source='attributes.field_id' cellClassName='cell-2' />
              <TextField
                source='included.field_house.attributes.title'
                cellClassName='cell-6'
              />
              <TextField source='attributes.title' cellClassName='cell-6' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddAgreementButton />
        </FormTab>
        <FormTab label='resources.node/contract.tabs.protocol' path='protocol'>
          <ReferenceManyField
            addLabel={false}
            reference='node/protocol'
            target='field_contract.id'
            sort={{ field: 'created', order: 'DESC' }}
            fullWidth={true}
          >
            <Datagrid rowClick='show'>
              <TextField source='attributes.field_id' cellClassName='cell-2' />
              <TextField source='attributes.title' cellClassName='cell-10' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddProtocolButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default withStyles(styles)(EditForm)
