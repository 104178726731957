import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import checkPermission from '../../../helpers/permissions'
import Header from './Header'
import { useDispatch } from 'react-redux'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import _, {
  map,
  findIndex,
  find,
  uniqBy,
  filter,
  range,
  rangeRight,
  groupBy,
  last,
  toPairs,
  sumBy,
  sortBy,
  get,
  includes,
  round,
  keyBy,
  fromPairs,
  split,
  head,
  first,
} from 'lodash'
import PrimaryInfo from './PrimaryInfo'
import CumulativeTotal from './CumulativeTotal'
import Receivables from './Receivables'
import { addMonth, stringifyDate } from '../../../helpers/tools'
import ColorsLegend from './ColorsLegend'
import BarSeriesChart from '../../../components/charts/BarSeriesChart'
import LoadingIndicator from '../../../components/LoadingIndicator'
import PrimaryBlock from '../../../components/PrimaryBlock'
import { makeRequest, baseUrls } from '../../../helpers/tools'
import Development from './Development'

const useStyles = makeStyles(() => ({
  gridItem: {
    padding: 10,
  },
}))

const monthNames = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек'
]

const colorsMatching = [
  { text: 'Начислено', color: '#6ec6ff' },
  { text: 'Оплачено', color: '#0069c0' },
  { text: 'Сбор за тек.месяц', color: '#2e7d32' },
  { text: 'Сбор за пред.месяц', color: '#c5e1a5' }
]

function Home(props) {

  const classes = useStyles()
  const { permissions } = usePermissions()
  const dispatch = useDispatch()

  const [currentHousekeeper, setHousekeeper] = useState('empty')
  const [housekeepersList, setHousekeepersList] = useState([])

  const [currentHouse, setHouse] = useState('empty')
  const [housesList, setHousesList] = useState([])
  const [mainIsLoaded, setMainIsLoaded] = useState(false)
  const [developmentIsLoaded, setDevelopmentIsLoaded] = useState(false)
  const [payments, setPayments] = useState([])
  const [daysPayments, setDaysPayments] = useState([])
  const [latestStageDeals, setLatestStageDeals] = useState([])
  const [latestStageDealsOss, setLatestStageDealsOss] = useState([])
  const [dealsWithStages, setDealsWithStages] = useState([])

  const [developmentData, setDevelopmentData] = useState({})

  const currDate = new Date()
  currDate.setDate(1)
  const prevDate = addMonth(currDate, -1)

  const changeHouse = (event) => {
    setHouse(event.target.value)
  }

  const changeHousekeeper = (event) => {
    setHousekeeper(event.target.value)
  }

  const getHousesList = () => {
    return uniqBy(filter(map(payments, (elem) => {
      const house = elem.house_id
        ? { id: elem.house_id, title: elem.house_title, housekeeper_id: elem.housekeeper_id }
        : null

      return house
    }), (elem) => { return elem !== null }), 'id')
  }

  const filterByHousekeeper = (data) => {
    const index = findIndex(housekeepersList, elem => elem.id === currentHousekeeper)
    return currentHousekeeper === 'empty' || index === -1
      ? data
      : filter(data, elem => elem.housekeeper_id === currentHousekeeper)
  }

  const filterByHouse = (data) => {
    const index = findIndex(housesList, elem => elem.id === currentHouse)
    return currentHouse === 'empty' || index === -1
      ? data
      : filter(data, elem => elem.house_id === currentHouse)
  }

  const cumSumByDays = payments => {
    let accumulator = 0
    return fromPairs(map(payments, (e) => {
      accumulator += Number(e.paid)
      return [new Date(e.date).getDate(), accumulator]
    }))
  }

  const getCurrDayCumSum = daysPayments => {
    for (let day = new Date().getDate(); day > 0; --day) {
      if (daysPayments[day])
        return daysPayments[day]
    }

    return 0
  }

  const calcPercent = (value, total) => total === 0 ? 100 : (value / total) * 100
  const daysInMonth = date => new Date(date.getFullYear(), date.getMonth(), 0).getDate()

  useEffect(() => {
    setMainIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, path, params, init) => {
      ++storage.counter
      makeRequest(url, path, params, init)
        .then((data) => {
          storage.pendingData[head(split(path, '/'))] = data
        })
        .finally(() => {
          if (--storage.counter === 0) {
            setPayments(storage.pendingData['last-months'])
            setDaysPayments(storage.pendingData['days-payments'])

            setMainIsLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData(baseUrls.api, 'last-months', { _format: 'json' }, { credentials: 'include' })

    const queryParams = { _format: 'json', date: stringifyDate(prevDate) }

    if (currentHouse !== 'empty')
      queryParams.house_id = currentHouse
    else if (currentHousekeeper !== 'empty')
      queryParams.housekeeper_id = currentHousekeeper

    getData(baseUrls.api, 'days-payments', queryParams, { credentials: 'include' })
  }, [currentHousekeeper, currentHouse])

  useEffect(() => {
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, path, params, init, storageKey) => {
      ++storage.counter
      makeRequest(url, path, params, init)
        .then((data) => {
          storage.pendingData[storageKey] = data
        })
        .finally(() => {
          if (--storage.counter === 0) {
            setLatestStageDeals(storage.pendingData['deals-with-latest-stage-11'])
            setDealsWithStages(storage.pendingData[`deals-with-stages`])
            setLatestStageDealsOss(storage.pendingData[`deals-with-latest-stage-5,6,7`])

            dispatch(fetchEnd())
            setDevelopmentIsLoaded(true)
          }
        })
    }

    const bitrixHeaders = {
      'Authorization': 'Bearer nMLzKxTAxvRunORXHICTKlsU4Yfm07LUKja7aagA',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    getData(
      baseUrls.bitrix,
      `deals-with-latest-stage/${currDate.getFullYear()}-01-01/${currDate.getFullYear()}-12-31/11`,
      [],
      {
        headers: bitrixHeaders
      },
      'deals-with-latest-stage-11'
    )

    getData(
      baseUrls.bitrix,
      `deals-with-stages/${stringifyDate(prevDate)}/${stringifyDate(new Date())}`,
      [],
      {
        headers: bitrixHeaders
      },
      'deals-with-stages'
    )

    getData(
      baseUrls.bitrix,
      `deals-with-latest-stage/2020-01-01/${stringifyDate(currDate)}/5,6,7`,
      [],
      {
        headers: bitrixHeaders
      },
      'deals-with-latest-stage-5,6,7'
    )
  }, [])

  useEffect(() => {
    if (!developmentIsLoaded) return

    const data = {
      houses_num: 0,
      area: 0,
      oss_deals: _.size(latestStageDealsOss),
      new_apps: 0,
      prev_month_apps: 0
    }

    for (const stage in latestStageDeals) {
      const deal = latestStageDeals[stage]
      data.area += deal.living_area + deal.uninhabited_area + deal.paid_parking_area
      data.houses_num++
    }

    for (const deal in dealsWithStages) {
      for (const stage in dealsWithStages[deal].stages) {
        const stageId = dealsWithStages[deal].stages[stage].stage_id
        const stageDate = dealsWithStages[deal].stages[stage].updated_at
        const isCurrDate = new Date(stageDate).getMonth() === currDate.getMonth()
        if (stageId === 1) {
          if (isCurrDate)
            data.new_apps++
          else
            data.prev_month_apps++
        }
      }
    }

    setDevelopmentData(data)
  }, [developmentIsLoaded])

  useEffect(() => {
    const housekeepersList = uniqBy(filter(map(payments, (elem) => {
      const keeper = elem.housekeeper_id
        ? { id: elem.housekeeper_id, title: elem.housekeeper_title }
        : null

      return keeper
    }), (elem) => { return elem !== null }), 'id')

    setHousekeepersList(housekeepersList)
    currentHousekeeper === 'empty'
      ? setHousesList(getHousesList())
      : setHousesList(filter(getHousesList(), elem => elem.housekeeper_id === currentHousekeeper))
  }, [payments])

  useEffect(() => {
    currentHousekeeper === 'empty'
      ? setHousesList(getHousesList())
      : setHousesList(filter(getHousesList(), elem => elem.housekeeper_id === currentHousekeeper))
  }, [currentHousekeeper])

  useEffect(() => {
    if (!find(housesList, { id: currentHouse })) {
      setHouse('empty')
    }
  }, [housesList])

  const currDaysPayments = filter(daysPayments, elem => includes(elem.date, stringifyDate(currDate, 'YYYY-MM')))
  const prevDaysPayments = filter(daysPayments, elem => includes(elem.date, stringifyDate(prevDate, 'YYYY-MM')))
  const cumulativeCurr = cumSumByDays(currDaysPayments)
  const cumulativePrev = cumSumByDays(prevDaysPayments)

  const data = groupBy(filterByHouse(filterByHousekeeper(payments)), 'date')
  if (!(stringifyDate(currDate) in data))
    data[stringifyDate(currDate)] = []

  const pairs = toPairs(data).map(([dateStr, monthPayments]) => {
    const cumSum = {
      date: dateStr,
      accrued: sumBy(monthPayments, o => Number(o.accrued)),
      balance_start: sumBy(monthPayments, o => Number(o.balance_start)),
    }

    if (dateStr === stringifyDate(currDate)) {
      cumSum.paid = getCurrDayCumSum(cumulativeCurr)
      cumSum.currDayPaid = cumSum.paid
    }
    else {
      cumSum.currDayPaid = 0
      cumSum.paid = sumBy(monthPayments, o => Number(o.paid))
      if (dateStr === stringifyDate(prevDate)) {
        cumSum.currDayPaid = getCurrDayCumSum(cumulativePrev)
      }
    }

    return cumSum
  })

  const sorted = sortBy(pairs, 'date')
  while (sorted.length < 3)
    sorted.unshift({ accrued: 0, paid: 0, balance_start: 0 })

  const r = []
  for (let i = 2; i < sorted.length; ++i) {
    const calc = (prev, curr) => {
      const debtStart = prev.balance_start - prev.paid
      const debtEnd = debtStart + prev.accrued - curr.paid
      return {
        taxPercent: prev.accrued === 0 ? 0 : calcPercent(curr.currDayPaid, prev.accrued),
        financialIndicator: prev.accrued === 0 ? 0 : debtEnd / prev.accrued,
        dmIndicator: prev.accrued === 0 ? 0 : 2 * curr.currDayPaid / (prev.accrued + debtStart)
      }
    }

    const minus0 = sorted[i]
    const minus1 = sorted[i - 1]
    const minus2 = sorted[i - 2]

    const prev = calc(minus2, minus1)
    const curr = calc(minus1, minus0)

    r.push({
      date: minus0.date,
      taxPercent: curr.taxPercent,
      financialIndicator: curr.financialIndicator,
      dmIndicator: curr.dmIndicator,
      accrued: minus1.accrued,
      paid: minus0.paid,
      currDayPaid: minus0.currDayPaid,
      accruedDiff: calcPercent(minus1.accrued, minus2.accrued) - 100,
      paidDiff: calcPercent(minus0.currDayPaid, minus1.currDayPaid) - 100,
      taxPercentDiff: prev.taxPercent === 0 ? 0 : curr.taxPercent - prev.taxPercent,
      financialIndicatorDiff: calcPercent(curr.financialIndicator, prev.financialIndicator) - 100,
      dmIndicatorDiff: calcPercent(curr.dmIndicator, prev.dmIndicator) - 100
    })
  }

  const storage = keyBy(r, 'date')
  const currAccrued = get(storage[stringifyDate(currDate)], 'accrued')
  const prevAccrued = get(storage[stringifyDate(prevDate)], 'accrued')

  const maxDays = Math.max(...[currDate, prevDate].map(daysInMonth))
  const cumChartData = map(range(1, maxDays + 1), day => {
    const currCumSum = cumulativeCurr[day]
    const prevCumSum = cumulativePrev[day]
    const currPaid = currAccrued && currCumSum ? round(calcPercent(currCumSum, currAccrued), 1) : undefined
    const prevPaid = prevAccrued && prevCumSum ? round(calcPercent(prevCumSum, prevAccrued), 1) : undefined

    return { day, prevPaid, currPaid }
  })

  const aggregated = _(filterByHouse(filterByHousekeeper(payments)))
    .groupBy('date')
    .map((p, date) => {
      return [date, {
        date,
        paid: sumBy(p, o => Number(o.paid)),
        accrued: sumBy(p, o => Number(o.accrued)),
      }]
    })
    .sortBy(head)
    .value()

  if (first(last(aggregated)) !== stringifyDate(currDate)) {
    aggregated.push([
      stringifyDate(currDate),
      {
        date: stringifyDate(currDate),
        paid: 0,
        accrued: 0,
      }
    ])
  }
  
  const forChart = fromPairs(aggregated)
  
  const paidAccruedData = rangeRight(-6).map(offset => {
    const date = addMonth(currDate, offset)
    const monthData = forChart[stringifyDate(date)]
    const monthDataAccrued = forChart[stringifyDate(addMonth(date, -1))]
    return {
      month: monthNames[date.getMonth()],
      accrued: get(monthDataAccrued, "accrued", 0),
      paid: get(monthData, "paid", 0)
    }
  })
  
  return (
    <>
      <Title title="Главная" />
      {checkPermission(`menuItem./node/analytics/home`, permissions) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header
              changeHousekeeper={changeHousekeeper}
              housekeepersList={housekeepersList}
              changeHouse={changeHouse}
              housesList={housesList}
              isLoaded={mainIsLoaded}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <PrimaryBlock title='Качество' />
              <PrimaryBlock title='Заявки' />
              <Development
                data={developmentData}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Paper>
              {mainIsLoaded ?
                <Grid container>
                  <Grid className={classes.gridItem} item xs={6}>
                    {
                      <PrimaryInfo data={last(r)} />
                    }
                  </Grid>
                  <Grid className={classes.gridItem} item xs={6}>
                    <CumulativeTotal data={cumChartData} />
                  </Grid>
                  {/* <Grid className={classes.gridItem} item xs={6}>
                  <FeeRate data={feeRateData}/>
                </Grid> */}
                  <Grid className={classes.gridItem} item xs={6}>
                    {
                      findIndex(paidAccruedData, v => v.accrued != 0 || v.paid != 0) !== -1
                        ?
                        <BarSeriesChart
                          chartData={paidAccruedData}
                          seriesData={[
                            { name: 'Начислено', value: 'accrued', argument: 'month', color: '6ec6ff' },
                            { name: 'Оплачено', value: 'paid', argument: 'month', color: '0069c0' }
                          ]}
                          height={250}
                          isTitled={true}
                          title='Начисления и оплаты за 6 мес. (руб.)'
                          isTooltipped={true}
                          tooltipComponent={e => `${round(e.text, 2).toLocaleString()} руб.`}
                          animation={true}
                          valueScaleModify={() => tick => tick / 1000000 % 2 === 0 ? tick.toLocaleString() : ''}
                        />
                        : <></>
                    }
                  </Grid>
                  <Grid className={classes.gridItem} item xs={6}>
                    {/* <Receivables data={receivablesData}/> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify={'flex-end'} style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                      <ColorsLegend colors={colorsMatching} />
                    </Grid>
                  </Grid>
                </Grid>
                : <LoadingIndicator />
              }
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  )
}

Home.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default Home
