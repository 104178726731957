import React from 'react'
import { AppBar, UserMenu, translate } from 'react-admin'
import Typography from '@material-ui/core/Typography'

import Logo from './Logo'

const CustomUserMenu = translate(({ translate, ...props }) => (
  <UserMenu {...props}></UserMenu>
))

const CustomAppBar = ({ classes, ...props }) => (
  <AppBar {...props} userMenu={<CustomUserMenu />}>
    <Typography variant='title' color='inherit' id='react-admin-title' />
    <Logo />
  </AppBar>
)

export default CustomAppBar
