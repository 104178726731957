import React from 'react'
import { Datagrid, Tab, ArrayField, FileField } from 'react-admin'
import TextField from '../../components/TextField'
import ClearFix from '../../components/ClearFix'
import FileSizeField from '../../components/FileSizeField'

export default props => {
  return (
    <Tab {...props} contentClassName='row block' label='tabs.main'>
      <TextField
        className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.title'
      />
      <TextField
        className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='included.field_area.attributes.title'
      />
      <ClearFix className='clearfix' />
      <TextField
        className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='included.field_firm.attributes.title'
      />
      <ClearFix className='clearfix' />
      <TextField
        className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_area_paid'
      />
      <TextField
        className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_area_mutual'
      />
      <ClearFix className='clearfix' />
      <ArrayField className='col-xs-12 col-fix' source='included.field_files'>
        <Datagrid>
          <FileField
            source='attributes.uri.url'
            title='attributes.filename'
            target='_blank'
          />
          <FileSizeField source='attributes.filesize' />
        </Datagrid>
      </ArrayField>

      <TextField className='col-xs-12' source='attributes.body.value' />
      <ClearFix className='clearfix' />
    </Tab>
  )
}
