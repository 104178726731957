/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Tab, useDataProvider } from 'react-admin'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { DatePicker } from '@material-ui/pickers'
import { map, get, find, set, filter } from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MeasureRow from './MeasureRow'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { myParseFloat } from '../../../helpers/tools'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 178,
  },
}))

export default props => {
  const classes = useStyles()
  const { id } = props
  const dataProvider = useDataProvider()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [counters, setCounters] = useState([])
  const [currentMonthMeasures, setCurrentMonthMeasures] = useState([])
  const [prevMonthMeasures, setPrevMonthMeasures] = useState([])
  const [resource, setResource] = useState('')
  const [purpose, setPurpose] = useState('')
  let prevValueTotal = 0
  let currentValueTotal = 0
  let consumptionTotal = 0
  moment.locale('ru')

  useEffect(() => {
    dataProvider
      .getManyReference('node/counter', {
        id,
        target: 'field_counter_house.id',
        pagination: {
          page: 1,
          perPage: 50,
        },
      })
      .then(({ data }) => {
        setCounters(data)
      })
      .catch(error => {})
  }, [])

  useEffect(() => {
    const fetchMeasure = (addMonth, setData) => {
      const startOfMonth = moment(selectedDate)
        .add(addMonth, 'month')
        .startOf('month')
        .format(moment.HTML5_FMT.DATE)
      const endOfMonth = moment(selectedDate)
        .add(addMonth, 'month')
        .endOf('month')
        .format(moment.HTML5_FMT.DATE)

      const filter = {
        counter: {
          path: 'field_measure_counter.id',
          operator: 'IN',
          value: map(counters, counter => counter.id),
        },
        date: {
          path: 'field_measure_date',
          operator: 'BETWEEN',
          value: [startOfMonth, endOfMonth],
        },
      }
      if (resource) {
        filter.resource = {
          path: 'field_measure_counter.field_counter_resource',
          operator: '=',
          value: resource,
        }
      }
      if (purpose) {
        filter.resource = {
          path: 'field_measure_counter.field_counter_purpose',
          operator: '=',
          value: purpose,
        }
      }

      dataProvider
        .getList('node/measure', {
          pagination: {
            page: 1,
            perPage: 50,
          },
          filter,
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {})
    }
    if (counters) {
      fetchMeasure(0, setCurrentMonthMeasures)
      fetchMeasure(-1, setPrevMonthMeasures)
    }
  }, [counters, selectedDate, resource, purpose])

  const countersFiltered = filter(counters, counter => {
    const counterResource = get(counter, 'attributes.field_counter_resource')
    if (resource && resource !== counterResource) {
      return false
    }
    const counterPurpose = get(counter, 'attributes.field_counter_purpose')
    if (purpose && purpose !== counterPurpose) {
      return false
    }
    return true
  })

  return (
    <Tab
      {...props}
      contentClassName='row block'
      label='resources.node/house.tabs.measures'
    >
      <div>
        <div className='col-xs-12' style={{ margin: '20px 0 20px 0' }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              views={['year', 'month']}
              label='Месяц'
              variant='inline'
              inputVariant='filled'
              openTo='month'
              autoOk
              minDate={new Date('2018-01-01')}
              maxDate={new Date()}
              value={selectedDate}
              onChange={setSelectedDate}
            />
          </MuiPickersUtilsProvider>
          <span style={{ width: '16px', display: 'inline-block' }}></span>
          <FormControl variant='filled' className={classes.formControl}>
            <InputLabel id='type-select-label'>Ресурс</InputLabel>
            <Select
              labelId='type-select-label'
              value={resource}
              autoWidth={true}
              displayEmpty={true}
              onChange={e => {
                setResource(e.target.value)
              }}
            >
              <MenuItem value=''>&nbsp;</MenuItem>
              <MenuItem value={'Электроэнергия'}>Электроэнергия</MenuItem>
              <MenuItem value={'ХВС'}>ХВС</MenuItem>
              <MenuItem value={'ГВС носитель'}>ГВС носитель</MenuItem>
              <MenuItem value={'ГВС энергия'}>ГВС энергия</MenuItem>
              <MenuItem value={'Теплоснабжение'}>Теплоснабжение</MenuItem>
              <MenuItem value={'Газ'}>Газ</MenuItem>
            </Select>
          </FormControl>
          <span style={{ width: '16px', display: 'inline-block' }}></span>
          <FormControl variant='filled' className={classes.formControl}>
            <InputLabel id='purpose-select-label'>Назначение</InputLabel>
            <Select
              labelId='purpose-select-label'
              value={purpose}
              autoWidth={true}
              displayEmpty={true}
              onChange={e => {
                setPurpose(e.target.value)
              }}
            >
              <MenuItem value=''>&nbsp;</MenuItem>
              <MenuItem value={'Общий'}>Общий</MenuItem>
              <MenuItem value={'МОП'}>МОП</MenuItem>
              <MenuItem value={'Общеквартирный'}>Общеквартирный</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Номер</TableCell>
              <TableCell align='right'>Тип</TableCell>
              <TableCell align='right'>Место установки</TableCell>
              <TableCell align='right'>Потребители</TableCell>
              <TableCell align='right'>Назначение</TableCell>
              <TableCell align='right'>Предыдущие показания</TableCell>
              <TableCell align='right'>Текущие показания</TableCell>
              <TableCell align='right'>Разница показаний</TableCell>
              <TableCell align='right'>Коэффициент</TableCell>
              <TableCell align='right'>Расход</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countersFiltered.map(counter => {
              const searchPath = set(
                {},
                'relationships.field_measure_counter.data.id',
                counter.id
              )
              const prevMeasure = find(prevMonthMeasures, searchPath)
              const prevValue = get(
                prevMeasure,
                'attributes.field_measure_value',
                ''
              )
              prevValueTotal += myParseFloat(prevValue)

              const currentMeasure = find(currentMonthMeasures, searchPath)
              const currentMeasureId = get(currentMeasure, 'id')
              const currentValue = get(
                currentMeasure,
                'attributes.field_measure_value',
                ''
              )
              currentValueTotal += myParseFloat(currentValue)

              const coefficient = get(
                counter,
                'attributes.field_counter_coefficient'
              )
              consumptionTotal +=
                (myParseFloat(currentValue) - myParseFloat(prevValue)) *
                myParseFloat(coefficient)

              return (
                <MeasureRow
                  key={counter.id}
                  counter={counter}
                  measureId={currentMeasureId}
                  currentValue={currentValue}
                  date={moment(selectedDate)
                    .startOf('month')
                    .format(moment.HTML5_FMT.DATE)}
                  prevValue={prevValue}
                  coefficient={coefficient}
                />
              )
            })}
            {resource && (
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'></TableCell>
                <TableCell align='right'>Итого</TableCell>
                <TableCell align='right'>{prevValueTotal}</TableCell>
                <TableCell align='right'>{currentValueTotal}</TableCell>
                <TableCell align='right'>
                  {currentValueTotal - prevValueTotal}
                </TableCell>
                <TableCell align='right'>-</TableCell>
                <TableCell align='right'>{consumptionTotal}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Tab>
  )
}
