import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { percentageDiff } from '../helpers/tools'

const useStyles = makeStyles(() => ({
  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  }
}))

export default function (props) {
  const classes = useStyles()
  const { number, comparedNumber } = props
  const diff = percentageDiff(number, comparedNumber)

  const color = diff < 0
    ? classes.red
    : classes.green

  return (
    <>
      {number} (<span className={color}>{diff >= 0 && '+'}{diff}%</span>)
    </>
  )
}