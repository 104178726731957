import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirmIcon from '@material-ui/icons/PeopleAlt'
import HouseIcon from '@material-ui/icons/LocationCity'
import ContractorIcon from '@material-ui/icons/SupervisorAccount'
import Person from '@material-ui/icons/ContactPhone'
import DescriptionIcon from '@material-ui/icons/Description'
import PieChartIcon from '@material-ui/icons/PieChart'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import BuildIcon from '@material-ui/icons/Build'
import SvgIcon from '@material-ui/core/SvgIcon'
import ReceiptIcon from '@material-ui/icons/Receipt'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { ReactComponent as CounterIcon } from '../images/2.svg'
import { withRouter } from 'react-router-dom'
import {
  translate,
  Responsive,
  getResources,
  WithPermissions,
} from 'react-admin'
import SubMenu from '../components/SubMenu'
import MenuItemLink from '../components/MenuItemLink'

class Menu extends Component {
  state = {
    menuCatalog: false,
    menuDocuments: false,
    menuMail: false,
    menuDirectory: false,
    menuAnalytics: false,
  }

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  }

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }))
  }

  render() {
    const { onMenuClick, open, logout, translate } = this.props
    return (
      <WithPermissions
        render={({ permissions: roles }) => {
          return (
            <>
              <SubMenu
                handleToggle={() => this.handleToggle('menuAnalytics')}
                isOpen={this.state.menuAnalytics}
                sidebarIsOpen={open}
                icon={<ChevronRight />}
                name='menuGroup.analytics'
                roles={roles}
              >
                <MenuItemLink
                  to='/node/analytics/home'
                  primaryText='resources.node/analytics/home.name'
                  leftIcon={<PieChartIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                <MenuItemLink
                  to='/node/analytics/finance'
                  primaryText='resources.node/analytics/finance.name'
                  leftIcon={<MonetizationOnIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                {/* <MenuItemLink
                  to='/node/analytics/finance2'
                  primaryText='resources.node/analytics/finance2.name'
                  leftIcon={<MonetizationOnIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                /> */}
                <MenuItemLink
                  to='/node/analytics/sales'
                  primaryText='resources.node/analytics/sales.name'
                  leftIcon={<TrendingUpIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                {/* <MenuItemLink
                  to='/node/analytics/ratings'
                  primaryText='resources.node/analytics/ratings.name'
                  leftIcon={<StarHalfIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                /> */}
                <MenuItemLink
                  to='/node/analytics/arrears'
                  primaryText='Дебиторская задолженность'
                  leftIcon={<AccountBalanceWalletIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
              </SubMenu>
              <MenuItemLink
                to='/node/firm'
                primaryText='resources.node/firm.name'
                leftIcon={<FirmIcon />}
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/firm_payments_bills'
                primaryText='resources.node/firm_payments_bills.name'
                leftIcon={<ReceiptIcon />}
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/contractor'
                primaryText='resources.node/contractor.name'
                leftIcon={<ContractorIcon />}
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/house'
                primaryText='resources.node/house.name'
                leftIcon={<HouseIcon />}
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/ipu'
                primaryText='resources.node/ipu.name'
                leftIcon={
                  <SvgIcon>
                    <CounterIcon />
                  </SvgIcon>
                }
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/counter'
                primaryText='resources.node/counter.name'
                leftIcon={
                  <SvgIcon>
                    <CounterIcon />
                  </SvgIcon>
                }
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <MenuItemLink
                to='/node/contact'
                primaryText='resources.node/contact.name'
                leftIcon={<Person />}
                onClick={onMenuClick}
                translate={translate}
                roles={roles}
              />
              <SubMenu
                handleToggle={() => this.handleToggle('menuDocuments')}
                isOpen={this.state.menuDocuments}
                sidebarIsOpen={open}
                icon={<ChevronRight />}
                name='menuGroup.documents'
                roles={roles}
              >
                <MenuItemLink
                  to='/node/contract'
                  primaryText='resources.node/contract.name'
                  leftIcon={<DescriptionIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                <MenuItemLink
                  to='/node/founding_document'
                  primaryText='resources.node/founding_document.name'
                  leftIcon={<DescriptionIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
              </SubMenu>
              <SubMenu
                handleToggle={() => this.handleToggle('menuTools')}
                isOpen={this.state.menuTools}
                sidebarIsOpen={open}
                icon={<ChevronRight />}
                name='menuGroup.tools'
                roles={roles}
              >
                <MenuItemLink
                  to='/node/tools/bills_comparison'
                  primaryText='resources.node/tools/bills_comparison.name'
                  leftIcon={<BuildIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                <MenuItemLink
                  to='/node/tools/payments_comparison'
                  primaryText='resources.node/tools/payments_comparison.name'
                  leftIcon={<BuildIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                <MenuItemLink
                  to='/node/tools/services_payments_comparison'
                  primaryText='resources.node/tools/services_payments_comparison.name'
                  leftIcon={<BuildIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
                <MenuItemLink
                  to='/node/tools/balance_end_comparison'
                  primaryText='resources.node/tools/balance_end_comparison.name'
                  leftIcon={<BuildIcon />}
                  onClick={onMenuClick}
                  translate={translate}
                  roles={roles}
                />
              </SubMenu>
              <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
              />
            </>
          )
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  resources: getResources(state),
})

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate)

export default enhance(Menu)
