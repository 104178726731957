import React from 'react'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  AutocompleteInput,
  // DateInput,
} from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'
import { DateInput } from "react-admin-date-inputs"
import MomentUtils from '@date-io/moment'
import { stringifyDate } from '../../helpers/tools'

const dateFormatter = v => {
  if (v == undefined) return
  const date = new Date(v)
  date.setDate(1)
  return stringifyDate(date)
};

export default props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source='field_house_single'
        alwaysOn
        reference='node/house'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_house_single.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput optionText='attributes.title' />
      </ReferenceInput>
      {/* <DateInput
        source='field_date'
        label='Дата'
        alwaysOn
        parse={value => {
          return {
            path: 'field_date',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      /> */}
      <DateInput
        source="field_date"
        views={["year", "month"]}
        alwaysOn
        label="Дата"
        providerOptions={{ utils: MomentUtils }}
        options={{clearable: true}}
        minDate={new Date("2020-09-01")}
        maxDate={new Date()}
        parse={value => {
          return {
            path: 'field_date',
            value: dateFormatter(value),
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <TextInput
        source='field_text_1'
        alwaysOn
        parse={value => {
          return {
            path: 'field_text_1',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <TextInput
        source='field_text_2'
        alwaysOn
        parse={value => {
          return {
            path: 'field_text_2',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <TextInput
        source='field_text_3'
        alwaysOn
        parse={value => {
          return {
            path: 'field_text_3',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
    </Filter>
  )
}
