import React from 'react'
import {
  Datagrid,
  EditButton,
  TextField,
  DateField,
  RichTextField
} from 'react-admin'
import List from '../../components/List'
import MailFilter from '../../components/MailFilter'

export default props => {
  return (
    <List filters={<MailFilter />} {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField
          cellClassName='cell-1'
          source='attributes.field_custom_serial'
        />
        <DateField cellClassName='cell-1' source='attributes.created' />
        <TextField cellClassName='cell-3' source='attributes.title' />
        <RichTextField
          cellClassName='cell-3'
          sortBy='field_firm.attributes.title'
          source='included.field_firm.attributes.title'
        />
        <RichTextField
          cellClassName='cell-3'
          sortBy='field_contractor.attributes.title'
          source='included.field_contractor.attributes.title'
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
