import React, { useState, useEffect } from 'react'
import FilterByPeriod from '../../../components/FilterByPeriod'
import { stringifyDate, addDay, toCurrencyFormat } from '../../../helpers/tools'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import { makeRequest, baseUrls, defaultChartScheme } from '../../../helpers/tools'
import { find, get, head, split } from 'lodash'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EnhancedTableHead from '../../../components/EnhancedTableHead'
import _, { orderBy } from "lodash"
import LoadingIndicator from '../../../components/LoadingIndicator'
import PieChart from '../../../components/charts/PieChart'
import SecondaryBlock from '../../../components/SecondaryBlock'

function JudicialWorkByStages(props) {
    const {
        receivablesByDate,
        receivablesAll,
        startDate,
        endDate,
        changeStartDate,
        changeEndDate,
        isLoaded
    } = props

    const [order, setOrder] = useState('asc')
    const [columnToSort, setColumnToSort] = useState('stage_index')

    const handleRequestSort = (event, property) => {
        const isAsc = columnToSort === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setColumnToSort(property)
    }

    const tableColumns = [
        { field: 'stage', name: 'Стадия', align: 'left' },
        { field: 'number', name: 'Количество' },
        { field: 'cost', name: 'Стоимость' },
        { field: 'repayment', name: 'Погашение' },

    ]

    const tableData = [
        { id: ['DATA_SOZDANIYA'], stage: 'Новые просуживания', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_SOZDANIYA', 'DATA_RETURN'], stage: 'Подготовка документов', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_NACHALA_POISKA'], stage: 'Поиск данных', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_OPLATA'], stage: 'Оплата пошлины', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_READINESS_COURT'], stage: 'Подача в суд', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_PODACHI_DOKUMENTOV_V_SUD', 'DATA_RECEIVING_APPEAL'], stage: 'В суде', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_REFUSAL_COURT'], stage: 'Отказано судом', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_DECISION_SYD', 'DATA_DECISION_APEL_INST'], stage: 'Ожидание вступления в силу', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_LEGAL_DECISION'], stage: 'Подача в Сбербанк', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_SBER'], stage: 'Ожидание от Сбербанка', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_RETURN_SBER'], stage: 'Подача в ФССП', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_FSSP'], stage: 'Ожидание постановления ФССП', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_POLUCH_FSSP'], stage: 'Ожидание оплаты', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_OTMENA_PRIKAZ'], stage: 'Отмена приказа', number: 0, cost: 0, repayment: 0 },
        { id: ['DATA_PROVERKI_DOLG'], stage: 'Долг погашен', number: 0, cost: 0, repayment: 0 },
    ]

    _.each(receivablesByDate, (item) => {
        const cost = Number(get(item, 'SUMMA_DOLG', 0)) + Number(get(item, 'SUMMA_PENNI', 0))
        for (const prop in item) {
            if (_.includes(prop, 'DATA_')) {
                _.each(tableData, (row) => {
                    if (_.includes(row.id, prop)) {
                        row.number++
                        row.cost += cost
                    }
                })
            }
        }
    })

    const receivablesByStage = []

    _.each(receivablesAll, (item, idx) => {
        const targetElem = find(receivablesByStage, (elem) => { return elem.stage === item.STAGE })
        const cost = Number(get(item, 'SUMMA_DOLG', 0)) + Number(get(item, 'SUMMA_PENNI', 0))
        if (targetElem) {
            targetElem.number++
            targetElem.cost += cost
        }
        else {
            receivablesByStage.push({
                stage: item.STAGE,
                number: 1,
                cost,
                color: defaultChartScheme[idx]
            })
        }
    })

    const receivablesByNumber = [], receivablesByCost = []

    _.each(receivablesByStage, (item) => {
        const stageByNumber = `${item.stage} - ${item.number} шт.`
        const stageByCost = `${item.stage} - ${toCurrencyFormat(item.cost)} руб.`
        receivablesByNumber.push({
            stage: stageByNumber,
            number: item.number,
            color: item.color
        })

        receivablesByCost.push({
            stage: stageByCost,
            cost: item.cost,
            color: item.color
        })
    })

    const sortedByNumber = _.orderBy(receivablesByNumber, 'number', 'desc')
    const sortedByCost = _.orderBy(receivablesByCost, 'cost', 'desc')

    const colorsByNumber = _.map(sortedByNumber, 'color')
    const colorsByCost = _.map(sortedByCost, 'color')

    // console.log(receivablesByNumber, receivablesByCost)

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <SecondaryBlock title='Распределение судебной работы по стадиям'>
                    <Grid container>
                        <Grid item xs={6}>
                            <PieChart
                                data={sortedByNumber}
                                height={300}
                                valueField='number'
                                argumentField='stage'
                                legend='bottom'
                                isTitled={true}
                                title='Количество'
                                colorScheme={colorsByNumber}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PieChart
                                data={sortedByCost}
                                height={300}
                                valueField='cost'
                                argumentField='stage'
                                legend='bottom'
                                isTitled={true}
                                title='Стоимость'
                                colorScheme={colorsByCost}
                            />
                        </Grid>
                    </Grid>
                </SecondaryBlock>
            </Grid>
            <Grid item xs={6}>
                <SecondaryBlock title='Движение судебной работы по стадиям'>
                    <Grid container>
                        <Grid item xs={12} style={{ padding: '5px' }}>
                            <FilterByPeriod
                                startDate={startDate}
                                endDate={endDate}
                                changeStartDate={changeStartDate}
                                changeEndDate={changeEndDate}
                                isLoaded={isLoaded}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {isLoaded ?
                                <Table size='small'>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={columnToSort}
                                        onRequestSort={handleRequestSort}
                                        headCells={tableColumns}
                                    />
                                    <TableBody>
                                        {orderBy(tableData, columnToSort, order).map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.stage}</TableCell>
                                                <TableCell align="center">{row.number}</TableCell>
                                                <TableCell align="center">{toCurrencyFormat(row.cost)}</TableCell>
                                                <TableCell align="center">{row.repayment}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                : <LoadingIndicator withoutTitle />
                            }
                        </Grid>
                    </Grid>
                </SecondaryBlock>
            </Grid>
        </Grid>
    )
}

export default JudicialWorkByStages