import { forEach, isArray, get, has, find } from 'lodash'
import { GET_ONE } from './actions'

const resourceIncludes = (resource, type) => {
  let included = ''
  switch (resource) {
    case 'node/contract': {
      included =
        'field_contractor, field_house, field_firm, field_contract_type, field_contract_stage'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/counter': {
      included = 'field_counter_house, field_counter_supplier'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/measure': {
      included = 'field_measure_counter.field_counter_house'
      return included
    }
    case 'node/ipu': {
      included = 'field_account, field_account.field_house_single'
      return included
    }
    case 'node/house': {
      included = 'field_area,field_firm'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/firm_payments_bills': {
      included = 'field_house_single'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/founding_document': {
      included = 'field_firm'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/protocol': {
      included = 'field_contract'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/agreement': {
      included = 'field_contract,field_house'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    case 'node/incoming_mail':
    case 'node/outgoing_mail': {
      included = 'field_contractor,field_firm,field_parent'
      if (type === GET_ONE) {
        included += ',field_files'
      }
      return included
    }
    default: {
    }
  }
}

const fillItemIncluded = (item, included, out = {}, parent = '') => {
  forEach(item.relationships, (fieldValue, fieldName) => {
    const fullFieldName = parent ? `${parent}_${fieldName}` : fieldName
    if (isArray(fieldValue.data)) {
      out[fullFieldName] = []
      forEach(fieldValue.data, data => {
        const id = get(data, 'id')
        const inc = find(included, { id })
        if (inc) {
          out[fullFieldName].push(inc)
          fillItemIncluded(inc, included, out, fieldName)
        }
      })
    } else {
      const id = get(fieldValue, 'data.id')
      const inc = find(included, { id })
      if (inc) {
        out[fullFieldName] = inc
        fillItemIncluded(inc, included, out, fieldName)
      }
    }
  })

  return out
}

const responseToData = data => {
  return data.data.map(value => {
    if (has(data, 'included')) {
      value.included = fillItemIncluded(value, data.included)
    }
    return value
  })
}

export { resourceIncludes, responseToData, fillItemIncluded }
