import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  FileField,
  ReferenceField,
  TextField,
  required
} from 'react-admin'
import Title from '../../components/Title'
import FileInput from '../../components/FileInput'
import ClearFix from '../../components/ClearFix'
import Edit from '../../components/Edit'

export default props => {
  const redirect = (basePath, id, data) =>
    `/node/contract/${data.relationships.field_contract.data.id}/show/protocol`

  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceField
          source='relationships.field_contract.data.id'
          reference='node/contract'
          formClassName='col-xs-12'
        >
          <TextField source='attributes.title' />
        </ReferenceField>

        <ReferenceInput
          source='relationships.field_contract.data.id'
          reference='node/contract'
          validate={required()}
          formClassName='col-xs-12 hidden'
        >
          <SelectInput optionText='attributes.title' />
        </ReferenceInput>

        <TextInput
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_id'
        />

        <TextInput
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.title'
          validate={required()}
        />
        <ClearFix formClassName='clearfix' />

        <DateInput
          fullWidth={true}
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_date'
        />
        <ClearFix formClassName='clearfix' />

        <FileInput
          formClassName='file-input col-xs-12'
          source='included.field_files'
          accept='application/pdf'
        >
          <FileField
            formClassName='file-field'
            source='attributes.uri.url'
            title='attributes.filename'
            target='_blank'
          />
        </FileInput>

        <TextInput
          multiline
          formClassName='col-xs-12'
          source='attributes.body.value'
        />

        <ClearFix formClassName='clearfix' />
      </SimpleForm>
    </Edit>
  )
}
