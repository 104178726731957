import edit from './Edit'
import create from './Create'
import show from './Show'

export default {
  name: 'node/agreement',
  edit,
  create,
  show
}
