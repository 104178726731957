import React, { useState, useEffect } from 'react'
import { makeRequest, baseUrls, stringifyDate, addDay } from '../../../helpers/tools'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _, {
  head,
  split,
  isEmpty,
  merge,
  map,
  uniqBy,
  filter,
  fromPairs,
  round,
  reduce,
  sumBy,
  includes,
  uniq,
  keys,
  constant,
  concat,
  over,
  property,
  join
} from 'lodash'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SelectFormControl from '../../../components/SelectFormControl'
import { makeStyles } from '@material-ui/core/styles'
import JudicialWorkByStages from './JudicialWorkByStages'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  }
}))

function Arrears() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const localDate = new Date()

  const [currentHousekeeper, setHousekeeper] = useState('empty')
  const [currentHouse, setHouse] = useState('empty')
  const [startDate, setStartDate] = useState(stringifyDate(addDay(localDate, -7)))
  const [endDate, setEndDate] = useState(stringifyDate(localDate))
  const [receivablesByDate, setReceivablesByDate] = useState([])
  const [receivablesAll, setReceivablesAll] = useState([])


  const [filtersByHousekeepers, setFiltersByHousekeeper] = useState([])
  const [receivablesAllTotal, setReceivablesAllTotal] = useState([])
  const [receivablesByDateTotal, setReceivablesByDateTotal] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const filters = [
    data => filterBy(data, 'HOUSEKEEPER_ID', currentHousekeeper),
    data => filterBy(data, 'HOUSE_ID', currentHouse),
  ]

  const filterBy = (data, property, value) => {
    const filtered = filter(data, elem => elem[property] === value)
    return isEmpty(filtered) ? data : filtered
  }

  const applyFilters = (data, filters) => {
    for (const filter of filters)
      data = filter(data)
    return data
  }

  const changeHouse = event => setHouse(event.target.value)
  const changeHousekeeper = event => setHousekeeper(event.target.value)
  const changeStartDate = event => setStartDate(event.target.value)
  const changeEndDate = event => setEndDate(event.target.value)

  const prepareFilters = data => {
    return _(data)
      .values()
      .uniqBy(o => join([o.HOUSEKEEPER_ID, o.HOUSE_ID], ':'))
      .value()
  }

  useEffect(() => {
    setIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, path, params, init) => {
      ++storage.counter
      makeRequest(url, path, params, init)
        .then((data) => {
          storage.pendingData[head(split(path, '/'))] = data
        })
        .finally(() => {
          if (--storage.counter === 0) {
            setReceivablesByDate(storage.pendingData['receivables-filtered-by-dates'])
            setReceivablesAll(storage.pendingData['all-receivables'])

            setIsLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData(baseUrls.api, 'receivables-filtered-by-dates', { _format: 'json', date_start: startDate, date_end: endDate }, { credentials: 'include' })
    getData(baseUrls.api, 'all-receivables', { _format: 'json' }, { credentials: 'include' })
  }, [startDate, endDate])

  useEffect(() => {
    setFiltersByHousekeeper(prepareFilters(receivablesAll))
  }, [isLoaded])

  useEffect(() => {
    const receivablesAllData = applyFilters(receivablesAll, filters)
    const receivablesByDateData = applyFilters(receivablesByDate, filters)

    setReceivablesAllTotal(receivablesAllData)
    setReceivablesByDateTotal(receivablesByDateData)
  }, [isLoaded, currentHousekeeper, currentHouse])

  const prepareFilter = (list, id, key) => {
    const filtered = filter(list, { [key]: id })
    const unique = uniqBy(list, key)
    return [isEmpty(filtered) ? list : filtered, unique]
  }

  const [allHouses, uniqHousekeepers] = prepareFilter(filtersByHousekeepers, currentHousekeeper, 'HOUSEKEEPER_ID')
  const uniqHouses = uniqBy(allHouses, "HOUSE_ID")

  console.log(receivablesAllTotal, receivablesByDateTotal)

  return (
    <>
      <Title title='Дебиторская задолженность компании' />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <SelectFormControl label='Управляющий' list={uniqHousekeepers} onChange={changeHousekeeper} property='HOUSEKEEPER' id='ID' title='' disabled={!isLoaded} />
            <SelectFormControl label='Дом' list={uniqHouses} onChange={changeHouse} property='HOUSE' id='ID' title='' disabled={!isLoaded} />
          </Paper>
        </Grid>
        {/* <Grid item xs={5}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <JudicialWorkByStages
              receivablesByDate={receivablesByDateTotal}
              receivablesAll={receivablesAllTotal}
              startDate={startDate}
              endDate={endDate}
              changeStartDate={changeStartDate}
              changeEndDate={changeEndDate}
              isLoaded={isLoaded}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

Arrears.propTypes = {
}

export default Arrears
