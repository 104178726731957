import React from 'react'
import { get, set } from 'lodash'
import {
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  FileField,
  DateInput,
  required,
  NumberInput,
  FormDataConsumer,
  AutocompleteInput,
  BooleanInput,
  // FileInput,
} from 'react-admin'
import ClearFix from '../../components/ClearFix'
import { withStyles } from '@material-ui/core/styles'
import FileInput from '../../components/FileInput'

const styles = theme => ({
  widthFix: {
    width: '100%',
    minWidth: '100%',
  },
})

const EditCreateForm = props => {
  const { classes } = props
  return (
    <SimpleForm {...props} redirect={'/node/counter'}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const number = get(formData, 'attributes.field_counter_number', '')
          const type = get(formData, 'attributes.field_counter_type', '')
          set(formData, 'attributes.title', `${number} / ${type}`)
          return (
            <TextInput style={{ display: 'none' }} source='attributes.title' />
          )
        }}
      </FormDataConsumer>

      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_counter_number'
        validate={required()}
        fullWidth={true}
      />
      <SelectInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_counter_resource'
        validate={required()}
        choices={[
          { id: 'Электроэнергия', name: 'Электроэнергия' },
          { id: 'ХВС', name: 'ХВС' },
          { id: 'ГВС носитель', name: 'ГВС носитель' },
          { id: 'ГВС энергия', name: 'ГВС энергия' },
          { id: 'Теплоснабжение', name: 'Теплоснабжение' },
          { id: 'Газ', name: 'Газ' },
        ]}
      />

      <ClearFix formClassName='clearfix' />

      <SelectInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_counter_type'
        validate={required()}
        choices={[
          { id: 'День', name: 'День' },
          { id: 'Ночь', name: 'Ночь' },
          { id: 'Пик', name: 'Пик' },
          { id: 'Подача', name: 'Подача' },
          { id: 'Обратка', name: 'Обратка' },
          { id: 'Однотарифный', name: 'Однотарифный' },
        ]}
      />

      <ReferenceInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='relationships.field_counter_supplier.data.id'
        reference='node/contractor'
        validate={required()}
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          optionText='attributes.title'
        />
      </ReferenceInput>
      <ClearFix formClassName='clearfix' />

      <SelectInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_counter_measure'
        validate={required()}
        choices={[
          { id: 'Квт*ч', name: 'Квт*ч' },
          { id: 'м3', name: 'м3' },
          { id: 'гКал', name: 'гКал' },
        ]}
      />

      <NumberInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_counter_coefficient'
        step={1}
      />
      <ClearFix formClassName='clearfix' />
      <ReferenceInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='relationships.field_counter_house.data.id'
        reference='node/house'
        validate={required()}
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
      >
        <SelectInput fullWidth optionText='attributes.title' />
      </ReferenceInput>

      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_install_location'
        fullWidth={true}
      />

      <ClearFix formClassName='clearfix' />

      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_consumer'
        fullWidth={true}
      />

      <SelectInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_counter_purpose'
        choices={[
          { id: 'Общий', name: 'Общий' },
          { id: 'МОП', name: 'МОП' },
          { id: 'Общеквартирный', name: 'Общеквартирный' },
        ]}
      />

      <ClearFix formClassName='clearfix' />
      <DateInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_counter_date_installation'
        validate={required()}
        fullWidth={true}
      />

      <DateInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_counter_date_verification'
        validate={required()}
        fullWidth={true}
      />

      <ClearFix formClassName='clearfix' />
      <DateInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_counter_date_ver_next'
        validate={required()}
        fullWidth={true}
      />

      <BooleanInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_counter_status'
        fullWidth={true}
      />
      <ClearFix formClassName='clearfix' />

      <FileInput
        label='Паспорт'
        source='included.field_files'
        accept='application/pdf'
        multiple
      >
        <FileField
          source='attributes.uri.url'
          title='attributes.filename'
          target='_blank'
        />
      </FileInput>

      <ClearFix formClassName='clearfix' />

      <TextInput
        fullWidth
        multiline
        formClassName='col-xs-12'
        source='attributes.field_counter_comment'
      />
    </SimpleForm>
  )
}

export default withStyles(styles)(EditCreateForm)
