import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FilterByPeriod from '../../../components/FilterByPeriod'


const useStyles = makeStyles(() => ({
  textField: {
    minWidth: 130,
    margin: '0px 5px'
  }
}))

function FiltersPanel(props) {
  return (
    <FilterByPeriod {...props} />
  )
}

export default FiltersPanel