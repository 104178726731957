import React, { useState } from 'react'
import { orderBy } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const headCells = [
  { field: 'HOUSE_ID', name: 'Id' },
  { field: 'HOUSE_TITLE', name: 'Дом' },
  { field: 'LOCAL_BALANCE_END', name: 'Наш остаток', units: 'руб.' },
  { field: 'ELLIS_BALANCE_END', name: 'Остаток Эллис', units: 'руб.' },
  { field: 'BALANCE_END_DIFF', name: 'Разница', units: 'руб.' }
]

function ComparisonTable(props) {
  const { data } = props

  const [order, setOrder] = useState('asc')
  const [columnToSort, setColumnToSort] = useState('HOUSE_TITLE')

  const handleRequestSort = (event, property) => {
    const isAsc = columnToSort === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setColumnToSort(property)
  };

  return (
    <Table stickyHeader>
      <EnhancedTableHead
        order={order}
        orderBy={columnToSort}
        onRequestSort={handleRequestSort}
        headCells={headCells}
      />
      <TableBody>
        {orderBy(data, columnToSort, order).map((row) => (
          <TableRow key={row.HOUSE_ID}>
            <TableCell align="center">{row.HOUSE_ID}</TableCell>
            <TableCell align="center">{row.HOUSE_TITLE}</TableCell>
            <TableCell align="center">{(row.LOCAL_BALANCE_END).toLocaleString()}</TableCell>
            <TableCell align="center">{(row.ELLIS_BALANCE_END).toLocaleString()}</TableCell>
            <TableCell align="center">{(row.BALANCE_END_DIFF).toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ComparisonTable
