import React from 'react'
import {
  Datagrid,
  ArrayField,
  FileField,
  TabbedShowLayout,
  DateField,
  Tab,
  RichTextField,
  ReferenceManyField,
  TextField,
  BooleanField,
  EditButton,
  WithPermissions
} from 'react-admin'
import AddAgreementButton from './AddAgreementButton'
import AddProtocolButton from './AddProtocolButton'
import FileSizeField from '../../components/FileSizeField'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import RecordShowActions from '../../components/RecordShowActions'
import ArrayFieldSimple from '../../components/ArrayFieldSimple'
import Show from '../../components/Show'
import checkPermission from '../../helpers/permissions'

export default props => {
  console.log('Contract show props', props)
  return (
    <WithPermissions
      render={({ permissions: roles }) => {
        const { resource } = props
        const access = `resource.${resource}.edit`
        return (
          <Show title={<Title />} actions={<RecordShowActions />} {...props}>
            <TabbedShowLayout>
              <Tab
                contentClassName='row block'
                label='resources.node/contract.tabs.main'
              >
                <TextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='attributes.field_id'
                />

                <TextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='attributes.title'
                />
                <ClearFix className='clearfix' />

                <DateField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='attributes.field_date_start'
                />

                <DateField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='attributes.field_date_end'
                />
                <ClearFix className='clearfix' />

                <RichTextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='included.field_firm.attributes.title'
                />

                <RichTextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='included.field_contractor.attributes.title'
                />
                <ClearFix className='clearfix' />

                <ArrayFieldSimple
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='included.field_house'
                  itemSource='attributes.title'
                  glue=','
                />

                <BooleanField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='attributes.field_prolongation'
                />
                <ClearFix className='clearfix' />

                <RichTextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='included.field_contract_type.attributes.title'
                />

                <RichTextField
                  className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
                  source='included.field_contract_stage.attributes.title'
                />
                <ClearFix className='clearfix' />

                <ArrayField
                  className='col-xs-12 col-fix'
                  source='included.field_files'
                >
                  <Datagrid>
                    <FileField
                      source='attributes.uri.url'
                      title='attributes.filename'
                      target='_blank'
                    />
                    <FileSizeField source='attributes.filesize' />
                  </Datagrid>
                </ArrayField>

                <RichTextField
                  className='col-xs-12 col-fix'
                  source='attributes.body.value'
                />
              </Tab>
              <Tab label='resources.node/contract.tabs.agreement' path='agreement'>
                <ReferenceManyField
                  addLabel={false}
                  reference='node/agreement'
                  target='field_contract.id'
                  sort={{ field: 'created', order: 'DESC' }}
                  fullWidth={true}
                >
                  <Datagrid rowClick='show'>
                    <TextField source='attributes.field_id' cellClassName='cell-2' />
                    <ArrayFieldSimple
                      sortBy='field_house.attributes.title'
                      source='included.field_house'
                      itemSource='attributes.title'
                      glue=','
                    />
                    <TextField source='attributes.title' cellClassName='cell-6' />
                    {checkPermission(access, roles) && <EditButton />}
                  </Datagrid>
                </ReferenceManyField>
                <AddAgreementButton />
              </Tab>
              <Tab label='resources.node/contract.tabs.protocol' path='protocol'>
                <ReferenceManyField
                  addLabel={false}
                  reference='node/protocol'
                  target='field_contract.id'
                  sort={{ field: 'created', order: 'DESC' }}
                  fullWidth={true}
                >
                  <Datagrid rowClick='show'>
                    <TextField source='attributes.field_id' cellClassName='cell-2' />
                    <TextField source='attributes.title' cellClassName='cell-10' />
                    {checkPermission(access, roles) && <EditButton />}
                  </Datagrid>
                </ReferenceManyField>
                <AddProtocolButton />
              </Tab>
            </TabbedShowLayout>
          </Show>
        )
      }}
    />
  )
}
