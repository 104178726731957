import React, { Children, cloneElement, isValidElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { useInput, useTranslate, useNotify } from 'ra-core'
import { get, forEach, replace, findIndex, map, find } from 'lodash'
import { useFieldArray } from 'react-final-form-arrays'
import {
  Labeled,
  InputHelperText,
  useDataProvider,
  fetchStart,
  fetchEnd,
} from 'react-admin'
import FileInputPreview from './FileInputPreview'
import unidecode from 'unidecode'
import globals from '../helpers/globals'
import { CREATE_FILE } from '../data-providers/ra-jsonapi-client/actions'

const useStyles = makeStyles(
  theme => ({
    dropZone: {
      background: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' }
)

const FileInput = props => {
  const {
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    validate,
    previewFilesRef,
    ...rest
  } = props
  const { record } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const dispatch = useDispatch()

  const fileFieldName = replace(source, '.', '_dot_')
  const fileField = useFieldArray(fileFieldName)
  useEffect(() => {
    const recordFieldData = get(record, source)
    forEach(recordFieldData, item => {
      if (!find(fileField.fields.value, { id: item.id }))
        fileField.fields.push(item)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const input = useInput({
    format: format,
    parse: parse,
    source,
    type: 'file',
    validate,
    ...rest,
  })

  const {
    id,
    input: { onChange, value, ...inputProps },
    meta,
    isRequired,
  } = input

  const { touched, error } = meta

  const onDrop = (newFiles, rejectedFiles, event) => {
    const reader = new FileReader()

    newFiles.forEach(file => {
      reader.readAsArrayBuffer(file)
      reader.onload = (file => {
        return e => {
          const binaryStr = reader.result
          const fileName = unidecode(file.name)
          const url = `/${globals.apiBasePath}/${resource}/field_files`
          dispatch(fetchStart())
          dataProvider(CREATE_FILE, url, {
            apiUrl: globals.apiHost,
            file: file,
            data: binaryStr,
            headers: {
              Accept: 'application/vnd.api+json; charset=utf-8',
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': 'file; filename="' + fileName + '"',
              'X-CSRF-Token': localStorage.getItem('csrf_token'),
            },
          })
            .then(res => {
              dispatch(fetchEnd())
              notify('File uploaded')
              fileField.fields.push(res.data)
            })
            .catch(e => {
              dispatch(fetchEnd())
              notify('Server Error: File not uploaded', 'warning')
            })
        }
      })(file)
    })

    if (options.onDrop) {
      options.onDrop(newFiles, rejectedFiles, event)
    }
  }

  const onRemove = file => () => {
    const removeIndex = findIndex(fileField.fields.value, { id: file.id })
    fileField.fields.remove(removeIndex)

    if (options.onRemove) {
      options.onRemove(file)
    }
  }

  const childrenElement = isValidElement(Children.only(children))
    ? Children.only(children)
    : undefined

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  })
  const dropzoneOptions = {
    ...getInputProps({
      ...inputProps,
      ...inputPropsOptions,
    }),
  }

  return (
    <Labeled
      id={id}
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      meta={meta}
    >
      <>
        <div
          data-testid='dropzone'
          className={classes.dropZone}
          {...getRootProps()}
        >
          <input
            id={id}
            {...dropzoneOptions}
            name={`${dropzoneOptions.name}_dropzone`}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        <FormHelperText>
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        </FormHelperText>
        {children && (
          <div className='previews' ref={previewFilesRef}>
            {map(fileField.fields.value, (file, index) => (
              <FileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={classes.removeButton}
              >
                {cloneElement(childrenElement, {
                  record: file,
                  className: classes.preview,
                })}
              </FileInputPreview>
            ))}
          </div>
        )}
      </>
    </Labeled>
  )
}

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
}

export default FileInput
