import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chart,
  LineSeries,
  SplineSeries,
  Title,
  Tooltip,
  ArgumentAxis,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, Palette, Stack, ArgumentScale, EventTracker, ValueScale } from '@devexpress/dx-react-chart'
import {
  curveCatmullRom,
  line,
} from 'd3-shape'
import { first, last } from 'lodash'

const colorScheme = ['#c5e1a5', '#2e7d32']

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  stabilityRatio: {
    color: '#0069c0',
    fontSize: '2.5rem',
    verticalAlign: 'sub'
  },
  chartTitle: {
    fontSize: '1rem'
  }
}))

const makeLine = width => {
  return props => (
    <LineSeries.Path
      style={{ strokeWidth: width }}
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveCatmullRom)}
    />
  )
}

export default function CumulativeTotal(props) {
  const classes = useStyles()
  const { data } = props

  const titleComponent = ({ ...restProps }) => (
    <Title.Text {...restProps} className={classes.chartTitle} />
  )

  const contentComponent = (elem => {
    return `${Number(elem.text).toLocaleString()} %`
  })

  const format = () => tick => tick % 20 === 0 ? tick : ''
  
  first(data).hundred = 100
  last(data).hundred = 100
  
  console.log(data)
  return (
    <Chart
      data={data}
      height={250}
    >
      <ArgumentScale />
      <ArgumentAxis />
      <ValueScale modifyDomain={() => [0, 120]} />
      <ValueAxis tickFormat={format} />
      <Palette scheme={colorScheme} />
      <Title text='Поступление денег нарастающим итогом (%)' textComponent={titleComponent} />
      <LineSeries
        name='prev_paid'
        valueField='prevPaid'
        argumentField='day'
        seriesComponent={makeLine(2)}
      />
      <LineSeries
        name='curr_paid'
        valueField='currPaid'
        argumentField='day'
        seriesComponent={makeLine(4)}
      />
      <LineSeries
        name='hundred'
        valueField='hundred'
        argumentField='day'
        color='#6ec6ff'
      />
      <EventTracker />
      <Tooltip contentComponent={contentComponent} />
      <Animation />
    </Chart>
  )
}