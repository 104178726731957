import React, { useState } from 'react'
import { set, get } from 'lodash'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { submit, isSubmitting } from 'redux-form'
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  crudGetMatching,
  Button,
  SaveButton,
  SimpleForm,
  DateInput,
  NumberInput,
  CREATE,
  withDataProvider,
  FormDataConsumer,
  refreshView,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'

const styles = {
  button: {
    marginTop: '1em',
  },
}

const QuickCreate = props => {
  const [state, setState] = useState({
    error: false,
    showDialog: false,
  })

  const handleClick = () => {
    setState({ showDialog: true })
  }

  const handleCloseClick = () => {
    setState({ showDialog: false })
  }

  const handleSaveClick = () => {
    const { submit } = props

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('measure-quick-create')
  }

  const handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      dataProvider,
      refreshView,
    } = props

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart()

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, 'node/measure', { data: values })
      .then(({ data }) => {
        refreshView()
        setState({ showDialog: false })
      })
      .catch(error => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd()
      })
  }

  const { showDialog } = state
  const { isSubmitting, classes, counterId } = props

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label='Add a measure'
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Add a measure'
      >
        <DialogTitle>Добавить показание</DialogTitle>
        <DialogContent>
          <SimpleForm
            // We override the redux-form name to avoid collision with the react-admin main form
            form='measure-quick-create'
            resource='node/measure'
            // We override the redux-form onSubmit prop to handle the submission ourselves
            onSubmit={handleSubmit}
            // We want no toolbar at all as we have our modal actions
            toolbar={null}
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                const date = get(formData, 'attributes.field_measure_date', '')
                set(formData, 'attributes.title', `${counterId} / ${date}`)
                set(
                  formData,
                  'relationships.field_measure_counter.data.id',
                  counterId
                )
              }}
            </FormDataConsumer>
            <DateInput
              // disabled
              defaultValue={moment().format('YYYY-MM-D')}
              fullWidth
              validate={required()}
              source='attributes.field_measure_date'
            />
            <NumberInput
              fullWidth
              validate={required()}
              source='attributes.field_measure_value'
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <SaveButton saving={isSubmitting} onClick={handleSaveClick} />
          <Button label='ra.action.cancel' onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('measure-quick-create')(state),
})

const mapDispatchToProps = {
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(QuickCreate)

export default compose(withDataProvider, withStyles(styles))(connected)
