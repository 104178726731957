import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  FileField,
  ReferenceField,
  TextField,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from 'react-admin'
import Edit from '../../components/Edit'
import FileInput from '../../components/FileInput'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  widthFix: {
    width: '100%',
    minWidth: '100%',
  },
})

export default withStyles(styles)(props => {
  const { classes } = props

  const redirect = (basePath, id, data) => {
    return '/node/contract'
    // return `/node/contract/${data.relationships.field_contract.data.id}/show/agreement`
  }

  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceField
          source='relationships.field_contract.data.id'
          reference='node/contract'
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        >
          <TextField source='attributes.title' />
        </ReferenceField>
        <ClearFix formClassName='clearfix' />

        <ReferenceInput
          source='relationships.field_contract.data.id'
          reference='node/contract'
          validate={required()}
          formClassName='col-xs-12 hidden'
        >
          <SelectInput optionText='attributes.title' />
        </ReferenceInput>

        <TextInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_id'
          fullWidth={true}
        />
        <TextInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.title'
          validate={required()}
          fullWidth={true}
        />
        <ClearFix formClassName='clearfix' />

        <DateInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_date_start'
          fullWidth={true}
        />

        <DateInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_date_end'
          fullWidth={true}
        />
        <ClearFix formClassName='clearfix' />

        <ReferenceArrayInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          className={classes.widthFix}
          source='relationships.field_house.data'
          reference='node/house'
          sort={{ field: 'title', order: 'ASC' }}
          perPage={50}
        >
          <SelectArrayInput optionText='attributes.title' />
        </ReferenceArrayInput>
        <BooleanInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_prolongation'
          fullWidth={true}
        />
        <ClearFix formClassName='clearfix' />

        <FileInput
          formClassName='file-input col-xs-12'
          source='included.field_files'
          accept='application/pdf'
          fullWidth={true}
        >
          <FileField
            formClassName='file-field'
            source='attributes.uri.url'
            title='attributes.filename'
            target='_blank'
          />
        </FileInput>

        <TextInput
          multiline
          formClassName='col-xs-12'
          source='attributes.body.value'
          fullWidth={true}
        />
      </SimpleForm>
    </Edit>
  )
})
