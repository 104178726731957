import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import FiltersPanel from './FiltersPanel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import {
  isEmpty,
  merge,
  map,
  uniqBy,
  filter,
  fromPairs,
  find,
  findIndex,
  round,
  cloneDeep,
  orderBy,
  reduce
} from 'lodash'
import { addMonth, stringifyDate, renameObjectKey } from '../../../helpers/tools'
import BarSeriesChart from '../../../components/charts/BarSeriesChart'
import LoadingIndicator from '../../../components/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}))

function Ratings(props) {
  const classes = useStyles()
  const { permissions } = usePermissions()
  const dispatch = useDispatch()

  const localDate = new Date()

  const [currYear, setYear] = useState(localDate.getFullYear())
  const [currMonth, setMonth] = useState(localDate.getMonth())
  const currDate = new Date(currYear, currMonth)

  const [currentHousekeeper, setHousekeeper] = useState('empty')
  const [housekeepersList, setHousekeepersList] = useState([])

  const [housesFeeData, setHousesFeeData] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const changeYear = event => setYear(event.target.value)
  const changeMonth = event => setMonth(event.target.value)
  const changeHousekeeper = event => setHousekeeper(event.target.value)

  const filterByHousekeeper = (data) => {
    const index = findIndex(housekeepersList, elem => elem.id === currentHousekeeper)
    return currentHousekeeper === 'empty' || index === -1
      ? data
      : filter(data, elem => elem.housekeeper_id === currentHousekeeper)
  }

  async function makeRequest(url) {
    const response = await fetch(url, { credentials: 'include' })
    return await response.json()
  }

  const average = (nums) => {
    return nums.reduce((a, b) => (a + b)) / nums.length;
  }

  useEffect(() => {
    setIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? [] : data
        })
        .finally(() => {
          const pending = storage.pendingData
          if (--storage.counter === 0) {
            const houseKey = ['house_id']

            const megreBy = (elem, mergeFrom, keys, defaultValue) => {
              const query = fromPairs(keys.map(key => [key, elem[key]]))
              const data = find(mergeFrom, query)
              return merge(elem, data || defaultValue)
            }

            const mergeBills = (elem, mergeFrom, keys) => megreBy(elem, mergeFrom, keys, { paid: '0.00', prev_paid: '0.00' })
            const mergePayments = (elem, mergeFrom, keys) => megreBy(elem, mergeFrom, keys, { accrued: '0.00', balance_start: '0.00', prev_paid: '0.00' })


            const houseBills = map(pending['house-bills'], e => renameObjectKey(e, 'paid', 'prev_paid'))
            const housesBillsData = map(cloneDeep(houseBills), (elem) => mergeBills(elem, pending['house-payments'], houseKey))
            const housesPaymentsData = map(pending['house-payments'], (elem) => mergePayments(elem, cloneDeep(houseBills), houseKey))

            setHousesFeeData(filter(uniqBy(housesBillsData.concat(housesPaymentsData), 'house_id'), e => e.housekeeper_id !== '833172'))

            setIsLoaded(true)
            dispatch(fetchEnd())
          }

        })
    }

    getData('house-bills', [`&date=${stringifyDate(addMonth(currDate, -1))}`])
    getData('house-payments', [`&date=${stringifyDate(currDate)}`])
  }, [currYear, currMonth])

  useEffect(() => {
    if (!isEmpty(housesFeeData)) {
      const newHousekeepersList = uniqBy(filter(map(housesFeeData, (elem) => {
        const keeper = elem.housekeeper_id
          ? { id: elem.housekeeper_id, title: elem.housekeeper_title }
          : null

        return keeper
      }), (elem) => { return elem !== null }), 'id')

      setHousekeepersList(newHousekeepersList)
    }
  }, [housesFeeData])

  const getFilterableData = () => {
    let result

    if (currentHousekeeper === 'empty')
      result = housesFeeData
    else
      result = filterByHousekeeper(housesFeeData)

    return result
  }

  const filteredData = map(getFilterableData(), (elem) => {
    const { accrued, paid, prev_paid, balance_start, house_title, house_id, housekeeper_id, housekeeper_title } = elem
    const debtStart = Number(balance_start) - Number(prev_paid)
    const debtEnd = debtStart + Number(accrued) - Number(paid)
    return {
      housekeeper_id,
      housekeeper_title,
      house_id,
      accrued: Number(accrued),
      paid: Number(paid),
      house_title,
      debt_start: debtStart,
      debt_end: debtEnd,
      tax_percent: Number(paid) !== 0 && Number(accrued) !== 0 ? round(Number(paid) * 100 / Number(accrued)) : Number(paid) !== 0 ? 100 : 0,
      financial_indicator: Number(accrued) !== 0 ? round(debtEnd / Number(accrued), 2) : 0,
    }
  })

  let taxChartData
  let financialIndicatorData

  if (currentHousekeeper === 'empty') {
    const result = []

    for (const elem in filteredData) {
      const meanTaxElem = find(result, ['housekeeper', filteredData[elem].housekeeper_title])
      if (meanTaxElem) {
        meanTaxElem.accrued += filteredData[elem].accrued
        meanTaxElem.paid += filteredData[elem].paid
        meanTaxElem.debt_end += filteredData[elem].debt_end
      }
      else
        result.push({
          housekeeper: filteredData[elem].housekeeper_title,
          accrued: filteredData[elem].accrued,
          paid: filteredData[elem].paid,
          debt_end: filteredData[elem].debt_end
        })
    }

    taxChartData = result

    taxChartData = orderBy(map(result, e => {
      const taxPercent = e.paid !== 0 && e.accrued !== 0 ? round((e.paid * 100 / e.accrued), 2) : e.paid !== 0 ? 100 : 0
      return { argument: e.housekeeper, value: taxPercent }
    }), ['value'], ['asc'])

    financialIndicatorData = orderBy(map(result, e => {
      const financialIndicator = e.accrued !== 0 ? round(e.debt_end / e.accrued, 2) : 0
      return { argument: e.housekeeper, value: financialIndicator }
    }), ['value'], ['desc'])
  }
  else {
    taxChartData = orderBy(map(filteredData, e => {
      return { argument: e.house_title, value: e.tax_percent }
    }), ['value'], ['asc'])

    financialIndicatorData = orderBy(map(filteredData, e => {
      return { argument: e.house_title, value: e.financial_indicator }
    }), ['value'], ['desc'])
  }

  return (
    <>
      <Title title="Рейтинги" />
      {checkPermission(`menuItem./node/analytics/ratings`, permissions) &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FiltersPanel
                defaultDate={localDate}
                currentYear={currYear}
                changeYear={changeYear}
                changeMonth={changeMonth}
                changeHousekeeper={changeHousekeeper}
                housekeepersList={housekeepersList}
                isLoaded={isLoaded}
              />
            </Paper>
          </Grid>
          {isLoaded ?
            <Grid item xs={12}>
              <Paper>
                <Grid container>
                  <Grid item xs={6}>
                    {!isEmpty(taxChartData) &&
                      <BarSeriesChart
                        chartData={taxChartData}
                        seriesData={[
                          { name: 'Сбор', value: 'value', argument: 'argument', color: '6ec6ff' }
                        ]}
                        height={250}
                        rotated={true}
                        isTitled={true}
                        title='Сбор'
                        isTooltipped={true}
                        tooltipComponent={e => `${round(e.text, 2).toLocaleString()} %`}
                      />
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {!isEmpty(financialIndicatorData) &&
                      <BarSeriesChart
                        chartData={financialIndicatorData}
                        seriesData={[
                          { name: 'Фин.устойчивость', value: 'value', argument: 'argument', color: '6ec6ff' }
                        ]}
                        height={250}
                        rotated={true}
                        isTitled={true}
                        title='Показатель фин. устойчивости'
                        isTooltipped={true}
                        tooltipComponent={e => `${round(e.text, 2).toFixed(2).replace('.', ',')}`}
                      />
                    }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            :
            <Grid item xs={12}>
              <LoadingIndicator />
            </Grid>
          }
        </Grid>
      }
    </>
  )
}

Ratings.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default Ratings
