import * as React from "react";
import {
  Chart,
  ValueAxis,
  BarSeries,
  Title,
  Legend
} from "@devexpress/dx-react-chart-material-ui"
import { withStyles } from "@material-ui/core/styles"
import { Stack, Animation, ValueScale } from "@devexpress/dx-react-chart"
import { stackOffsetExpand } from "d3-shape"
import { map, floor, cloneDeep } from "lodash"

const legendStyles = () => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row"
  }
});
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: "nowrap"
  }
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

const format = () => tick => tick * 10 % 2 === 0 ? tick * 100 : ''

function FeeChart(props) {
  const { data, chartHeight, chartWidth, barWidth, title, legend } = props
  const newData = cloneDeep(data)
  const taxPercent = newData[0].tax_percent

  const chartData = [
    {
      house: newData[0].house,
      paid: taxPercent > 100 ? 100 : taxPercent,
      accrued: taxPercent !== 0 ? taxPercent > 100 ? 0 : floor(100 - taxPercent) : 100
    }
  ]

  return (
    <Chart
      data={chartData}
      height={chartHeight}
      width={chartWidth}
      rotated
    >
      {title && <Title text="Общий сбор" />}
      <ValueAxis tickFormat={format} indentFromAxis={5}/>

      <BarSeries
        name='Оплачено'
        valueField="paid"
        argumentField="house"
        barWidth={barWidth}
        color="#0069c0"
      />
      <BarSeries
        name='Начислено'
        valueField="accrued"
        argumentField="house"
        barWidth={barWidth}
        color="#6ec6ff"
      />
      <Animation />
      {legend &&
        <Legend
          position="bottom"
          rootComponent={Root}
          labelComponent={Label}
        />
      }
      <Stack
        stacks={[{ series: ['Оплачено', 'Начислено'] }]}
        offset={stackOffsetExpand}
      />
    </Chart>
  )
}

export default withStyles(legendStyles)(FeeChart)