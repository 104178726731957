import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        margin: '0px 5px',
    }
}))

function SelectFormControl(props) {
    const classes = useStyles()
    const { label, list, onChange, disabled, defaultValue = 'empty', property = '', id = 'id', title = 'title' } = props

    let idProp = id
    let titleProp = title

    if (property !== '') {
        if (idProp.length)
            idProp = '_' + idProp
        if (titleProp.length)
            titleProp = '_' + titleProp

        idProp = `${property}${idProp}`
        titleProp = `${property}${titleProp}`
    }

    return (
        <FormControl variant='filled' className={classes.formControl} disabled={disabled || isEmpty(list)} size='small'>
            <InputLabel>{label}</InputLabel>
            <Select
                defaultValue={defaultValue}
                onChange={onChange}
            >
                <MenuItem value='empty'>&nbsp;</MenuItem>
                {
                    list.map((elem) => (
                        <MenuItem key={elem[idProp]} value={elem[idProp]}>{elem[titleProp]}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectFormControl