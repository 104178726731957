import React from 'react'
import {
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  FileField
} from 'react-admin'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import Show from '../../components/Show'
import FileSizeField from '../../components/FileSizeField'

export default props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='included.field_house_single.attributes.title'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_date'
        />
        <ClearFix className='clearfix' />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_accrued'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_text_1'
        />
        <ClearFix className='clearfix' />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_text_2'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_text_3'
        />
        <ClearFix className='clearfix' />

        <ArrayField
          className='col-xs-12 col-fix'
          source='included.field_files'
        >
          <Datagrid>
            <FileField
              source='attributes.uri.url'
              title='attributes.filename'
              target='_blank'
            />
            <FileSizeField source='attributes.filesize' />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}
