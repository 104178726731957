import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    header: {
      padding: 10,
      borderRadius: '4px 4px 0px 0px',
      backgroundColor: '#283593',
      color: 'white'
    },
  }))


function PrimaryBlockHeader(props) {
    const classes = useStyles()
    const { title } = props
    return (
        <div className={classes.header}>{title}</div>
    )
}

export default PrimaryBlockHeader