import React from 'react'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  AutocompleteInput,
  DateInput,
} from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'

export default props => {
  return (
    <Filter {...props}>
      <TextInput
        source='field_search'
        alwaysOn
        parse={value => {
          return {
            path: 'field_search',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <DateInput
        source='field_date_start'
        label='Дата начала'
        alwaysOn
        parse={value => {
          return {
            path: 'field_date_start',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      ></DateInput>
      <TextInput
        source='title'
        alwaysOn
        parse={value => {
          return {
            path: 'title',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      ></TextInput>
      <ReferenceInput
        source='field_house'
        alwaysOn
        reference='node/house'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_house.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput optionText='attributes.title' />
      </ReferenceInput>
      <ReferenceInput
        source='field_contract_type'
        alwaysOn
        reference='node/contract_type'
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_contract_type.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <ReferenceInput
        source='field_firm'
        alwaysOn
        reference='node/firm'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_firm.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <ReferenceInput
        source='field_contractor'
        alwaysOn
        reference='node/contractor'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        // allowEmpty
        parse={value => {
          return {
            path: 'field_contractor.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput optionText='attributes.title' />
      </ReferenceInput>
    </Filter>
  )
}
