import React from 'react'
import {
  Datagrid,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  RichTextField,
  ReferenceManyField,
  EditButton,
} from 'react-admin'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import Show from '../../components/Show'

export default props => {
  return (
    <Show title={<Title />} {...props}>
      <TabbedShowLayout>
        <Tab contentClassName='row block' label='resources.node/firm.tabs.main'>
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
            source='attributes.title'
          />
          <ClearFix className='clearfix' />

          <RichTextField className='col-xs-12' source='attributes.body.value' />
        </Tab>
        <Tab
          contentClassName='row block'
          label='resources.node/firm.tabs.contracts'
          path='contracts'
        >
          <ReferenceManyField
            addLabel={false}
            reference='node/contract'
            target='field_firm.id'
            sort={{ field: 'created', order: 'DESC' }}
            fullWidth={true}
          >
            <Datagrid rowClick='show'>
              <TextField source='attributes.field_id' cellClassName='cell-1' />
              <RichTextField
                sortBy='field_contractor.attributes.title'
                source='included.field_contractor.attributes.title'
                cellClassName='cell-2'
              />
              <TextField
                sortBy='field_contract_type.attributes.title'
                source='included.field_contract_type.attributes.title'
                cellClassName='cell-2'
              />
              <TextField
                sortBy='field_contract_stage.attributes.title'
                source='included.field_contract_stage.attributes.title'
                cellClassName='cell-2'
              />
              <TextField source='attributes.title' cellClassName='cell-7' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          contentClassName='row block'
          label='resources.node/firm.tabs.foundingDocuments'
          path='founding-documents'
        >
          <ReferenceManyField
            addLabel={false}
            reference='node/founding_document'
            target='field_firm.id'
            sort={{ field: 'created', order: 'DESC' }}
            fullWidth={true}
          >
            <Datagrid rowClick='show'>
              <DateField
                source='attributes.field_date'
                cellClassName='cell-2'
              />
              <TextField source='attributes.title' cellClassName='cell-10' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
