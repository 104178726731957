import React from 'react'
import { Create } from 'react-admin'
import Form from './EditCreateForm'

export default props => {
  return (
    <Create {...props}>
      <Form />
    </Create>
  )
}
