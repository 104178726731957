import React from 'react'
import { Datagrid, EditButton, TextField, DateField } from 'react-admin'
import List from '../../components/List'
import checkPermission from '../../helpers/permissions'
import ListFilters from './ListFilters'

export default props => {
  const { permissions, resource } = props
  return (
    <List filters={<ListFilters />} {...props} bulkActionButtons={false} creatable={false} sort={{ field: 'attributes.field_date', order: 'DESC' }}>
      <Datagrid rowClick='show'>
        <TextField cellClassName='cell-2' source='included.field_house_single.attributes.title' sortBy='field_house_single.attributes.title'/>
        <DateField cellClassName='cell-2' source='attributes.field_date' />
        <TextField cellClassName='cell-2' source='attributes.field_accrued' />
        <TextField cellClassName='cell-2' source='attributes.field_text_1' />
        <TextField cellClassName='cell-2' source='attributes.field_text_2' />
        <TextField cellClassName='cell-2' source='attributes.field_text_3' />
        {checkPermission(`resource.${resource}.edit`, permissions) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  )
}
