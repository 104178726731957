import React from 'react'
import { Datagrid, EditButton, TextField, DateField } from 'react-admin'
import BooleanField from '../../components/BooleanField'
import List from '../../components/List'
import checkPermission from '../../helpers/permissions'
import ListFilters from './ListFilters'

export default props => {
  const { permissions, resource } = props
  return (
    <List filters={<ListFilters />} {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField source='attributes.field_counter_number' />
        <TextField
          sortBy='field_counter_house.attributes.title'
          source='included.field_counter_house.attributes.title'
        />
        <TextField source='attributes.field_counter_resource' />
        <TextField
          sortBy='field_counter_supplier.attributes.title'
          source='included.field_counter_supplier.attributes.title'
        />
        <TextField source='attributes.field_counter_type' />
        <TextField source='attributes.field_counter_purpose' />
        <DateField source='attributes.field_counter_date_ver_next' />
        <BooleanField source='attributes.field_counter_status' />
        {checkPermission(`resource.${resource}.edit`, permissions) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  )
}
