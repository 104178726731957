import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  FileField,
  NumberInput,
  required,
  BooleanInput,
} from 'react-admin'
import ClearFix from '../../components/ClearFix'
import FileInput from '../../components/FileInput'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  widthFix: {
    width: '100%',
    minWidth: '100%',
  },
})

const EditCreateForm = props => {
  const { classes } = props

  return (
    <SimpleForm {...props} redirect='/node/house'>
      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.title'
        validate={required()}
        fullWidth={true}
      />
      <ReferenceInput
        className={classes.widthFix}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_area.data.id'
        reference='node/area'
        sort={{ field: 'title', order: 'ASC' }}
        validate={required()}
        fullWidth={true}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <ClearFix formClassName='clearfix' />

      <ReferenceInput
        className={classes.widthFix}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_firm.data.id'
        reference='node/firm'
        sort={{ field: 'title', order: 'ASC' }}
        validate={required()}
        fullWidth={true}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <ClearFix formClassName='clearfix' />

      <NumberInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_area_paid'
        fullWidth={true}
      />
      <NumberInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_area_mutual'
        fullWidth={true}
      />
      <ClearFix formClassName='clearfix' />
      <BooleanInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_active'
      />
      <FileInput
        formClassName='col-xs-12'
        // label='Паспорт'
        source='included.field_files'
        accept='application/pdf'
        multiple
      >
        <FileField
          source='attributes.uri.url'
          title='attributes.filename'
          target='_blank'
        />
      </FileInput>
      <TextInput
        multiline
        fullWidth
        formClassName='col-xs-12'
        source='attributes.body.value'
      />
    </SimpleForm>
  )
}

export default withStyles(styles)(EditCreateForm)
