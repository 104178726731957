import { has, forEach, forIn, cloneDeep, set, get, unset } from 'lodash'
import globals from '../../helpers/globals'

const FIRM = 'node--firm',
  PROTOCOL = 'node--protocol',
  CONTRACT_TYPE = 'node--contract_type',
  HOUSE = 'node--house',
  AREA = 'node--area',
  CONTRACTOR = 'node--contractor',
  CONTRACT = 'node--contract',
  CONTRACT_STAGE = 'node--contract_stage',
  AGREEMENT = 'node--agreement',
  COUNTER = 'node--counter',
  PARENT = 'node--outgoing_mail',
  MEASURE = 'node--measure'

const field_types = {
  field_contract_type: CONTRACT_TYPE,
  field_contract_stage: CONTRACT_STAGE,
  field_firm: FIRM,
  field_house: HOUSE,
  field_contractor: CONTRACTOR,
  field_area: AREA,
  field_contract: CONTRACT,
  field_parent: PARENT,
  field_counter_house: HOUSE,
  field_counter_supplier: CONTRACTOR,
  field_measure_counter: COUNTER,
}

const resource_types = {
  'node/protocol': PROTOCOL,
  'node/firm': FIRM,
  'node/firm_payments_bills': 'node--firm_payments_bills',
  'node/area': AREA,
  'node/contract': CONTRACT,
  'node/contractor': CONTRACTOR,
  'node/agreement': AGREEMENT,
  'node/house': HOUSE,
  'node/counter': COUNTER,
  'node/measure': MEASURE,
  'node/incoming_mail': 'node--incoming_mail',
  'node/outgoing_mail': 'node--outgoing_mail',
  'node/founding_document': 'node--founding_document',
}

const bool_fields = [
  'field_prolongation',
  'field_counter_status',
  'field_active',
  'field_response_required',
]

const requestNormalize_CREATE = (resource, data) => {
  data = requestNormalize(resource, data)
  if (!has(data, 'type') && has(resource_types, resource)) {
    data.type = resource_types[resource]
  }
  set(data, 'attributes.field_status', 1)
  return data
}

const requestNormalize_DELETE = (resource, data) => {
  data = requestNormalize(resource, data)
  set(data, 'attributes.field_status', 0)
  return data
}

const requestNormalize = (resource, data) => {
  console.log('requestNormalize -> data', data)
  const normalized = cloneDeep(data)
  forIn(normalized.relationships, (value, key) => {
    if (
      has(value, 'data.id') &&
      !has(value, 'data.type') &&
      has(field_types, key)
    ) {
      value.data.type = field_types[key]
    }
  })

  const field_house = get(data, 'relationships.field_house.data')
  if (field_house) {
    const normalized_field_house = field_house.map(id => {
      return {
        id,
        type: 'node--house',
      }
    })
    set(normalized, 'relationships.field_house.data', normalized_field_house)
  }

  const field_files = get(normalized, 'included_dot_field_files')
  if (field_files) {
    const normalized_field_files = field_files.map(item => {
      return {
        id: item.id,
        type: 'file--file',
      }
    })
    set(normalized, 'relationships.field_files.data', normalized_field_files)
  }

  unset(normalized, 'included')
  unset(normalized, 'included_dot_field_files')
  console.log('requestNormalize -> normalized', normalized)
  return normalized
}

const responseNormalize = (resource, data, method) => {
  if (has(data, 'data.relationships.field_house.data')) {
    data.data.relationships.field_house.data = data.data.relationships.field_house.data.map(
      item => {
        return item.id
      }
    )
  }
  if (has(data, 'data.included.field_files')) {
    data.data.included.field_files = data.data.included.field_files.map(
      item => {
        item.attributes.uri.url = globals.apiHost + item.attributes.uri.url
        return item
      }
    )
  }
  if (has(data, 'data.attributes.uri.url')) {
    data.data.attributes.uri.url =
      globals.apiHost + data.data.attributes.uri.url
  }
  const field_house = get(data, 'data.relationships.field_house.data')
  if (field_house) {
    const normalized_field_house = field_house.map(item => {
      return get(item, 'id')
    })
    set(data, 'data.relationships.field_house.data.id', normalized_field_house)
  }

  forEach(bool_fields, item => {
    if (has(data, `data.attributes.${item}`)) {
      const val = get(data, `data.attributes.${item}`)
      const norm_val = val === '0' ? false : true
      set(data, `data.attributes.${item}`, norm_val)
    }
  })
  console.log('TCL: responseNormalize -> data', data)
  return data
}

export {
  responseNormalize,
  requestNormalize,
  requestNormalize_CREATE,
  requestNormalize_DELETE,
}
