import React from 'react'
import { Datagrid, EditButton, TextField, DateField } from 'react-admin'
import List from '../../components/List'
import checkPermission from '../../helpers/permissions'
import ListFilters from './ListFilters'

export default props => {
  console.log('props', props)
  const { permissions, resource } = props
  return (
    <List {...props} filters={<ListFilters />} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField
          sortBy='field_measure_counter.field_counter_house.attributes.title'
          source='included.field_counter_house.attributes.title'
        />
        <TextField
          sortBy='field_measure_counter.attributes.title'
          source='included.field_measure_counter.attributes.title'
        />
        <DateField source='attributes.field_measure_date' />
        <TextField source='attributes.field_measure_value' />
        {checkPermission(`resource.${resource}.edit`, permissions) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  )
}
