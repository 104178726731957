import React from 'react'
import { SimpleForm, TextInput, required } from 'react-admin'
import ClearFix from '../../components/ClearFix'

export default props => {
  return (
    <SimpleForm {...props} redirect={'/node/contractor'}>
      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.title'
        validate={required()}
        fullWidth={true}
      />
      <ClearFix formClassName='clearfix' />

      <TextInput
        multiline
        formClassName='col-xs-12'
        source='attributes.body.value'
        fullWidth={true}
      />
    </SimpleForm>
  )
}
