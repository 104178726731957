import React from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'

export default props => {
  return (
    <Filter {...props}>
      <TextInput
        label='Номер'
        source='field_id'
        alwaysOn
        parse={value => {
          return {
            path: 'field_id',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <TextInput
        label='Лицевой счёт'
        source='field_account'
        alwaysOn
        parse={value => {
          return {
            path: 'field_account.title',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <ReferenceInput
        source='field_house_single'
        reference='node/house'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        allowEmpty
        alwaysOn
        parse={value => {
          return {
            path: 'field_account.field_house_single.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <TextInput
        label='Помещение'
        source='field_room_number'
        alwaysOn
        parse={value => {
          return {
            path: 'field_account.field_room_number',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
    </Filter>
  )
}
