import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { stringifyDate, addDay } from '../helpers/tools'


const useStyles = makeStyles((theme) => ({
    textField: {
        minWidth: 130,
        margin: '0px 5px'
    }
}))

function FiltersPanel(props) {
    const classes = useStyles()
    const {
        startDate,
        endDate,
        changeStartDate,
        changeEndDate,
        isLoaded
    } = props

    return (
        <>
            <TextField
                label='Начало периода'
                disabled={!isLoaded}
                type='date'
                defaultValue={startDate}
                required={true}
                variant='filled'
                className={classes.textField}
                onChange={changeStartDate}
                size='small'
            />
            <TextField
                label='Конец периода'
                disabled={!isLoaded}
                type='date'
                defaultValue={endDate}
                required={true}
                variant='filled'
                className={classes.textField}
                onChange={changeEndDate}
                size='small'
            />
        </>
    )
}

export default FiltersPanel