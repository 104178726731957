import list from './List'
import edit from './Edit'
import create from './Create'
import show from './Show'

export default {
  name: 'node/contract',
  list,
  edit,
  create,
  show
}
