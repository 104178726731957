import React from 'react'
import { SimpleForm, TextInput, required } from 'react-admin'
import Title from '../../components/Title'
import Edit from '../../components/Edit'

export default props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput source='attributes.title' validate={required()} />
      <TextInput fullWidth multiline source='attributes.body.value' />
    </SimpleForm>
  </Edit>
)
