import React, { useState } from 'react'
import { orderBy, get } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const headCells = [
  { field: 'id', name: 'Id' },
  { field: 'house_title', name: 'Дом' },
  { field: 'service_title', name: 'Услуга' },
  { field: 'local_paid', name: 'Оплата наша', units: 'руб.' },
  { field: 'ellis_paid', name: 'Оплата Эллис', units: 'руб.' },
  { field: 'paid_diff', name: 'Разница', units: 'руб.' }
]

function ComparisonTable(props) {
  const { data } = props
  const [order, setOrder] = useState('asc')
  const [columnToSort, setColumnToSort] = useState('house_title')

  const handleRequestSort = (event, property) => {
    const isAsc = columnToSort === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setColumnToSort(property)
  };

  return (
    <Table stickyHeader>
      <EnhancedTableHead
        order={order}
        orderBy={columnToSort}
        onRequestSort={handleRequestSort}
        headCells={headCells}
      />
      <TableBody>
        {orderBy(data, columnToSort, order).map((row) => {
          return (
            <TableRow key={row.id}>
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.house_title}</TableCell>
              <TableCell align="center">{row.service_title}</TableCell>
              <TableCell align="center">{(get(row, 'local_paid', 0)).toLocaleString()}</TableCell>
              <TableCell align="center">{(get(row, 'ellis_paid', 0)).toLocaleString()}</TableCell>
              <TableCell align="center">{(get(row, 'paid_diff', 0)).toLocaleString()}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default ComparisonTable
