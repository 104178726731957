import React, { useEffect, useState } from 'react'
import { Datagrid, TextField, DateField, useMutation } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { TextField as MUITextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '../../components/List'
import ListFilters from './ListFilters'
import { forEach, get } from 'lodash'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '300px',
  },
  button: {
    alignSelf: 'flex-end',
    marginLeft: '20px',
  },
}))

const IpuValues = props => {
  const classes = useStyles()
  const { id } = props
  const [getValues, { data, loaded: valuesLoaded }] = useMutation({
    type: 'getManyReference',
    resource: 'node/ipu_value',
    payload: {
      target: 'field_ipu.id',
      id: id,
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'field_date', order: 'DESC' },
    },
  })
  const normalized = {}
  const ids = []
  forEach(data, item => {
    normalized[item.id] = item
    ids.push(item.id)
    item.attributes.field_value[0] = parseFloat(item.attributes.field_value[0])
  })
  const [value, setValue] = useState()

  const [mutate, { loading: valuesUpdating }] = useMutation()

  const submitHandler = () => {
    const lastValueMonth = moment(
      get(data, '[0].attributes.field_date', 0)
    ).format('YYYY-MM')

    const mutateData = {
      type: 'create',
      resource: 'node/ipu_value',
      payload: {
        data: {
          attributes: {
            title: 'Показание ИПУ',
            field_date: moment().format('YYYY-MM-DD'),
            field_value: value,
          },
          relationships: {
            field_ipu: {
              data: {
                type: 'node--ipu',
                id: id,
              },
            },
          },
          type: 'node--ipu_value',
        },
      },
    }

    if (lastValueMonth === moment().format('YYYY-MM')) {
      const lastValueId = get(data, '[0].id')
      mutateData.type = 'update'
      mutateData.payload.id = lastValueId
      mutateData.payload.data.id = lastValueId
    }

    mutate(mutateData)
  }

  useEffect(() => {
    if (valuesUpdating === false) {
      getValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesUpdating])

  useEffect(() => {
    const lastValue = get(data, '[0].attributes.field_value[0]', 0)
    setValue(lastValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesLoaded])

  return (
    <div style={{ margin: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <form
          onSubmit={e => {
            e.preventDefault()
            submitHandler()
          }}
        >
          <div className={classes.root}>
            <div className={classes.input}>
              <MUITextField
                type='number'
                autoFocus
                name='value'
                label='Показание'
                disabled={valuesUpdating}
                fullWidth
                variant='filled'
                value={value}
                min={value}
                onChange={e => {
                  setValue(e.target.value)
                }}
              />
            </div>
            <div className={classes.button}>
              <Button
                type='submit'
                color='primary'
                disabled={valuesUpdating}
                fullWidth
                variant='outlined'
              >
                {valuesUpdating && <CircularProgress size={25} thickness={2} />}
                {'Отправить'}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Datagrid
        basePath='/node/ipu_value'
        resource='/node/ipu_value'
        data={normalized}
        ids={ids}
        currentSort={{
          field: '',
          order: '',
        }}
        setSort={() => {}}
        rowClick={() => {}}
        loaded={valuesLoaded}
      >
        <DateField source='attributes.field_date' label='Дата' />
        <TextField source='attributes.field_value[0]' label='Показание' />
      </Datagrid>
    </div>
  )
}

export default props => {
  return (
    <List
      filter={{ field_text_1: 'ГВС' }}
      filters={<ListFilters />}
      {...props}
      bulkActionButtons={false}
      actions={<></>}
      creatable={true}
    >
      <Datagrid rowClick='expand' expand={<IpuValues />}>
        <TextField source='attributes.field_counter_number' />
        <TextField
          source='included.field_account.attributes.title'
          sortBy='field_account.attributes.title'
        />
        <TextField
          source='included.field_house_single.attributes.title'
          sortBy='field_account.field_house_single.attributes.title'
        />
        <TextField
          source='included.field_account.attributes.field_room_number'
          sortBy='field_account.attributes.field_room_number'
        />
        <TextField
          source='included.field_account.attributes.field_room_type'
          sortBy='field_account.attributes.field_room_type'
        />
      </Datagrid>
    </List>
  )
}
