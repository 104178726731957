import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { stringifyDate } from '../../../helpers/tools'
import { isEmpty } from 'lodash'
import SelectFormControl from '../../../components/SelectFormControl'

const useStyles = makeStyles((theme) => ({
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.2rem'
  },
  bold: {
    fontWeight: 'bold'
  },
  filter: {
    textAlign: 'right',
  },
  formControl: {
    margin: '0px 5px',
    minWidth: 120,
  },
  select: {
    fontSize: '0.9rem'
  }
}))

export default function Header(props) {
  const classes = useStyles()

  const {
    changeHousekeeper,
    housekeepersList,
    changeHouse,
    housesList,
    isLoaded,
  } = props

  return (
    <Grid container>
      <Grid item xs={3} xl={5}>
      </Grid>
      <Grid item xs={3} xl={2}>
        <Grid container className={classes.header}>
          <Grid item className={classes.bold}>Пульс Домохозяин</Grid>
          <Grid item>на {stringifyDate(new Date(), 'DD.MM.YYYY')}</Grid>
        </Grid>
      </Grid>
      <Grid className={classes.filter} item xs={6} xl={5}>
        <SelectFormControl label='Управляющий' list={housekeepersList} onChange={changeHousekeeper} disabled={!isLoaded}/>
        <SelectFormControl label='Дом' list={housesList} onChange={changeHouse} disabled={!isLoaded}/>
      </Grid>
    </Grid>
  )
}