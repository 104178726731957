import React from 'react'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  AutocompleteInput,
} from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'

export default props => {
  return (
    <Filter {...props}>
      <TextInput
        label='Номер'
        source='field_counter_number'
        alwaysOn
        parse={value => {
          return {
            path: 'field_counter_number',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <ReferenceInput
        source='field_counter_house'
        reference='node/house'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        allowEmpty
        alwaysOn
        parse={value => {
          return {
            path: 'field_counter_house.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <SelectInput
        label='Ресурс'
        source='field_counter_resource'
        allowEmpty
        alwaysOn
        choices={[
          { id: 'Электроэнергия', name: 'Электроэнергия' },
          { id: 'ХВС', name: 'ХВС' },
          { id: 'ГВС носитель', name: 'ГВС носитель' },
          { id: 'ГВС энергия', name: 'ГВС энергия' },
          { id: 'Теплоснабжение', name: 'Теплоснабжение' },
          { id: 'Газ', name: 'Газ' },
        ]}
      />
      <SelectInput
        label='Тип'
        allowEmpty
        source='field_counter_type'
        alwaysOn
        choices={[
          { id: 'День', name: 'День' },
          { id: 'Ночь', name: 'Ночь' },
          { id: 'Пик', name: 'Пик' },
          { id: 'Подача', name: 'Подача' },
          { id: 'Обработка', name: 'Обработка' },
        ]}
      />
      <SelectInput
        label='Назначение'
        allowEmpty
        source='field_counter_purpose'
        alwaysOn
        choices={[
          { id: 'Общий', name: 'Общий' },
          { id: 'МОП', name: 'МОП' },
          { id: 'Общеквартирный', name: 'Общеквартирный' },
        ]}
      />
      <ReferenceInput
        source='field_counter_supplier'
        alwaysOn
        reference='node/contractor'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        allowEmpty
        parse={value => {
          return {
            path: 'field_counter_supplier.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput optionText='attributes.title' />
      </ReferenceInput>
    </Filter>
  )
}
