import list from './List'
import edit from './Edit'
import create from './Create'
import show from './Show'
import icon from '@material-ui/icons/SupervisorAccount'

export default {
  name: 'node/founding_document',
  list,
  show,
  edit,
  create,
  icon
}
