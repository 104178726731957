const globals = {
  apiHost: 'https://api.xn--d1agdohcba6d8e.xn--p1acf',
  apiBasePath: 'jsonapi',
  values: {
    contract: {
      stage: {
        acceptNotification: '1bc26501-6f6d-411c-953f-44dd1615e758',
        canceled: '7615ee6e-eea3-4583-ac1d-4ef1d9849131',
        cancelNotification: '8c7d8625-0c45-4c5e-8cbd-e816be1429d8'
      }
    }
  }
}

export default globals
