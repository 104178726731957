import React from 'react'
import { Datagrid, EditButton, TextField } from 'react-admin'
import List from '../../components/List'
import ListFilters from './ListFilters'
import BooleanField from '../../components/BooleanField'
import checkPermission from '../../helpers/permissions'

export default props => {
  const { permissions, resource } = props
  return (
    <List filters={<ListFilters />} {...props} bulkActionButtons={false} creatable={true}>
      <Datagrid rowClick='show'>
        <TextField cellClassName='cell-2' source='attributes.field_date' />
        <TextField
          cellClassName='cell-2'
          sortBy='field_firm.attributes.title'
          source='included.field_firm.attributes.title'
        />
        <TextField cellClassName='cell-8' source='attributes.title' />
        <BooleanField source='attributes.field_active' />
        {checkPermission(`resource.${resource}.edit`, permissions) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  )
}
