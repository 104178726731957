import React from 'react'
import { set } from 'lodash'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  FileField,
  required
} from 'react-admin'
import FileInput from '../../components/FileInput'
import { parse } from 'query-string'
import ClearFix from '../../components/ClearFix'

export default props => {
  const { contract_id } = parse(props.location.search)
  const redirect = contract_id
    ? `/node/contract/${contract_id}/show/protocol`
    : false

  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={set(
          {},
          'relationships.field_contract.data.id',
          contract_id
        )}
        redirect={redirect}
      >
        <ReferenceInput
          style={{ display: 'none' }}
          source='relationships.field_contract.data.id'
          reference='node/contract'
          validate={required()}
        >
          <SelectInput optionText='attributes.title' />
        </ReferenceInput>

        <TextInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.title'
          validate={required()}
          fullWidth={true}
        />

        <DateInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_date'
          validate={required()}
          fullWidth={true}
        />
        <ClearFix formClassName='clearfix' />

        <FileInput
          formClassName='col-xs-12'
          source='included.field_files'
          accept='application/pdf'
        >
          <FileField
            source='attributes.uri.url'
            title='attributes.filename'
            target='_blank'
          />
        </FileInput>
        <TextInput
          multiline
          formClassName='col-xs-12'
          source='attributes.body.value'
        />
      </SimpleForm>
    </Create>
  )
}
