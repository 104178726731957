/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { get } from 'lodash'
import MeasureTextField from './MeasureTextField'
import { Link } from 'react-router-dom'

export default props => {
  const {
    counter,
    currentValue: initialValue,
    prevValue,
    measureId,
    date,
    coefficient,
  } = props
  const [currentValue, setCurrentValue] = useState(0)

  useEffect(() => {
    setCurrentValue(initialValue)
  }, [initialValue])

  return (
    <TableRow key={counter.id}>
      <TableCell component='th' scope='row'>
        <Link to={`/node/counter/${counter.id}`}>
          {get(counter, 'attributes.field_counter_number')}
        </Link>
      </TableCell>
      <TableCell align='right'>
        {get(counter, 'attributes.field_counter_type')}
      </TableCell>
      <TableCell align='right'>
        {get(counter, 'attributes.field_install_location')}
      </TableCell>
      <TableCell align='right'>
        {get(counter, 'attributes.field_consumer')}
      </TableCell>
      <TableCell align='right'>
        {get(counter, 'attributes.field_counter_purpose')}
      </TableCell>
      <TableCell align='right'>{prevValue}</TableCell>
      <TableCell align='right'>
        <MeasureTextField
          counterId={counter.id}
          measureId={measureId}
          initialValue={initialValue}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          date={date}
        />
      </TableCell>
      <TableCell align='right'>{currentValue - prevValue}</TableCell>
      <TableCell align='right'>{coefficient}</TableCell>
      <TableCell align='right'>
        {(currentValue - prevValue) * coefficient}
      </TableCell>
    </TableRow>
  )
}
