import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { months, years } from '../helpers/tools'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: '0px 5px',
  }
}))

function FilterByYearMonth(props) {
  const classes = useStyles()
  const {
    defaultDate,
    currentYear,
    changeYear,
    changeMonth,
    isLoaded
  } = props

  return (
    <>
      <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded} size='small'>
        <InputLabel>Год</InputLabel>
        <Select
          defaultValue={defaultDate.getFullYear()}
          onChange={changeYear}
        >
          {
            years(2018).map((year, idx) => (
              <MenuItem key={idx} value={year}>{year}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded} size='small'>
        <InputLabel>Месяц</InputLabel>
        <Select
          defaultValue={defaultDate.getMonth()}
          onChange={changeMonth}
        >
          {
            currentYear === '2018'
              ?
              <MenuItem value='11'>{months[11]}</MenuItem>
              :
              months.map((row, idx) => (
                <MenuItem key={idx} value={idx}>{row}</MenuItem>
              ))
          }
        </Select>
      </FormControl>
    </>
  )
}

export default FilterByYearMonth