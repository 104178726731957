import React from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import { get } from 'lodash'
import Filter from '../../components/Filter'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inputFirm: {
    minWidth: 230
  }
}))

export default props => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <ReferenceInput
        source='field_area'
        label='Город'
        alwaysOn
        reference='node/area'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_area.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <TextInput
        source='title'
        label='Адрес'
        alwaysOn
        parse={value => {
          return {
            path: 'title',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <ReferenceInput
        className={classes.inputFirm}
        label='Управляющая компания'
        source='field_firm'
        alwaysOn
        reference='node/firm'
        perPage={50}
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_firm.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
        filter={{ field_firm_type: 'mc' }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
    </Filter>
  )
}
