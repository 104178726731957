import React from 'react'
import { SelectInput, ReferenceInput, TextInput } from 'react-admin'
import Filter from '../../components/Filter'
import { get } from 'lodash'

export default props => {
  return (
    <Filter {...props}>
      <TextInput
        source='field_search'
        alwaysOn
        parse={value => {
          return {
            path: 'field_search',
            value: value,
            operator: 'CONTAINS',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      />
      <ReferenceInput
        source='field_firm'
        alwaysOn
        reference='node/firm'
        sort={{ field: 'title', order: 'ASC' }}
        parse={value => {
          return {
            path: 'field_firm.id',
            value: value,
            operator: '=',
          }
        }}
        format={value => {
          return get(value, 'value', '')
        }}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
    </Filter>
  )
}
