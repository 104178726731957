import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chart,
  BarSeries,
  Legend,
  Title,
  ArgumentAxis,
  ValueAxis,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart'

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  chartTitle: {
    fontSize: '1rem'
  }
}))

export default function Receivables(props) {
  const classes = useStyles()
  const { data } = props

  const titleComponent = (({ ...restProps }) => (
    <Title.Text {...restProps} className={classes.chartTitle} />
  ))

  return (
    <Chart
      data={data}
      height={250}
    >
      <ArgumentAxis />
      <ValueAxis />

      <Title
        text="Дебиторская задолженность за последние 6 мес. (руб.)"
        textComponent={titleComponent}
      />

      <BarSeries
        name=">36 мес."
        valueField="more36"
        argumentField="month"
      />
      <BarSeries
        name="12-36 мес."
        valueField="from12to36"
        argumentField="month"
      />
      <BarSeries
        name="2-12 мес."
        valueField="from2to12"
        argumentField="month"
      />
      <BarSeries
        name="<2 мес."
        valueField="less2"
        argumentField="month"
      />
      <Stack
        stacks={[
          { series: ['>36 мес.', '12-36 мес.', '2-12 мес.', '<2 мес.'] },
        ]}
      />
      <EventTracker />
      <Tooltip />
      <Animation />
      <Legend />
    </Chart>
  )
}