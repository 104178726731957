import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'
import { translate } from 'react-admin'
import { WithPermissions } from 'react-admin'
import checkPermission from '../../helpers/permissions'

const styles = {
  button: {
    marginTop: '1em',
  },
}

const AddProtocolButton = ({ translate, classes, record }) => (
  <WithPermissions
    render={({ permissions: roles }) => {
      const access = `resource.node/protocol.edit`
      return (
        <>
          {checkPermission(access, roles) && (
            <Button
              className={classes.button}
              component={Link}
              to={`/node/protocol/create?contract_id=${record.id}`}
              label={translate('Add a protocol')}
            >
              <AddIcon />
            </Button>
          )}
        </>
      )
    }}
  />
)

export default translate(withStyles(styles)(AddProtocolButton))
