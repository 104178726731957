import React from 'react'
import { List, Pagination } from 'react-admin'
import Actions from './Actions'

const MyPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const MyList = props => {
  const { permissions, creatable } = props
  const childProps = { ...props }
  delete childProps.creatable
  return (
    <List
      {...childProps}
      perPage={props.perPage || 20}
      pagination={<MyPagination />}
      actions={<Actions permissions={permissions} creatable={creatable} />}
    >
      {props.children}
    </List>
  )
}

export default MyList
