import React from 'react'
import { get, set } from 'lodash'
import {
  SimpleForm,
  ReferenceInput,
  DateInput,
  required,
  NumberInput,
  FormDataConsumer,
  TextInput,
  AutocompleteInput,
} from 'react-admin'
import ClearFix from '../../components/ClearFix'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

const styles = theme => ({
  widthFix: {
    width: '100%',
    minWidth: '100%',
  },
})

const EditCreateForm = props => {
  const { classes } = props
  return (
    <SimpleForm {...props}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const number = get(
            formData,
            'relationships.field_measure_counter.data.id',
            ''
          )
          const type = get(formData, 'attributes.field_measure_date', '')
          set(formData, 'attributes.title', `${number} / ${type}`)
          return (
            <TextInput style={{ display: 'none' }} source='attributes.title' />
          )
        }}
      </FormDataConsumer>
      {!get(
        props,
        'defaultValue.relationships.field_measure_counter.data.id'
      ) && (
        <ReferenceInput
          formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          className={classes.widthFix}
          source='relationships.field_measure_counter.data.id'
          reference='node/counter'
          validate={required()}
          perPage={10}
          sort={{ field: 'title', order: 'ASC' }}
          filterToQuery={searchText => {
            return {
              title: {
                path: 'title',
                value: searchText,
                operator: 'CONTAINS',
              },
            }
          }}
        >
          <AutocompleteInput
            formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            optionText='attributes.title'
          />
        </ReferenceInput>
      )}
      <ClearFix formClassName='clearfix' />

      <DateInput
        // disabled
        defaultValue={moment().format('YYYY-MM-D')}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_measure_date'
        validate={required()}
        fullWidth={true}
      />

      <NumberInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className={classes.widthFix}
        source='attributes.field_measure_value'
        validate={required()}
        step={1}
      />

      <ClearFix formClassName='clearfix' />
    </SimpleForm>
  )
}

export default withStyles(styles)(EditCreateForm)
