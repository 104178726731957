import { stringify } from 'qs'
import { forIn, isObject, get, set, has, each } from 'lodash'
import merge from 'deepmerge'
import axios from 'axios'
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE_FILE,
} from './actions'

import defaultSettings from './default-settings'
import { NotImplementedError } from './errors'
import init from './initializer'
import { responseToData, resourceIncludes, fillItemIncluded } from './includes'
import {
  requestNormalize,
  responseNormalize,
  requestNormalize_CREATE,
  requestNormalize_DELETE,
} from './normalize'

// Set HTTP interceptors.
init()

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {Object} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (defaultApiUrl, userSettings = {}) => (
  type,
  resource,
  params
) => {
  let url = ''
  const settings = merge(defaultSettings, userSettings)

  const options = {
    headers: params.headers || settings.headers,
    withCredentials: true,
  }
  set(options, 'headers.X-CSRF-Token', localStorage.getItem('csrf_token'))

  const apiUrl = params.apiUrl || defaultApiUrl

  console.log('TCL: request type, resource', type, resource)

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination

      // Create query with pagination params.
      const query = {
        'page[offset]': (page - 1) * perPage,
        'page[limit]': perPage,
      }

      // Add all filter params to query.
      forIn(params.filter, (value, key) => {
        if (isObject(value)) {
          if (has(value, 'value')) {
            const path = `filter[${key}][condition][path]`
            const operator = `filter[${key}][condition][operator]`
            const val = `filter[${key}][condition][value]`
            query[path] = get(value, 'path')
            query[operator] = get(value, 'operator')
            query[val] = get(value, 'value', '')
          }
        } else {
          query[`filter[${key}]`] = value
        }
      })

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-'
        query.sort = `${prefix}${params.sort.field.replace('attributes.', '')}`
      }
      query.include = resourceIncludes(resource, type)
      url = `${apiUrl}/${resource}?${stringify(query)}`
      break
    }

    case GET_ONE:
      const query = {}
      query.include = resourceIncludes(resource, type)
      url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`
      break

    case CREATE:
      url = `${apiUrl}/${resource}`
      options.method = 'POST'
      const data = {
        data: requestNormalize_CREATE(resource, params.data),
      }
      options.data = JSON.stringify(data)
      break

    case CREATE_FILE:
      url = `${apiUrl}${resource}`
      console.log('CREATE_FILE url', url)
      options.method = 'POST'
      options.data = params.data
      break

    case UPDATE: {
      url = `${apiUrl}/${resource}/${params.id}`

      const data = {
        data: requestNormalize(resource, params.data),
      }

      options.method = settings.updateMethod
      options.data = JSON.stringify(data)
      break
    }

    case DELETE: {
      url = `${apiUrl}/${resource}/${params.id}`

      const data = {
        data: requestNormalize_DELETE(resource, params.previousData),
      }

      options.method = settings.updateMethod
      options.data = JSON.stringify(data)
      break
    }

    case GET_MANY: {
      let filter = {
        'filter[filter-id][condition][path]': 'id',
        'filter[filter-id][condition][operator]': 'IN',
        'filter[filter-id][condition][value]': [],
      }
      each(params.ids, value => {
        if (isObject(value)) {
          filter['filter[filter-id][condition][value]'].push(value.id)
        } else {
          filter['filter[filter-id][condition][value]'].push(value)
        }
      })
      const query = stringify(filter, { arrayFormat: settings.arrayFormat })
      url = `${apiUrl}/${resource}?${query}`
      break
    }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination

      // Create query with pagination params.
      const query = {
        'page[offset]': (page - 1) * perPage,
        'page[limit]': perPage,
      }

      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-'
        query.sort = `${prefix}${params.sort.field.replace('attributes.', '')}`
      }

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach(key => {
        query[`filter[${key}]`] = params.filter[key]
      })

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id

      query.include = resourceIncludes(resource, type)

      url = `${apiUrl}/${resource}?${stringify(query)}`
      break
    }

    default:
      throw new NotImplementedError(
        `Unsupported Data Provider request type ${type}`
      )
  }

  return axios({ url, ...options }).then(response => {
    let total
    console.log('TCL: response type, resource', type, resource)

    // For all collection requests get the total count.
    if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type)) {
      // When meta data and the 'total' setting is provided try
      // to get the total count.
      if (response.data.meta && settings.total) {
        total = get(response, 'data.meta.count', 0)
      }

      // Use the length of the data array as a fallback.
      total = parseInt(total) || response.data.data.length
    }

    switch (type) {
      case GET_MANY:
      case GET_LIST: {
        let out = {
          data: responseToData(response.data),
          total,
        }
        out = responseNormalize(resource, out, type)
        console.log('TCL: out', out)
        return out
      }

      case GET_MANY_REFERENCE: {
        let out = {
          data: responseToData(response.data),
          total,
        }
        out = responseNormalize(resource, out, type)
        console.log('TCL: out', out)
        return out
      }

      case GET_ONE: {
        let out = response.data
        if (typeof response.data.included !== 'undefined') {
          out.data.included = fillItemIncluded(
            response.data.data,
            response.data.included
          )
        }
        out = responseNormalize(resource, out, type)
        console.log('TCL: out', out)
        return out
      }

      case CREATE: {
        const { id } = response.data.data

        return {
          data: {
            id,
            data: response.data.data,
          },
        }
      }

      case CREATE_FILE: {
        response.data = responseNormalize(resource, response.data, type)
        return response.data
      }

      case UPDATE: {
        const { id, attributes } = response.data.data

        return {
          data: {
            id,
            ...attributes,
          },
        }
      }

      case DELETE: {
        return {
          data: { id: params.id },
        }
      }

      default:
        throw new NotImplementedError(
          `Unsupported Data Provider request type ${type}`
        )
    }
  })
}
