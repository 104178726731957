import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { orderBy } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import FeeChart from './FeeChart'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const useStyles = makeStyles(theme => ({
  chartCell: {
    minWidth: '150px',
    padding: '5px 16px',
    paddingBottom: 0,
  },
  td: {
    padding: 0,
  },
  houseTitleSell: {
    width: '20%',
    padding: '0px 5px',
  },
  taxPercent: {
    height: 10,
    position: 'relative'
  },
}))

const headCells = [
  {align: 'left', id: 'house_title', label: 'Дом'},
  {align: 'center', id: 'balance_start', label: 'Долг на начало (руб.)'},
  {align: 'center', id: 'accrued', label: 'Начислено (руб.)'},
  {align: 'center', id: 'paid', label: 'Оплачено (руб.)'},
  {align: 'center', id: 'balance_end', label: 'Долг на конец (руб.)'},
  {align: 'center', id: 'tax_percent', label: 'Сбор по дому (%)'},
  {align: 'center', id: 'financial_indicator', label: 'Фин. устойчивость'},
]


function HousesFeeTable(props) {
  const { data } = props
  const classes = useStyles()
  
  const [order, setOrder] = useState('asc')
  const [columnToSort, setColumnToSort] = useState('house_title')
      
  const handleRequestSort = (event, property) => {
    const isAsc = columnToSort === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setColumnToSort(property)
  };
  
  return (
    <Table>
      <EnhancedTableHead
        order={order}
        orderBy={columnToSort}
        onRequestSort={handleRequestSort}
        headCells={headCells}
      />
      <TableBody>
        {orderBy(data, columnToSort, order).map((row, key) => (
          <TableRow key={row.house_id}>
            <TableCell className={classes.houseTitleSell}>
              {row.house_title}
            </TableCell>
            <TableCell className={classes.td} align="center">{row.debt_start.toLocaleString()}</TableCell>
            <TableCell className={classes.td} align="center">{row.accrued.toLocaleString()}</TableCell>
            <TableCell className={classes.td} align="center">{row.paid.toLocaleString()}</TableCell>
            <TableCell className={classes.td} align="center">{row.debt_end.toLocaleString()}</TableCell>
            <TableCell align="center" className={classes.chartCell}>
              <div className={classes.taxPercent}>{row.tax_percent}%</div>
              <HouseFeeChart data={orderBy(data, columnToSort, order)[key]}/>
            </TableCell>
            <TableCell className={classes.td} align="center">{row.financial_indicator.toFixed(2).replace('.', ',')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function HouseFeeChart(props) {
  const { data } = props
  const chartData = []
  chartData.push({ house: data.house_title, tax_percent: data.tax_percent })

  return (
    <FeeChart data={chartData} chartHeight={60} chartWidth={undefined} title={false} legend={false} barWidth={1}/>
  )
}

export default HousesFeeTable
