import React from 'react'
import {
  Datagrid,
  ArrayField,
  FileField,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
  ReferenceField
} from 'react-admin'
import FileSizeField from '../../components/FileSizeField'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import BooleanField from '../../components/BooleanField'

export default props => {
  return (
    <Show title={<Title />} {...props}>
      <SimpleShowLayout>
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_custom_serial'
        />
        <DateField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.created'
        />
        <ClearFix className='clearfix' />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.title'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_id'
        />
        <ClearFix className='clearfix' />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='included.field_firm.attributes.title'
        />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='included.field_contractor.attributes.title'
        />
        <ClearFix className='clearfix' />

        <ReferenceField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='relationships.field_parent.data.id'
          reference='node/outgoing_mail'
          allowEmpty
        >
          <TextField source='attributes.title' />
        </ReferenceField>
        <BooleanField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_response_required'
        />

        <ClearFix className='clearfix' />
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4 col-fix'
          source='attributes.field_task'
        />
        <ClearFix className='clearfix' />

        <ArrayField className='col-xs-12' source='included.field_files'>
          <Datagrid>
            <FileField
              source='attributes.uri.url'
              title='attributes.filename'
              target='_blank'
            />
            <FileSizeField source='attributes.filesize' />
          </Datagrid>
        </ArrayField>
        <RichTextField className='col-xs-12' source='attributes.body.value' />
      </SimpleShowLayout>
    </Show>
  )
}
