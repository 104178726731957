import React from 'react'
import { Loading } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  center: {
    padding: '10px 0px',
    height: '100%',
    marginTop: 0
  },
  root: {
    opacity: 0.5,
    margin: '10px'
  }
}))

export default function LoadingIndicator(props) {
  const classes = useStyles()
  const { withoutTitle } = props

  const isTitled = withoutTitle === undefined
    ? false
    : true

  return (
    <>
      <Grid container style={{ padding: '5px', justifyContent: 'center' }}>
        {isTitled
          ? <CircularProgress classes={{ root: classes.root }} />
          : <Loading className={classes.center} />
        }
      </Grid>
    </>
  )
}