import React from 'react'
import { Redirect } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { findKey, last, includes } from 'lodash'

const homePages = {
  '/node/contract': ['law_admin', 'law_user'],
  '/node/counter': ['engineer', 'engineer_admin']
}

export default ({ permissions }) => {
  const role = last(permissions)
  const page = findKey(homePages, item => {
    return includes(item, role)
  })
  return <Card>{page && <Redirect to={page} />}</Card>
}
