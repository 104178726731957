import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  colorRectangle: {
    width: '25px',
    height: '20px',
    borderRadius: '4px'
  },
}))

function ColorsLegend(props) {
  const classes = useStyles()
  const { colors } = props
  return (
    <>
    {
      colors.map(color =>
        <Grid item key={color.text} style={{ padding: '3px' }}>
          <Grid container alignItems={'center'}>
            <Grid item style={{ fontSize: '0.8rem' }}>{color.text}</Grid>:
            <Grid item style={{ padding: '0px 5px' }}><div className={classes.colorRectangle} style={{ backgroundColor: color.color }}></div></Grid>
            {(colors.indexOf(color) + 1 < colors.length) && (',')}
          </Grid>
        </Grid>
      )
    }
    </>
  )
}

ColorsLegend.propTypes = {
  colors: PropTypes.array.isRequired
}

export default ColorsLegend