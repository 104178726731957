import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  tableSortLabel: {
    '& svg': {
      marginLeft: 0
    }
  },
})

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props
  const classes = useStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ 'padding': '5px' }}
            key={headCell.field}
            align={headCell.align ? headCell.align : 'center'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              className={classes.tableSortLabel}
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              <div style={{ 'paddingLeft': '1em' }}>
                {headCell.name}
                {headCell.units && <> ({headCell.units})</>}
              </div>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead