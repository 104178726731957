import React from 'react'
import { set } from 'lodash'
import { Create } from 'react-admin'
import Form from './EditCreateForm'
import { parse } from 'query-string'

export default props => {
  const { counter_id } = parse(props.location.search)
  const redirect = counter_id
    ? `/node/counter/${counter_id}/show/measures`
    : '/node/measure'
  return (
    <Create {...props}>
      <Form
        defaultValue={set(
          {},
          'relationships.field_measure_counter.data.id',
          counter_id
        )}
        redirect={redirect}
      />
    </Create>
  )
}
