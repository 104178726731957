import React from 'react'
import { DateField as RaDateField } from 'react-admin'

const DateField = ({ className, ...rest }) => {
  return <RaDateField {...rest} />
}

DateField.defaultProps = { addLabel: true }

export default DateField
