import { isNaN, reduce, range, cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'

const myParseFloat = x => {
  const y = parseFloat(x)
  return !isNaN(y) ? y : 0
}

const addMonth = (date, delta) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() + delta)
  return d
}

const addDay = (date, delta) => {
  const d = new Date(date)
  d.setDate(d.getDate() + delta)
  return d
}

const stringifyDate = (date, format = 'YYYY-MM-DD') => moment(date).format(format)
const years = (from) => range(from, new Date().getFullYear() + 1)
const toCurrencyFormat = (value) => `${(value).toLocaleString('ru-RU', { maximumFractionDigits: '2' })}`
const percentageDiff = (value, total, digits = 0) => total === 0 ? 100 : ((value / total) * 100 - 100).toLocaleString('ru-Ru', {maximumFractionDigits: digits})


const renameObjectKey = (object, key, newKey) => {
  const clonedObj = cloneDeep(object)
  const targetKey = clonedObj[key]
  delete clonedObj[key]
  clonedObj[newKey] = targetKey
  return clonedObj
}

async function makeRequest(url, path, params, init) {
  let query = ''
  if (!isEmpty(params))
    query = '?' + new URLSearchParams(params).toString()
  const response = await fetch(`https://${url + path + query}`, init)
  if (response.ok)
    return await response.json()
}

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const baseUrls = {
  api: 'api.xn--d1agdohcba6d8e.xn--p1acf/v1/',
  bitrix:  'l.dmhzn.com/api/bitrix/',
}

const developmentSpending = {
  2020: 3517881.61,
  2021: 884056.47
}

const defaultChartScheme = [
  '#673ab7',
  '#4caf50',
  '#03a9f4',
  '#f44336',
  '#ffc107',
  '#795548',
  '#9e9e9e',
  '#e91e63',
  '#ff9800',
  '#9c27b0',
  '#2196f3',
  '#00bcd4',
  '#ffeb3b'
]

export {
  myParseFloat,
  addMonth,
  addDay,
  stringifyDate,
  months,
  baseUrls,
  developmentSpending,
  defaultChartScheme,
  years,
  toCurrencyFormat,
  renameObjectKey,
  makeRequest,
  percentageDiff
}
