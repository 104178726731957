import React, { useState } from 'react'
import { orderBy, get } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const headCells = [
  { field: 'house_id', name: 'Id' },
  { field: 'house_title', name: 'Дом' },
  { field: 'local_paid', name: 'Оплата наша', units: 'руб.' },
  { field: 'ellis_paid', name: 'Оплата Эллис', units: 'руб.' },
  { field: 'paid_diff', name: 'Разница', units: 'руб.' }
]

function ComparisonTable(props) {
  const { data } = props
  console.log(data)

  const [order, setOrder] = useState('asc')
  const [columnToSort, setColumnToSort] = useState('house_title')

  const handleRequestSort = (event, property) => {
    const isAsc = columnToSort === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setColumnToSort(property)
  };

  return (
    <Table stickyHeader>
      <EnhancedTableHead
        order={order}
        orderBy={columnToSort}
        onRequestSort={handleRequestSort}
        headCells={headCells}
      />
      <TableBody>
        {orderBy(data, columnToSort, order).map((row) => (
          <TableRow key={`${row.house_id}_${row.service_id}`}>
            <TableCell align="center">{get(row, 'house_id', '-').toLocaleString()}</TableCell>
            <TableCell align="center">{get(row, 'house_title', '-').toLocaleString()}</TableCell>
            <TableCell align="center">{get(row, 'local_paid', '-').toLocaleString()}</TableCell>
            <TableCell align="center">{get(row, 'ellis_paid', '-').toLocaleString()}</TableCell>
            <TableCell align="center">{get(row, 'paid_diff', '-').toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ComparisonTable
