import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FileField,
  DateField,
  required,
  TextField,
  BooleanInput,
} from 'react-admin'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import FileInput from '../../components/FileInput'
import Edit from '../../components/Edit'

export default props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextField
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        className='full-width'
        source='attributes.field_custom_serial'
      />
      <DateField
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.created'
      />
      <ClearFix formClassName='clearfix' />

      <TextInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.title'
        validate={required()}
      />
      <TextInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_id'
      />
      <ClearFix formClassName='clearfix' />

      <ReferenceInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_firm.data.id'
        reference='node/firm'
        sort={{ field: 'title', order: 'ASC' }}
        validate={required()}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>

      <ReferenceInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_contractor.data.id'
        reference='node/contractor'
        sort={{ field: 'title', order: 'ASC' }}
        validate={required()}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>

      <ClearFix formClassName='clearfix' />
      <ReferenceInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_parent.data.id'
        reference='node/outgoing_mail'
        allowEmpty
        sort={{ field: 'title', order: 'ASC' }}
        filterToQuery={searchText => {
          return {
            title: {
              path: 'title',
              value: searchText,
              operator: 'CONTAINS',
            },
          }
        }}
      >
        <AutocompleteInput fullWidth={true} optionText='attributes.title' />
      </ReferenceInput>

      <BooleanInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_response_required'
      />
      <ClearFix formClassName='clearfix' />

      <TextInput
        fullWidth={true}
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_task'
      />
      <ClearFix formClassName='clearfix' />

      <FileInput
        formClassName='col-xs-12 file-input'
        source='included.field_files'
        accept='application/pdf'
      >
        <FileField
          formClassName='file-field'
          source='attributes.uri.url'
          title='attributes.filename'
          target='_blank'
        />
      </FileInput>

      <TextInput formClassName='col-xs-12' source='attributes.body.value' />

      <ClearFix formClassName='clearfix' />
    </SimpleForm>
  </Edit>
)
