import React from 'react'
import { SimpleShowLayout } from 'react-admin'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import TextField from '../../components/TextField'
import DateField from '../../components/DateField'
import RecordShowActions from '../../components/RecordShowActions'
import Show from '../../components/Show'

export default props => {
  return (
    <Show title={<Title />} actions={<RecordShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='included.field_measure_counter.attributes.title'
        />
        <ClearFix className='clearfix' />

        <DateField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_measure_date'
        />

        <TextField
          className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
          source='attributes.field_measure_value'
        />

        <ClearFix className='clearfix' />
      </SimpleShowLayout>
    </Show>
  )
}
