import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { find, includes, orderBy, round } from "lodash"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EnhancedTableHead from '../../../components/EnhancedTableHead'

const useStyles = makeStyles(theme => ({
    colorIndicatorTd: {
        padding: 5,
        height: 53
    },
    tr: {
        '& td': {
            borderBottom: 0
        }
    }
}))

const headCells = [
    { field: 'stage_index', name: '№ п/п' },
    { field: 'stage_name', name: 'Наименование этапа' },
    { field: 'houses_num', name: 'Объекты (шт.)' },
    { field: 'cost', name: 'Стоимость (руб.)' }
]

function StagesTable(props) {
    const { data } = props
    const classes = useStyles()

    const [order, setOrder] = useState('asc')
    const [columnToSort, setColumnToSort] = useState('stage_index')

    const tableData = [
        { stage_id: 1, stage_index: '1', color_indicator: '#f1f8e9', stage_name: 'Новые заявки', houses_num: 0, cost: 0 },
        { stage_id: 4, stage_index: '2', color_indicator: '#dcedc8', stage_name: 'Подготовка ОСС', houses_num: 0, cost: 0 },
        { stage_id: 6, stage_index: '3', color_indicator: '#c5e1a5', stage_name: 'ОСС', houses_num: 0, cost: 0 },
        { stage_id: 8, stage_index: '4', color_indicator: '#aed581', stage_name: 'Ожидание решения ГЖИ', houses_num: 0, cost: 0 },
        { stage_id: 9, stage_index: '5', color_indicator: '#9ccc65', stage_name: 'Защита решения в суде', houses_num: 0, cost: 0 },
        { stage_id: 11, stage_index: '6', color_indicator: '#8bc34a', stage_name: 'Пришло в Домохозяин', houses_num: 0, cost: 0 }
    ]

    const consideredStages = [1, 4, 6, 8, 9, 11]

    for (const deal in data) {
        for (const stage in data[deal].stages) {
            const stageId = data[deal].stages[stage].stage_id
            if (includes(consideredStages, stageId)) {
                const stageElem = find(tableData, e => e.stage_id === stageId)
                stageElem.cost += data[deal].opportunity
                stageElem.houses_num++
            }
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = columnToSort === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setColumnToSort(property)
    }

    return (
        <>
            <Typography variant="h6" style={{textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>Движение сделок</Typography>
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={columnToSort}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                    defaultSort='stage_index'
                />
                <TableBody>
                    {orderBy(tableData, columnToSort, order).map((row) => (
                        <TableRow key={row.stage_id} className={classes.tr} style={{ backgroundColor: row.color_indicator }}>
                            <TableCell align="center">{row.stage_index}</TableCell>
                            <TableCell align="center">{row.stage_name}</TableCell>
                            <TableCell align="center">{row.houses_num}</TableCell>
                            <TableCell align="center">{round(row.cost, 2).toLocaleString('ru-RU')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default StagesTable
