import React from 'react'
import FilterByYearMonth from '../../../components/FilterByYearMonth'
import SelectFormControl from '../../../components/SelectFormControl'

function FiltersPanel(props) {
    const {
        defaultDate,
        currentYear,
        changeYear,
        changeMonth,
        changeHousekeeper,
        isLoaded,
        housekeepersList
    } = props

    return (
        <>
            <FilterByYearMonth
                defaultDate={defaultDate}
                currentYear={currentYear}
                changeYear={changeYear}
                changeMonth={changeMonth}
                isLoaded={isLoaded}
            />
            <SelectFormControl label='Управляющий' list={housekeepersList} onChange={changeHousekeeper} disabled={!isLoaded} />

        </>
    )
}

export default FiltersPanel