import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import { isEmpty, reduce } from 'lodash'
import FilterPanel from './FiltersPanel'
import StagesTable from './StagesTable'
import VotingTable from './VotingTable'
import Indicators from './Indicators'
import { stringifyDate, addDay } from '../../../helpers/tools'
import LoadingIndicator from '../../../components/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  gridContainer: {
    fontSize: '1rem',
    padding: '0px 10px'
  },
  valueColor: {
    color: '#283593'
  },
}))

function Sales(props) {
  const classes = useStyles()
  const { permissions } = usePermissions()
  const dispatch = useDispatch()
  const localDate = new Date()

  const [stagesData, setStagesData] = useState({})
  const [salesData, setSalesData] = useState({})
  const [indicatorsData, setIndicatorsData] = useState({})
  const [indicatorsDataLoaded, setIndicatorsDataLoaded] = useState(false)
  const [dealsDataLoaded, setDealsDataLoaded] = useState(false)
  const [startDate, setStartDate] = useState(stringifyDate(addDay(localDate, -7)))
  const [endDate, setEndDate] = useState(stringifyDate(localDate))
  const [currYear, setCurrYear] = useState(localDate.getFullYear())

  const changeStartDate = event => setStartDate(event.target.value)
  const changeEndDate = event => setEndDate(event.target.value)
  const changeCurrYear = event => setCurrYear(event.target.value)

  async function makeRequest(url) {
    const response = await fetch(`https://l.dmhzn.com/api/bitrix/${url}`, {
      headers: {
        'Authorization': 'Bearer nMLzKxTAxvRunORXHICTKlsU4Yfm07LUKja7aagA',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if (response.ok)
      return await response.json()
  }

  useEffect(() => {
    setDealsDataLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url) => {
      ++storage.counter
      makeRequest(`${url}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? {} : data
        })
        .finally(() => {
          if (--storage.counter === 0) {
            setStagesData(storage.pendingData[`deals-with-stages/${startDate}/${endDate}`])
            setSalesData(storage.pendingData[`deals-with-votes/${startDate}/${endDate}/5`])

            setDealsDataLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData(`deals-with-stages/${startDate}/${endDate}`)
    getData(`deals-with-votes/${startDate}/${endDate}/5`)
  }, [startDate, endDate])

  useEffect(() => {
    setIndicatorsDataLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url) => {
      ++storage.counter
      makeRequest(`${url}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? {} : data
        })
        .finally(() => {
          if (--storage.counter === 0) {
            setIndicatorsData(storage.pendingData[url])
          }

          setIndicatorsDataLoaded(true)
          dispatch(fetchEnd())
        })
    }

    getData(`deals-with-latest-stage/${currYear}-01-01/${currYear}-12-31/11`)
  }, [currYear])

  return (
    <>
      <Title title="Продажи" />
      {checkPermission(`menuItem./node/analytics/sales`, permissions) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FilterPanel
                startDate={startDate}
                endDate={endDate}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
                isLoaded={dealsDataLoaded}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Indicators
              currYear={currYear}
              changeCurrYear={changeCurrYear}
              data={indicatorsData}
              isLoaded={indicatorsDataLoaded}
            />
          </Grid>
          {dealsDataLoaded ?
            <>
              <Grid item xs={12}>
                <Paper>
                  <StagesTable data={stagesData} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <VotingTable data={salesData} />
                </Paper>
              </Grid>
            </>
            :
            <Grid item xs={12}>
              <LoadingIndicator />
            </Grid>
          }
        </Grid>
      }
    </>
  )
}

Sales.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default Sales
