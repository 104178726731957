import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import { years, toCurrencyFormat, developmentSpending } from '../../../helpers/tools'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    fontSize: '1rem',
  },
  valueColor: {
    color: '#283593'
  },
  formControl: {
    margin: '0px 5px',
  },
  gridItem: {
    padding: '5px'
  }
}))

function Indicators(props) {
  const {
    data,
    currYear,
    changeCurrYear,
    isLoaded
  } = props
  const classes = useStyles()

  const CacIndicator = {
    houses_num: 0,
    area_val: 0
  }

  for (const elem in data) {
    const deal = data[elem]
    CacIndicator.area_val += deal.living_area + deal.uninhabited_area + deal.paid_parking_area
    CacIndicator.houses_num++
  }

  const spending = developmentSpending[currYear]

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={"auto"}>
        <Paper>
          <div className={classes.gridItem}>
            <Grid container style={{ alignItems: 'center', fontWeight: 'bold' }}>
              <Grid item>Показатель САС на</Grid>
              <Grid item><YearSelect /></Grid>
              <Grid item>год</Grid>
            </Grid>
            <Grid item xs={12}>Потрачено на развитие: <span className={classes.valueColor}>{toCurrencyFormat(spending)}</span></Grid>
            <Grid item xs={12}>Пришло в Домохозяин: <span className={classes.valueColor}>{CacIndicator.houses_num} дом. / {(CacIndicator.area_val).toLocaleString()} м<sup>2</sup></span></Grid>
            <Grid item xs={12}>САС на 1 дом: <span className={classes.valueColor}>{CacIndicator.houses_num === 0 ? 0 : toCurrencyFormat(spending / CacIndicator.houses_num)}</span></Grid>
            <Grid item xs={12}>САС на 1 м<sup>2</sup>: <span className={classes.valueColor}>{CacIndicator.area_val === 0 ? 0 : toCurrencyFormat(spending / CacIndicator.area_val)}</span></Grid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )

  function YearSelect() {
    return (
      <FormControl className={classes.formControl} disabled={!isLoaded}>
        <Select
          defaultValue={currYear}
          onChange={changeCurrYear}
        >
          {
            years(2020).map((year, idx) => (
              <MenuItem key={idx} value={year}>{year}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }
}


Indicators.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default Indicators
