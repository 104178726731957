import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import {
  find,
  isEmpty,
  reduce
} from 'lodash'
import { stringifyDate } from '../../../helpers/tools'
import FiltersPanel from './FiltersPanel'
import ComparisonTable from './ComparisonTable'
import LoadingIndicator from '../../../components/LoadingIndicator'
import NoDataAvailable from '../../../components/NoDataAvailable'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}))

function PaymentsComparison(props) {
  const classes = useStyles()
  const { permissions } = usePermissions()
  const dispatch = useDispatch()

  const localDate = new Date()

  const [currYear, setYear] = useState(localDate.getFullYear())
  const [currMonth, setMonth] = useState(localDate.getMonth())
  const currDate = new Date(currYear, currMonth)

  const [paymentsData, setPaymentsData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [tableData, setTableData] = useState([])

  const changeYear = event => setYear(event.target.value)
  const changeMonth = event => setMonth(event.target.value)

  async function makeRequest(url) {
    const response = await fetch(url, { credentials: 'include' })
    return await response.json()
  }

  useEffect(() => {
    setIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? [] : data
        })
        .finally(() => {
          const pending = storage.pendingData
          if (--storage.counter === 0) {
            setPaymentsData(pending['services-payments-compare'])

            setIsLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData('services-payments-compare', [`&date=${stringifyDate(currDate)}`])
  }, [currYear, currMonth])

  
  useEffect(() => {
    if (isEmpty(paymentsData)) return

    const finalData = []
  
    for (const i in paymentsData) {
      const targetElem = find(finalData, (e) => { return e.house_id == paymentsData[i].house_id })
      const isEllisPaid = paymentsData[i].ellis_paid !== undefined
      const ellis_paid = isEllisPaid ? paymentsData[i].ellis_paid : 0
      const paid_diff = isEllisPaid ? paymentsData[i].paid_diff : Number(paymentsData[i].paid_diff)
      if (!targetElem) {
        finalData.push({
          house_id: paymentsData[i].house_id,
          house_title: paymentsData[i].house_title,
          local_paid: paymentsData[i].local_paid,
          paid_diff,
          ellis_paid,
        })
      }
      else {
        targetElem.local_paid += paymentsData[i].local_paid
        targetElem.ellis_paid += ellis_paid
        targetElem.paid_diff += paid_diff
      }
    }

    setTableData(finalData)
  }, [paymentsData])

  return (
    <>
      <Title title="Сравнение оплат" />
      {checkPermission(`menuItem./node/tools/payments_comparison`, permissions) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FiltersPanel
                defaultDate={localDate}
                currentYear={currYear}
                changeMonth={changeMonth}
                changeYear={changeYear}
                isLoaded={isLoaded}
              />
            </Paper>
          </Grid>
          {isLoaded ?
            <Grid item xs={12}>
              {!isEmpty(tableData)
                ?
                <Paper>
                  <ComparisonTable data={tableData} />
                </Paper>
                :
                <NoDataAvailable />
              }
            </Grid>
            :
            <Grid item xs={12}>
              <LoadingIndicator />
            </Grid>
          }
        </Grid>
      }
    </>
  )
}

PaymentsComparison.propTypes = {
  // classes: PropTypes.object.isRequired
}

export default PaymentsComparison
