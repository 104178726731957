import React from 'react'
import { TabbedShowLayout } from 'react-admin'
import compose from 'recompose/compose'
import Title from '../../components/Title'
import Show from '../../components/Show'
import { withStyles } from '@material-ui/core/styles'
import { translate } from 'ra-core'
import checkPermission from '../../helpers/permissions'
import TabMain from './TabMain'
import TabCountersMeasures from './tabCountersMeasures/TabCountersMeasures'

const styles = theme => ({
  docked: {},
})

const HouseShow = props => {
  const { permissions, id } = props
  return (
    <Show title={<Title />} {...props}>
      <TabbedShowLayout>
        <TabMain />
        {checkPermission('resource.node/counter.edit', permissions) && (
          <TabCountersMeasures id={id} />
        )}
      </TabbedShowLayout>
    </Show>
  )
}

const enhance = compose(translate, withStyles(styles, { withTheme: true }))

export default enhance(HouseShow)
