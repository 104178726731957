// import edit from './Edit'
// import create from './Create'
// import show from './Show'
import list from './List'

export default {
  name: 'node/ipu',
  // edit,
  // create,
  // show,
  list,
}
