import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PrimaryBlock from '../../../components/PrimaryBlock'
import Grid from '@material-ui/core/Grid'
import { head, isEmpty, tail } from 'lodash'
import { developmentSpending, toCurrencyFormat } from '../../../helpers/tools'
import LoadingIndicator from '../../../components/LoadingIndicator'
import PercentComparisonNumber from '../../../components/PercentComparisonNumber'

const useStyles = makeStyles(() => ({
    valueColor: {
        color: '#283593'
    }
}))

function Development(props) {
    const classes = useStyles()
    const currYear = new Date().getFullYear()
    const { data } = props
    const spending = developmentSpending[currYear]

    return (
        <PrimaryBlock title='Развитие'>
            <Grid container spacing={1} style={{ padding: '5px' }}>
                {!isEmpty(data)
                    ?
                    <>
                        <Grid item xs={12}>Пришло в этом году: <span className={classes.valueColor}>{data.houses_num} дом. / {(data.area).toLocaleString()} м<sup>2</sup></span></Grid>
                        <Grid item xs={12}>Проходит ОСС: <span className={classes.valueColor}>{data.oss_deals}</span></Grid>
                        <Grid item xs={12}>Новых заявок за месяц: <span className={classes.valueColor}>{data.new_apps}</span></Grid>
                        {/* <Grid item xs={12}>Новых заявок за месяц: <span className={classes.valueColor}><PercentComparisonNumber number={data.new_apps} comparedNumber={data.prev_month_apps} /></span></Grid> */}
                        <Grid item xs={12}>САС на м<sup>2</sup>: <span className={classes.valueColor}>{data.area === 0 ? 0 : toCurrencyFormat(spending / data.area)}</span></Grid>
                    </>
                    : <LoadingIndicator withoutTitle />
                }
            </Grid>
        </PrimaryBlock>
    )
}

export default Development