import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FilterByYearMonth from '../../../components/FilterByYearMonth'
import GeneralFee from './GeneralFee'
import HousesFeeList from './HousesFeeList'
import { usePermissions, fetchStart, fetchEnd, Title } from 'react-admin'
import checkPermission from '../../../helpers/permissions'
import { useDispatch } from 'react-redux'
import _, {
  isEmpty,
  merge,
  map,
  uniqBy,
  filter,
  fromPairs,
  round,
  reduce,
  head,
  sumBy,
  includes,
  uniq,
  keys,
  constant,
  concat,
  over,
  property,
  join
} from 'lodash'
import { addMonth, stringifyDate } from '../../../helpers/tools'
import LoadingIndicator from '../../../components/LoadingIndicator'
import NoDataAvailable from '../../../components/NoDataAvailable'
import SelectFormControl from '../../../components/SelectFormControl'

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  }
})

const groupTitles = {
  9: 'Целевые сборы',
  10: 'Оплата сторонним организациям',
  11: 'Оплата УК',
}

function CenteredGrid(props) {
  const { classes } = props
  const { permissions } = usePermissions()
  const dispatch = useDispatch()


  const filters = [
    data => filterBy(data, 'housekeeper_id', currentHousekeeper),
    data => filterBy(data, 'house_id', currentHouse),
    data => filterBy(data, 'service_group_id', currentServiceGroup),
    data => filterBy(data, 'service_id', currentService),
  ]

  const localDate = new Date()

  const [currYear, setYear] = useState(localDate.getFullYear())
  const [currMonth, setMonth] = useState(localDate.getMonth())

  const currDate = new Date(currYear, currMonth)

  const [currentHousekeeper, setHousekeeper] = useState('empty')
  const [currentHouse, setHouse] = useState('empty')
  const [currentService, setService] = useState('empty')
  const [currentServiceGroup, setServiceGroup] = useState('empty')

  const [filtersByHousekeepers, setFiltersByHousekeeper] = useState([])

  const [housesBillsServices, setHousesBillsServices] = useState([])
  const [housesPaymentsServices, setHousesPaymentsServices] = useState([])

  const [totalData, setTotalData] = useState([])
  const [paymentsChannelsData, setPaymentsChannelsData] = useState([])

  const [dataIsLoaded, setDataIsLoaded] = useState(false)

  const changeYear = event => setYear(event.target.value)
  const changeMonth = event => setMonth(event.target.value)
  const changeHousekeeper = event => setHousekeeper(event.target.value)
  const changeHouse = event => setHouse(event.target.value)
  const changeService = event => setService(event.target.value)
  const changeServiceGroup = event => setServiceGroup(event.target.value)

  async function makeRequest(url) {
    const response = await fetch(url, { credentials: 'include' })
    return await response.json()
  }

  const filterBy = (data, property, value) => {
    const filtered = filter(data, elem => elem[property] === value)
    return isEmpty(filtered) ? data : filtered
  }

  const applyFilters = (data, filters) => {
    for (const filter of filters)
      data = filter(data)
    return data
  }

  const getPaymentsChannels = (payments) => {
    const keysToSum = ["paid", "penalties_paid"]
    const channelsData = _(payments)
      .groupBy("channel")
      .map(array => {
        const result = {}
        for (const key in head(array)) {
          if (includes(keysToSum, key))
            result[key] = sumBy(array, v => Number(v[key]))
          else if (key == "channel")
            result[key] = head(array)[key]
        }
        return result
      })
      .orderBy('paid', 'desc')
      .value()

    setPaymentsChannelsData(channelsData)
  }

  useEffect(() => {
    setDataIsLoaded(false)
    dispatch(fetchStart())

    const storage = { counter: 0, pendingData: {} }
    const getData = (url, params) => {
      ++storage.counter
      makeRequest(`https://api.xn--d1agdohcba6d8e.xn--p1acf/v1/${url}?_format=json${!isEmpty(params) ? reduce(params, (result, param) => result + param) : ''}`)
        .then((data) => {
          storage.pendingData[url] = isEmpty(data) ? [] : data
        })
        .finally(() => {
          const pending = storage.pendingData
          if (--storage.counter === 0) {
            const housesBillsServices = pending['houses-bills-services']
            const housesPaymentsServices = pending['houses-payments-services']

            setHousesBillsServices(housesBillsServices)
            setHousesPaymentsServices(housesPaymentsServices)

            setDataIsLoaded(true)
            dispatch(fetchEnd())
          }
        })
    }

    getData('houses-bills-services', [`&date=${stringifyDate(addMonth(currDate, -1))}`])
    getData('houses-payments-services', [`&date=${stringifyDate(currDate)}&by_pack=true&with_channels=true`])
  }, [currYear, currMonth])

  const prepareData = (bills, payments) => {
    const aggregate = (data, keysToSum) => {
      return _(data)
        .groupBy('house_id')
        .map((array, id) => {
          const result = {}
          for (const key in head(array)) {
            if (includes(keysToSum, key))
              result[key] = sumBy(array, v => Number(v[key]))
            else
              result[key] = head(array)[key]
          }
          return [id, result]
        })
        .fromPairs()
        .value()
    }

    const billsMergeKeys = ['balance_start', 'accrued', 'balance_end']
    const paymentsMergeKeys = ['paid', 'penalties_paid']

    const houseBills = aggregate(bills, billsMergeKeys)
    const housePayments = aggregate(payments, paymentsMergeKeys)

    const houseIds = uniq(concat(keys(houseBills), keys(housePayments)))

    const mergeKeys = concat(billsMergeKeys, paymentsMergeKeys)
    const defaultValues = {}

    for (const id of houseIds)
      defaultValues[id] = fromPairs(map(mergeKeys, key => [key, 0]))

    return merge(defaultValues, houseBills, housePayments)
  }

  const prepareFilters = () => {
    const addFixedTitle = (obj) => {
      const result = _.clone(obj)
      result.service_group_title = groupTitles[result.service_group_id]
      return result
    }

    return _(housesBillsServices)
      .concat(housesPaymentsServices)
      .map(addFixedTitle)
      .uniqBy(o => join([o.housekeeper_id, o.service_id, o.house_id], ':'))
      .value()
  }

  useEffect(() => {
    setFiltersByHousekeeper(prepareFilters())
  }, [dataIsLoaded])

  useEffect(() => {
    const bills = applyFilters(housesBillsServices, filters)
    const payments = applyFilters(housesPaymentsServices, filters)

    setTotalData(prepareData(bills, payments))
    getPaymentsChannels(payments)
  }, [dataIsLoaded, currentHousekeeper, currentHouse, currentServiceGroup, currentService])

  const filteredData = map(totalData, (elem) => {
    const { accrued, paid, balance_end, house_title, house_id, channel } = elem
    const debtStart = Number(balance_end) - Number(accrued)
    const debtEnd = debtStart + Number(accrued) - Number(paid)
    return {
      house_id,
      accrued: Number(accrued),
      paid: Number(paid),
      channel,
      house_title,
      debt_start: debtStart,
      debt_end: debtEnd,
      tax_percent: Number(paid) !== 0 && Number(accrued) !== 0 ? round(Number(paid) * 100 / Number(accrued)) : Number(paid) !== 0 ? 100 : 0,
      financial_indicator: Number(accrued) !== 0 ? round(debtEnd / Number(accrued), 2) : 0,
    }
  })

  const resultObj = { accrued: 0, paid: 0, debt_start: 0, debt_end: 0 }

  for (const elem of filteredData) {
    resultObj.accrued += elem.accrued
    resultObj.paid += elem.paid
    resultObj.debt_start += elem.debt_start
    resultObj.debt_end += elem.debt_end
  }

  const prepareFilter = (list, id, key) => {
    const filtered = filter(list, { [key]: id })
    const unique = uniqBy(list, key)
    return [(isEmpty(filtered) ? list : filtered), unique]
  }

  const [allHouses, uniqHousekeepers] = prepareFilter(filtersByHousekeepers, currentHousekeeper, 'housekeeper_id')
  const [allGroups, uniqHouses] = prepareFilter(allHouses, currentHouse, 'house_id')
  const [allServices, uniqGroups] = prepareFilter(allGroups, currentServiceGroup, 'service_group_id')
  const uniqServices = uniqBy(allServices, "service_id")

  return (
    <>
      <Title title="Финансы" />
      {checkPermission(`menuItem./node/analytics/finance`, permissions) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FilterByYearMonth
                defaultDate={localDate}
                currentYear={currYear}
                changeYear={changeYear}
                changeMonth={changeMonth}
                isLoaded={dataIsLoaded}
              />
              <SelectFormControl label='Управляющий' list={uniqHousekeepers} onChange={changeHousekeeper} disabled={!dataIsLoaded} property='housekeeper' />
              <SelectFormControl label='Дом' list={uniqHouses} onChange={changeHouse} disabled={!dataIsLoaded} property='house' />
              <SelectFormControl label='Группа услуг' list={uniqGroups} onChange={changeServiceGroup} disabled={!dataIsLoaded} property='service_group' />
              <SelectFormControl label='Услуга' list={uniqServices} onChange={changeService} disabled={!dataIsLoaded} property='service' />
            </Paper>
          </Grid>
          {dataIsLoaded ?
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {
                  !isEmpty(filteredData)
                    ?
                    <>
                      <Grid item xs={12}>
                        <Paper>
                          <GeneralFee data={resultObj} paymentsChannelsData={paymentsChannelsData} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <HousesFeeList data={filteredData} />
                        </Paper>
                      </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                      <NoDataAvailable />
                    </Grid>
                }
              </Grid>
            </Grid>
            :
            <Grid item xs={12}>
              <LoadingIndicator />
            </Grid>
          }
        </Grid>
      }
    </>
  )
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CenteredGrid)
