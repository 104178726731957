import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Chart,
  BarSeries,
  Legend,
  Title,
  ArgumentAxis,
  ValueAxis,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui'
import { Stack, Animation, EventTracker, ValueScale } from '@devexpress/dx-react-chart'
import { round } from 'lodash'

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  chartTitle: {
    fontSize: '1rem'
  }
}))

export default function PaidAccrued(props) {
  const classes = useStyles()
  const {
    chartData,
    seriesData,
    height,
    rotated = false,
    isTitled,
    title,
    isTooltipped,
    tooltipComponent,
    valueScaleModify,
    animation
  } = props

  const titleComponent = (({ ...restProps }) => (
    <Title.Text {...restProps} className={classes.chartTitle} />
  ))

  return (
    <Chart
      data={chartData}
      height={height}
      rotated={rotated}
    >
      {isTitled && <Title text={title} textComponent={titleComponent} />}
      <ValueAxis tickFormat={valueScaleModify} />
      {/* {valueScaleModify && <ValueScale modifyDomain={valueScaleModify} />} */}
      <ArgumentAxis />

      {
        seriesData.map((series, idx) =>
          <BarSeries
            key={idx}
            name={series.name}
            valueField={series.value}
            argumentField={series.argument}
            color={`#${series.color}`}
          />
        )
      }

      {animation && <Animation />}
      {/* <Legend position="bottom" /> */}
      <Stack />
      <EventTracker />
      {isTooltipped && <Tooltip contentComponent={tooltipComponent} />}
    </Chart>
  )
}