import React from 'react'
import {
  RichTextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  ArrayField,
  FileField,
} from 'react-admin'
import BooleanField from '../../components/BooleanField'
import Title from '../../components/Title'
import ClearFix from '../../components/ClearFix'
import TextField from '../../components/TextField'
import DateField from '../../components/DateField'
import QuickCreate from './QuickCreate'
import FileSizeField from '../../components/FileSizeField'
import RecordShowActions from '../../components/RecordShowActions'
import Show from '../../components/Show'
import checkPermission from '../../helpers/permissions'

export default props => {
  const { id, permissions } = props
  return (
    <Show title={<Title />} actions={<RecordShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab contentClassName='row block' label='tabs.main'>
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_number'
          />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_resource'
          />
          <ClearFix className='clearfix' />

          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_type'
          />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='included.field_counter_supplier.attributes.title'
          />
          <ClearFix className='clearfix' />

          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_measure'
          />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_coefficient'
          />
          <ClearFix className='clearfix' />

          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='included.field_counter_house.attributes.title'
          />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_install_location'
          />
          <ClearFix className='clearfix' />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_consumer'
          />
          <TextField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_purpose'
          />
          <ClearFix className='clearfix' />
          <DateField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_date_installation'
          />
          <DateField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_date_verification'
          />
          <ClearFix className='clearfix' />
          <DateField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_date_ver_next'
          />
          <BooleanField
            className='col-xs-12 col-sm-6 col-md-5 col-lg-4'
            source='attributes.field_counter_status'
          />
          <ClearFix className='clearfix' />

          <ArrayField
            className='col-xs-12 col-fix'
            source='included.field_files'
          >
            <Datagrid>
              <FileField
                source='attributes.uri.url'
                title='attributes.filename'
                target='_blank'
              />
              <FileSizeField source='attributes.filesize' />
            </Datagrid>
          </ArrayField>

          <RichTextField
            className='col-xs-12'
            source='attributes.field_counter_comment'
          />
        </Tab>
        <Tab
          contentClassName='row block'
          label='resources.node/counter.tabs.measures'
          path='measures'
        >
          <ReferenceManyField
            addLabel={false}
            reference='node/measure'
            target='field_measure_counter.id'
            sort={{ field: 'attributes.field_measure_date', order: 'DESC' }}
            fullWidth={true}
          >
            <Datagrid rowClick='show'>
              <DateField source='attributes.field_measure_date' />
              <TextField source='attributes.field_measure_value' />
            </Datagrid>
          </ReferenceManyField>
          {checkPermission(`resource.${'node/measure'}.edit`, permissions) && (
            <QuickCreate counterId={id} />
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
