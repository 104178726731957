import list from './List'
import edit from './Edit'
import show from './Show'
import icon from '@material-ui/icons/Receipt'

export default {
  name: 'node/firm_payments_bills',
  list,
  show,
  edit,
  icon
}
