import React from 'react'
import { get, map } from 'lodash'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    whiteSpace: 'nowrap'
  }
}

const ArrayFieldSimple = ({ record, source, itemSource, glue, classes }) => {
  const data = get(record, source)
  return (
    <Typography>
      {map(data, (item, index) => {
        const value = get(item, itemSource)
        if (index < data.length - 1) {
          return (
            <span key={index}>
              <span className={classes.root}>
                {value}
                {glue}
              </span>{' '}
            </span>
          )
        } else {
          return (
            <span key={index} className={classes.root}>
              {value}
            </span>
          )
        }
      })}
    </Typography>
  )
}

const ArrayFieldSimpleStyled = withStyles(styles)(ArrayFieldSimple)
ArrayFieldSimpleStyled.defaultProps = { addLabel: true }

export default ArrayFieldSimpleStyled
