import React from 'react'
import { TextField as RaTextField } from 'react-admin'

const TextField = ({ className, ...rest }) => {
  return <RaTextField {...rest} />
}

TextField.defaultProps = { addLabel: true }

export default TextField
