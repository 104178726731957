import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { round, map } from 'lodash'
import PieChart from '../../../components/charts/PieChart'

const colorScheme = ['#0069c0', '#6ec6ff']

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  value: {
    color: '#0069c0',
    fontSize: '1.3rem',
    verticalAlign: 'sub',
  },
  taxPercent: {
    color: '#0069c0',
    fontSize: '1.5rem',
    verticalAlign: 'sub',
    lineHeight: '10px'
  },
  taxPercentDiv: {
    textAlign: 'center',
    position: 'relative',
    top: -95,
    height: 0
  },
  taxPercentWithDiffDiv: {
    textAlign: 'center',
    position: 'relative',
    top: -87,
    height: 0
  },
  colorGreen: {
    color: 'green'
  },
  colorRed: {
    color: 'red'
  }
}))

export default function PrimaryInfo(props) {
  const classes = useStyles()
  const { data } = props

  const chartData = [
    { type: "Оплачено", value: data.taxPercent },
    { type: "Начислено", value: Math.max(0, round(100 - data.taxPercent)) }
  ]


  const getClass = (value, reverted) => {
    return reverted === true
      ? value >= 0 ? classes.colorRed : classes.colorGreen
      : value >= 0 ? classes.colorGreen : classes.colorRed
  }
  const getSign = value => value >= 0 && '+'
  const formatValue = (value, diffProps, currency) => {
    const [diff, reverted] = diffProps
    const isGreater = diff >= 0
    return (
      <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item>
          <span className={classes.value}>{`${currency ? round(value, 2).toLocaleString() : round(value, 2).toFixed(2).replace('.', ',')} ${currency ? 'руб.' : ''}`}</span>
        </Grid>
        {value !== 0 && diff !== 0 &&
          <Grid item>&nbsp;(<span className={getClass(diff, reverted)}>{isGreater && '+'}{round(diff)}%</span>)
        </Grid>
        }
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} style={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <div className={classes.center}>
          Начислено:<br />
          {formatValue(data.accrued, [data.accruedDiff, false], true)}
          Оплачено:<br />
          {formatValue(data.currDayPaid, [data.paidDiff, false], true)}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.center}>
          Показатель финансовой устойчивости:<br />
          {formatValue(data.financialIndicator, [data.financialIndicatorDiff, true], false)}
        </div>
      </Grid>
      <Grid item xs={6}>
        <PieChart
          data={chartData}
          height={150}
          innerRadius={0.7}
          outerRadius={0.55}
          colorScheme={colorScheme}
          valueField='value'
          argumentField='type'
          legend={false}
          isTitled={false}
        />
        <div className={data.taxPercentDiff !== 0 ? classes.taxPercentDiv : classes.taxPercentWithDiffDiv}>
          <span className={classes.taxPercent}>{round(data.taxPercent)}%</span><br />
          {data.taxPercentDiff !== 0 && 
            <span className={getClass(data.taxPercentDiff)}>{getSign(data.taxPercentDiff)}{round(data.taxPercentDiff)}%</span>
          }
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.center}>
          Показатель ДМ:<br />
          {formatValue(data.dmIndicator, [data.dmIndicatorDiff, false], false)}
        </div>
      </Grid>
    </Grid>
  )
}