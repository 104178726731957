import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { isEmpty } from 'lodash'
import { months, years } from '../../../helpers/tools'

const useStyles = makeStyles((theme) => ({
    margin10: {
        margin: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}))

function FiltersPanel(props) {
    const classes = useStyles()
    const {
        currentYear,
        changeYear,
        changeMonth,
        changeHousekeeper,
        changeService,
        isLoaded,
        housekeepersList,
        servicesList
    } = props

    return (
        <div>
            <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded}>
                <InputLabel>Год</InputLabel>
                <Select
                    defaultValue={new Date().getFullYear()}
                    onChange={changeYear}
                >
                    {
                        years(2018).map((year, idx) => (
                            <MenuItem key={idx} value={year}>{year}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded}>
                <InputLabel>Месяц</InputLabel>
                <Select
                    defaultValue={new Date().getMonth()}
                    onChange={changeMonth}
                >
                    {
                        currentYear === '2018'
                            ?
                            <MenuItem value='11'>{months[11]}</MenuItem>
                            :
                            months.map((row, idx) => (
                                <MenuItem key={idx} value={idx}>{row}</MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
            <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded || isEmpty(housekeepersList)}>
                <InputLabel>Управляющий</InputLabel>
                <Select
                    defaultValue='empty'
                    onChange={changeHousekeeper}
                >
                    <MenuItem value='empty'>&nbsp;</MenuItem>
                    {
                        !isEmpty(housekeepersList) &&
                        housekeepersList.map((elem) => (
                            <MenuItem key={elem.id} value={elem.id}>{elem.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl variant='filled' className={classes.formControl} disabled={!isLoaded || isEmpty(servicesList)}>
                <InputLabel>Услуга</InputLabel>
                <Select
                    defaultValue='empty'
                    onChange={changeService}
                >
                    <MenuItem value='empty'>&nbsp;</MenuItem>
                    {
                        !isEmpty(servicesList) &&
                        servicesList.map((elem) => (
                            <MenuItem key={elem.id} value={elem.id}>{elem.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    )
}

export default FiltersPanel