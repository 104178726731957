import russianMessages from 'ra-language-russian'
import merge from 'lodash/merge'

const FILES = 'Файлы',
  COMPANY = 'Организация',
  CONTRACTOR = 'Контрагент',
  HOUSE = 'Дом',
  AREA = 'Город / Населенный пункт',
  CONTRACT_TYPE = 'Тип договора',
  CONTRACT_STAGE = 'Стадия договора',
  CONTRACT = 'Договор',
  TITLE = 'Заголовок',
  DATE = 'Дата',
  NUMBER = 'Номер',
  CONTRACT_AGREEMENT = 'Доп. соглашение',
  RESPONSE_TO = 'Является ответом на',
  SENDER = 'Отправитель',
  RECEIVER = 'Получатель',
  TAB_MAIN = 'Главная',
  ACCOUNTS = 'Лицевые счета',
  COUNTERS = 'ОДПУ',
  IPU = 'ИПУ',
  SUPPLIER = 'Поставщик ресурса'

const commons = {
  nid: 'Ид',
  'attributes.field_id': NUMBER,
  'attributes.field_counter_number': NUMBER,
  'attributes.title': TITLE,
  title: TITLE,
  body: 'Описание',
  field_search: 'Найти',
  field_firm: COMPANY,
  field_contractor: CONTRACTOR,
  field_house: HOUSE,
  field_counter_house: HOUSE,
  field_area: AREA,
  field_counter_supplier: SUPPLIER,
  'attributes.field_date': DATE,
  'attributes.created': DATE,
  'attributes.field_response_required': 'Требуется ответ',
  'relationships.field_parent.data.id': RESPONSE_TO,
  'included.field_parent.attributes.title': RESPONSE_TO,
  'attributes.body.value': 'Описание',
  'attributes.field_serial': NUMBER,
  'attributes.field_custom_serial': NUMBER,
  'attributes.field_date_start': 'Дата начала',
  'attributes.field_date_end': 'Дата окончания',
  'attributes.field_status': 'Статус',
  'attributes.field_firm': COMPANY,
  'relationships.field_firm.data.id': COMPANY,
  'included.field_firm.attributes.title': COMPANY,
  'included.field_contractor.attributes.title': CONTRACTOR,
  'relationships.field_contractor.data.id': CONTRACTOR,
  'included.field_house.attributes.title': HOUSE,
  'included.field_house': HOUSE,
  'relationships.field_house.data.id': HOUSE,
  'relationships.field_house.data': HOUSE,
  'attributes.field_prolongation': 'Автоматическая пролонгация',
  'attributes.field_active': 'Действующий',
  'included.field_files': FILES,
  'included.field_contract.attributes.title': CONTRACT,
  'relationships.field_contract.data.id': CONTRACT,
  'attributes.filesize': 'Размер',
  'included.field_area.attributes.title': AREA,
  'relationships.field_area.data.id': AREA,
  'relationships.field_area.data': AREA,
  'attributes.uri.url': 'Файл',
  'relationships.field_contract_type.data.id': CONTRACT_TYPE,
  'included.field_contract_type.attributes.title': CONTRACT_TYPE,
  'included.field_contract_stage.attributes.title': CONTRACT_STAGE,
  'relationships.field_contract_stage.data.id': CONTRACT_STAGE,
  'attributes.field_date_cancel': 'Дата расторжения',
  'attributes.field_number_cancel': 'Номер расторжения',
  'attributes.field_date_notice': 'Дата уведомления',
  'attributes.field_number_notice': 'Номер уведомления',
  'attributes.field_task': 'Задача',
  'included.field_counter_house.attributes.title': HOUSE,
  'attributes.field_area_paid': 'Оплачиваемая площадь',
  'attributes.field_area_mutual': 'Площадь МОП',
  field_measure_counter: COUNTERS,
}

const messages = {
  ra: {
    action: {
      confirm: 'Подтвердить',
    },
    loading: {
      primary: 'Загрузка',
      secondary: 'Идет загрузка, пожалуйста, подождите...',
    }
  },
  certificate_of_completion: 'Акты',
  Forbidden: 'Недостаточно прав',
  'File uploaded': 'Файл загружен',
  'Error: File does not meet the requirements':
    'Ошибка: Файл не соответствует условиям',
  'Server Error: File not uploaded': 'Ошибка сервера: Файл не загружен',
  'Add a protocol': 'Добавить протокол разногласий',
  'Add a agreement': 'Добавить доп. соглашение',
  'Add a measure': 'Добавить показание',
  'node--contract': CONTRACT,
  'node--agreement': CONTRACT_AGREEMENT,
  'node--protocol': 'Протокол разногласий',
  'node--house': HOUSE,
  'node--firm': COMPANY,
  'node--contractor': 'Контрагент',
  'node--area': 'Участок',
  'node--incoming_mail': 'Входящее письмо',
  'node--outgoing_mail': 'Исходящее письмо',
  'node--founding_document': 'Учредительный документ',
  'node--counter': COUNTERS,
  'node--measure': `Показание ${COUNTERS}`,
  pos: {
    menu: {
      config: 'Настройки',
      directory: 'Справочники',
      documents: 'Документы',
      mail: 'Корреспонденция',
    },
  },
  menuGroup: {
    config: 'Настройки',
    directory: 'Справочники',
    documents: 'Документы',
    analytics: 'Аналитика',
    tools: 'Инструменты',
    mail: 'Корреспонденция',
  },
  tabs: {
    main: TAB_MAIN,
  },
  resources: {
    'node/measure': {
      name: `Показание ${COUNTERS} |||| Показания ${COUNTERS}`,
      fields: {
        ...commons,
        'relationships.field_measure_counter.data.id': COUNTERS,
        'attributes.field_measure_date': DATE,
        'attributes.field_measure_value': 'Показание',
        'included.field_measure_counter.attributes.title': COUNTERS,
      },
    },
    'node/counter': {
      name: `${COUNTERS} |||| ${COUNTERS}`,
      fields: {
        ...commons,
        'attributes.field_counter_number': 'Номер',
        'included.field_counter_house.attributes.title': HOUSE,
        'relationships.field_counter_house.data.id': HOUSE,
        'attributes.field_counter_date_installation': 'Дата установки',
        'attributes.field_counter_date_verification': 'Дата поверки',
        'attributes.field_counter_date_ver_next': 'Дата след. поверки',
        'attributes.field_counter_resource': 'Ресурс',
        'attributes.field_counter_status': 'Активен',
        'included.field_counter_supplier.attributes.title': SUPPLIER,
        'relationships.field_counter_supplier.data.id': SUPPLIER,
        'attributes.field_counter_comment': 'Комментарий',
        'attributes.field_counter_measure': 'Единица измерения',
        'attributes.field_counter_coefficient': 'Коэффициент',
        'attributes.field_counter_passport_url': 'Ссылка на паспорт',
        'attributes.field_counter_type': 'Тип',
        'attributes.field_install_location': 'Место установки',
        'attributes.field_counter_purpose': 'Назначение',
        'attributes.field_consumer': 'Потребители',
        'attributes.field_text_1': 'Место установки',
        'attributes.field_text_2': 'Показание',
      },
      tabs: {
        measures: `Показания`,
      },
    },
    'node/ipu': {
      name: `${IPU} |||| ${IPU}`,
      fields: {
        ...commons,
        'included.field_account.attributes.title': 'Лицевой счёт',
        'included.field_house_single.attributes.title': 'Дом',
        field_house_single: 'Дом',
        'included.field_account.attributes.field_room_number':
          'Номер помещения',
        'included.field_account.attributes.field_room_type': 'Тип помещения',
      },
      tabs: {
        measures: `Показания`,
      },
    },
    'node/incoming_mail': {
      name: 'Входящее |||| Входящая корреспонденция',
      shortName: 'Входящая',
      fields: {
        ...commons,
        'relationships.field_contractor.data.id': SENDER,
        'relationships.field_firm.data.id': RECEIVER,
        'included.field_firm.attributes.title': RECEIVER,
        'included.field_contractor.attributes.title': SENDER,
        field_contractor: SENDER,
        field_firm: RECEIVER,
        'attributes.field_id': 'Номер контрагента',
      },
    },
    'node/outgoing_mail': {
      name: 'Исходящее |||| Исходящая корреспонденция',
      shortName: 'Исходящая',
      fields: {
        ...commons,
        'relationships.field_contractor.data.id': RECEIVER,
        'relationships.field_firm.data.id': SENDER,
        'included.field_firm.attributes.title': SENDER,
        'included.field_contractor.attributes.title': RECEIVER,
        field_contractor: RECEIVER,
        field_firm: SENDER,
      },
    },
    'node/contract': {
      name: 'Договор |||| Договоры',
      fields: {
        ...commons,
        field_contract_type: CONTRACT_TYPE,
      },
      tabs: {
        main: TAB_MAIN,
        agreement: 'Доп. соглашения',
        protocol: 'Протоколы разногласий',
      },
    },
    'node/agreement': {
      name: 'Доп. соглашение |||| Доп. соглашения',
      fields: {
        ...commons,
      },
    },
    'node/founding_document': {
      name: 'Учредительный документ |||| Учредительные документы',
      fields: {
        ...commons,
      },
    },
    'node/area': {
      name: 'Участок |||| Участки',
      fields: {
        ...commons,
      },
    },
    'node/house': {
      name: 'Дом |||| Дома',
      fields: {
        ...commons,
        'attributes.field_active': 'Активен',
        'attributes.title': 'Адрес',
        'attributes.body.value': 'Комментарий',
        rating: 'Рейтинг жителей',
        collection_level: 'Сбор',
        'relationships.field_firm.data.id': 'Управляющая компания',
        'included.field_firm.attributes.title': 'Управляющая компания',
        'included.field_files': 'Технический паспорт дома',
      },
      tabs: {
        main: TAB_MAIN,
        accounts: ACCOUNTS,
        counters: COUNTERS,
        measures: 'Показания ОДПУ',
      },
    },
    'node/firm': {
      name: 'Организация |||| Организации',
      fields: {
        ...commons,
      },
      tabs: {
        main: TAB_MAIN,
        contracts: 'Договоры',
        foundingDocuments: 'Учредительные документы',
      },
    },
    'node/firm_payments_bills': {
      name: 'Расход |||| Расходы',
      shortName: 'Расход',
      fields: {
        ...commons,
        field_house_single: HOUSE,
        'included.field_house_single.attributes.title' : HOUSE,
        'attributes.field_date' : DATE,
        'attributes.field_balance_start' : 'Долг на начало',
        'attributes.field_balance_end' : 'Долг на конец',
        'attributes.field_accrued' : 'Начислено',
        'attributes.field_paid' : 'Оплачено',
        'attributes.field_text_1' : 'Услуга',
        field_text_1 : 'Услуга',
        field_text_2 : 'Контрагент',
        'attributes.field_text_2' : CONTRACTOR,
        field_text_3 : 'Группа расходов',
        'attributes.field_text_3' : 'Группа расходов'
      },
    },
    'node/analytics/home': {
      name: 'Главная |||| Главная',
      fields: {
        ...commons,
      },
    },
    'node/analytics/finance': {
      name: 'Финансы |||| Финансы',
      fields: {
        ...commons,
      },
    },
    'node/analytics/finance2': {
      name: 'Финансы |||| Финансы',
      fields: {
        ...commons,
      },
    },
    'node/analytics/sales': {
      name: 'Продажи |||| Продажи',
      fields: {
        ...commons,
      },
    },
    'node/analytics/ratings': {
      name: 'Рейтинги',
      fields: {
        ...commons,
      },
    },
    'node/tools/bills_comparison': {
      name: 'Сравнение начислений',
      fields: {
        ...commons,
      },
    },
    'node/tools/balance_end_comparison': {
      name: 'Сравнение остатков',
      fields: {
        ...commons,
      },
    },
    'node/tools/payments_comparison': {
      name: 'Сравнение оплат',
      fields: {
        ...commons,
      },
    },
    'node/tools/services_payments_comparison': {
      name: 'Сравнение оплат по услугам',
      fields: {
        ...commons,
      },
    },
    'node/contractor': {
      name: 'Контрагент |||| Контрагенты',
      fields: {
        ...commons,
      },
    },
    'node/contact': {
      name: 'Контакт |||| Контакты',
      fields: {
        ...commons,
      },
    },
    'node/protocol': {
      name: 'Протокол разногласий |||| Протоколы разногласий',
      fields: {
        ...commons,
      },
    },
  },
}

export default merge(russianMessages, messages)
