import list from './List'
import edit from './Edit'
import create from './Create'
import show from './Show'
import icon from '@material-ui/icons/LocationCity'

export default {
  name: 'node/house',
  list,
  show,
  edit,
  create,
  icon
}
