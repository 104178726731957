import { isEmpty, intersection, get, includes } from 'lodash'

const permissions = {
  menuGroup: {
    directory: ['law_admin', 'law_user'],
    documents: [
      'law_admin',
      'law_user',
      'law_reader',
      'contract_reader',
      'contract_editor',
    ],
    analytics: ['reports_all'],
    tools: ['tools'],
    mail: ['law_admin', 'law_user'],
  },
  menuItem: {
    '/node/firm': ['law_admin', 'law_user', 'contract_editor'],
    '/node/firm_payments_bills': ['law_admin', 'law_user', 'law_reader', 'contract_reader', 'contract_editor',],
    '/node/contractor': ['law_admin', 'law_user', 'contract_editor'],
    '/node/area': ['law_admin', 'law_user'],
    '/node/house': [
      'law_admin',
      'law_user',
      'contact_user',
      'engineer',
      'engineer_admin',
      'counter_reader',
    ],
    '/node/counter': [
      'engineer',
      'engineer_admin',
      'counter_reader',
      'counter_editor',
    ],
    '/node/measure': [
      'engineer',
      'engineer_admin',
      'counter_reader',
      'counter_editor',
    ],
    '/node/contact': ['contact_user'],
    '/node/incoming_mail': ['law_admin', 'law_user'],
    '/node/outgoing_mail': ['law_admin', 'law_user'],
    '/node/contract': [
      'law_admin',
      'law_user',
      'law_reader',
      'contract_reader',
      'contract_editor',
    ],
    '/node/founding_document': [
      'law_admin',
      'law_user',
      'contract_reader',
      'contract_editor',
    ],
    '/node/ipu': ['pokazaniya_ipu'],
    '/node/analytics/home': ['reports_all'],
    '/node/analytics/finance': ['reports_all'],
    '/node/analytics/finance2': ['reports_all'],
    '/node/analytics/sales': ['reports_all'],
    '/node/analytics/ratings': ['reports_all'],
    '/node/tools/bills_comparison': ['tools'],
    '/node/tools/balance_end_comparison': ['tools'],
    '/node/tools/payments_comparison': ['tools'],
    '/coc': ['law_admin', 'law_user'],
  },
  resource: {
    'node/counter': {
      view: ['engineer', 'engineer_admin', 'counter_reader', 'counter_editor'],
      edit: ['engineer', 'engineer_admin', 'counter_editor'],
    },
    'node/measure': {
      view: ['engineer', 'engineer_admin', 'counter_reader', 'counter_editor'],
      edit: ['engineer', 'engineer_admin', 'counter_editor'],
    },
    'node/firm': {
      view: ['law_admin', 'contract_editor'],
      edit: ['law_admin', 'contract_editor'],
    },
    'node/firm_payments_bills': {
      view: ['law_admin', 'law_user', 'contract_reader', 'contract_editor'],
      edit: ['law_admin', 'law_user', 'contract_editor'],
    },
    'node/house': {
      view: [
        'law_admin',
        'law_user',
        'engineer',
        'engineer_admin',
        'counter_reader',
      ],
      edit: [],
    },
    'node/contact': {
      view: ['contact_user'],
      edit: ['contact_user'],
    },
    'node/contract': {
      view: ['law_admin', 'law_user', 'contract_reader', 'contract_editor'],
      edit: ['law_admin', 'law_user', 'contract_editor'],
    },
    'node/contractor': {
      view: ['law_admin', 'contract_editor'],
      edit: ['law_admin', 'contract_editor'],
    },
    'node/founding_document': {
      view: ['law_admin', 'law_user', 'contract_reader', 'contract_editor'],
      edit: ['law_admin', 'law_user', 'contract_editor'],
    },
    'node/incoming_mail': {
      view: ['law_admin', 'law_user'],
      edit: ['law_admin', 'law_user'],
    },
    'node/outgoing_mail': {
      view: ['law_admin', 'law_user'],
      edit: ['law_admin', 'law_user'],
    },
    'node/agreement': {
      view: ['law_admin', 'law_user', 'contract_reader', 'contract_editor'],
      edit: ['law_admin', 'law_user', 'contract_editor'],
    },
    'node/protocol': {
      view: ['law_admin', 'law_user', 'contract_reader', 'contract_editor'],
      edit: ['law_admin', 'law_user', 'contract_editor'],
    },
  },
}

const checkPermission = (requested, roles) => {
  if (includes(roles, 'admin')) {
    return true
  }
  const authorizedRoles = get(permissions, requested)
  return !isEmpty(intersection(authorizedRoles, roles))
}

export default checkPermission
