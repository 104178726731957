import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  FileField,
  required,
  BooleanInput,
} from 'react-admin'
import ClearFix from '../../components/ClearFix'
import FileInput from '../../components/FileInput'

export default props => (
  <Create {...props}>
    <SimpleForm redirect='/node/founding_document'>
      <TextInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.title'
        validate={required()}
        fullWidth={true}
      />
      <DateInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_date'
        validate={required()}
        fullWidth={true}
      />
      <ClearFix formClassName='clearfix' />

      <ReferenceInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='relationships.field_firm.data.id'
        reference='node/firm'
        sort={{ field: 'title', order: 'ASC' }}
        validate={required()}
        fullWidth={true}
      >
        <SelectInput optionText='attributes.title' />
      </ReferenceInput>
      <BooleanInput
        formClassName='col-xs-12 col-sm-6 col-md-5 col-lg-4'
        source='attributes.field_active'
        fullWidth={true}
      />

      <ClearFix formClassName='clearfix' />

      <FileInput
        formClassName='col-xs-12'
        source='included.field_files'
        accept='application/pdf'
      >
        <FileField
          source='attributes.uri.url'
          title='attributes.filename'
          target='_blank'
        />
      </FileInput>
      <ClearFix formClassName='clearfix' />

      <TextInput
        multiline
        fullWidth
        formClassName='col-xs-12'
        source='attributes.body.value'
      />
    </SimpleForm>
  </Create>
)
